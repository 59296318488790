<div data-testid="contenedor-notificaciones">
    <div class="notificacion notificacion-{{notificacion.tipo}}" role="notificacion" *ngFor="let notificacion of notificaciones">
        <ng-container *ngIf="notificacion.icono !== IconoEnum.SinIcono">
            <container-element [ngSwitch]="notificacion.icono" class="notif-icono">
                <neo-icono *ngSwitchCase="IconoEnum.Exito" [iconoClase]="'icon-24 icon-ibm--information'"></neo-icono>
                <neo-icono *ngSwitchCase="IconoEnum.Error" [iconoClase]="'icon-24 icon-ibm--close--outline'"></neo-icono>
                <neo-icono *ngSwitchCase="IconoEnum.Advertencia" [iconoClase]="'icon-24 icon-ibm--warning--alt'"></neo-icono>
                <neo-icono *ngSwitchCase="IconoEnum.Informacion" [iconoClase]="'icon-24 icon-ibm--checkmark--outline'"></neo-icono>
                <neo-icono *ngSwitchCase="IconoEnum.Personalizado" [iconoClase]="notificacion.iconoClasePersonalizado"></neo-icono>
                <neo-icono *ngSwitchDefault [iconoClase]="'icon-24 icon-ibm--close'"></neo-icono>
            </container-element>
        </ng-container>
        <div id="notif-txt">
            <p data-testid="ttlNotificacion" class="notificacion-titulo" *ngIf="notificacion?.titulo">{{notificacion.titulo}}</p>
            <p data-testid="txtNotificacion" *ngIf="notificacion?.mensaje"><neo-span-inner-html [contenido]="notificacion.mensaje"></neo-span-inner-html></p>
        </div>
        <div id="notif-cerrar">
          <neo-button data-testid="btnNotificacion" class="close" *ngIf="notificacion.btnCerrar"
                      [icono]="true"
                      [iconClass]="'icon-16 icon-ibm--close'"
                      (click)="eliminarNotificacion(notificacion)"

          ></neo-button>
        </div>
    </div>
</div>
