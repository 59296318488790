import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { LoaderService } from '@appNeo/neoCore/services/loader/loader.service';
import {delay} from 'rxjs/operators';



@Component({
  selector: 'neo-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
  loading: boolean = false;
  loaderTransparent = false;

  constructor(
    private loaderService: LoaderService
  ) {

  }

  ngOnInit() {
    this.listenToLoading();
    this.listenTransparent();
  }

    listenToLoading(): void {
      this.loaderService.loading
        .pipe(
          delay(0),
          )
        .subscribe((loading) => {
          this.loading = loading;
        });
    }

    listenTransparent(): void {
      this.loaderService.transparent
      .subscribe((transparent) => {
        this.loaderTransparent = transparent;
      });
    }
}
