import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {fromEvent, Subscription} from 'rxjs';
import {distinctUntilChanged, first, switchMap, tap} from 'rxjs/operators';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter} from '@angular-material-components/moment-adapter';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {FormularioFiltrosTablaService} from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import {ValidatorFn, Validators} from '@node_modules/@angular/forms';

//TODO: Analizar opción varios formatos
export const FORMATO_SELECTOR_FECHA_HORA = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm'
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
  }
};

@Component({
  selector: 'neo-formulario-filtros-tabla',
  templateUrl: './formulario-filtros-tabla.component.html',
  styleUrls: ['./formulario-filtros-tabla.component.scss'],
  providers: [
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: NGX_MAT_DATE_FORMATS, useValue:  FORMATO_SELECTOR_FECHA_HORA},
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
  ]
})
export class FormularioFiltrosTablaComponent implements OnInit, OnDestroy {

  @Input('valoresIniciales') valoresIniciales; // en la primera carga, el resto por reset
  @Output() submitForm = new EventEmitter ();
  @Output() cambiosFormulario = new EventEmitter ();
  @Output() limpiarNotificacion = new EventEmitter ();
  @Output() cancel = new EventEmitter();
  @ViewChild('formElement') formElement: ElementRef;

  formInput: IFormInput [];
  formValidators: FormGroup;
  formValueChanges = false;
  formValueReset = false;

  // subscription
  subValidators : Subscription;
  subForm : Subscription;
  subFormValueChange : Subscription;

  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    private formularioFiltrosTablaService: FormularioFiltrosTablaService
  ) {
  }

  ngOnInit() {
    this.formValidators = this.fb.group([]);
    this.subscriptionForm();
  }

  ngOnDestroy() {
    this.subForm.unsubscribe();
    if ( this.subFormValueChange ) {
      this.subFormValueChange.unsubscribe();
    }
  }

  // Todo: No aplica al componentizar campo select
  // totalSeleccionadosInput(input) {
  //   return this.formValidators.controls[input.formControlName].value?.length;
  // }

  subscriptionForm() {
    // console.log('Subscripcion al input formulario filtros');
    this.subForm = this.formularioFiltrosTablaService.inputsForm$.subscribe( data => {
      // console.log('Respuesta Subscripcion al input formulario filtros', data);
      if ( data && data.length ) {
        this.formInput = data;
        // this.formInput.forEach( item => {

        // })
        this.buildForm();
      }
    });
  }

  subscriptionValueChanges(){
    // console.log('Subscripcion al valueChanges formulario filtros');
    this.subFormValueChange = this.formValidators.valueChanges
      .pipe(
        tap(limpiarNotificacion => this.cleanError()),
        tap(event => this.formValueChanges = false),
        tap(event => console.log('Se produce un cambio: ', event)),
        tap(entidadFormulario => this.cambiosFormulario.emit(entidadFormulario)),
        distinctUntilChanged((formEntityOld, formEntityNew) => JSON.stringify(formEntityOld) === JSON.stringify(formEntityNew)),
        switchMap(formEvent => {
          this.formValueChanges = true;
          return fromEvent(this.formElement.nativeElement, 'submit')
            .pipe(
              first()
            );
        })
      )
      .subscribe(this.submit.bind(this));
  }

  buildForm() {
    this.formValidators  = this.creaFormGroup();
    if ( this.valoresIniciales ) {
      this.reset(this.valoresIniciales);
    }
    this.subscriptionValueChanges();
  }

  private creaFormGroup(): FormGroup {
    const group: any = {};

    this.formInput.forEach( campo => {

      const control = new FormControl( '' );
      const valids: ValidatorFn[] = this.generarValidadoresCampo( campo );

      // console.log('================================');
      // console.log(campo.campo.label);
      // console.log('Validadores de ' + campo.campo.label, valids);
      control.setValidators( valids );
      if ( campo.valor ) { control.setValue( campo.valor ); }
      if ( campo.disabled ) { control.disable(); }

      // Para los campos lógicos, su no tienen valor, ponemos por defecto false
      // En este caso pierde sentido el tema de obligatorio
      // if ( !campo.valor && campo.tag === FormTagEnum.boolean ) { control.setValue( false ); }

      group[campo.formControlName] = control;
    });

    return new FormGroup(group);
    // return new FormGroup(group, this.validaDependientesExcluyentes() );

  }

  public generarValidadoresCampo(campo: IFormInput): ValidatorFn[]{
    let validadores: ValidatorFn[] = [];

    // obligatorio
    if ( campo.obligatorio ) {
      validadores.push(Validators.required);
    }

    return validadores;
  }

  cleanError() {
    this.limpiarNotificacion.emit();
  }

  submit() {
    this.formValidators.markAllAsTouched();
    if (this.formValidators.valid) {
      let formValue = this.obtenerForm();
      this.submitForm.emit( formValue );
    } else {
      this.focusFirstInputInvalid();
    }

  }

  btnCancelClick() {
    this.cancel.emit();
  }

  private obtenerForm(): any {
    let formValue;
    formValue =  { ... this.formValidators.value};
    return formValue;
  }

  focusFirstInputInvalid() {
    const firstInvalidControl: HTMLElement = this.element.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }

  public validaCampos( emitirEventoDatosRecibidos: boolean = false ): any {
    // console.log('VALIDA CAMPOS: formValueChanges ', this.formValueChanges, 'formValueReset:', this.formValueReset);
    if ( this.formValueChanges || this.formValueReset) {
      // console.log('Hay cambios en formulario ó reset ');
      this.formValueChanges = false;
      this.formValueReset = false;
      // Pensado para llamar desde componente padre de este componente a través de @ViewChild
      // Dispara la validación de todos los campos y devuelve el formulario
      const obj = { fomulario: null, valores: null};
      if ( this.formValidators ) {
        // validamos todos los campos
        this.disparaValidacionCampos();
        obj.fomulario = this.formValidators;
        obj.valores = this.obtenerForm();
        // si es valido
        if ( emitirEventoDatosRecibidos && obj.fomulario.valid ) { this.submit() }
      }
      // console.log('Objeto a devolver ', obj);
      return obj;
    } else {
      // console.log('Submit de formulario sin cambios');
    }
  }
  private disparaValidacionCampos(formGroup?: FormGroup) {
    // console.log('Dispara validacion campos ', Object.keys(this.formValidators.controls));
    // itera por todos lo campos. Si un campos es, a su vez, un grupo de campos, llama recursivo
    Object.keys(this.formValidators.controls).forEach(field => {
      const control = this.formValidators.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.disparaValidacionCampos();
      }
    });
    this.focusFirstInputInvalid();

  }


  // adaptacion  a filtros
  public reset( valueForm: any ) {
    // console.log('Reset: ', this.formValidators.value, valueForm);
    this.formValidators.reset( valueForm );
    this.formValueReset = true;
  }

  public setValores(valueForm: any) {
    delete valueForm['busqueda'];
    if (valueForm) {
      this.formValidators.setValue(valueForm);
    }
  }

  // Todo: No aplica al componentizar campo select
  // public vaciarInput(input: IFormInput) {
  //   const inputFormGroup = this.formValidators.get(input.formControlName);
  //   inputFormGroup.reset();
  // }
}
