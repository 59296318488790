<span class="text-nowrap contenedor-estado-label" *ngIf="estado" [ngStyle]="{'color': estado?.color }" data-testid="contenedor-estado-label">
    <neo-icono *ngIf=" estado?.iconoClase; else iconoDefecto"
      [icono]="estado?.icono"
      [iconoClase]="estado?.iconoClase + ' mr-5 '"
    ></neo-icono>
    <span data-testid="nombre" *ngIf="estado?.nombre">
       {{ estado.nombre }}
    </span>
</span>

<ng-template #iconoDefecto>
  <neo-icono [iconoClase]="'icon-20 icon-ibm-schematics'"></neo-icono>
</ng-template>
