import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { RoutesNoBars } from '@app/shared/helpers/enums/routes-no-bars.enum';

@Injectable({
  providedIn: 'root'
})
export class BarsService {
  url: string;
  mostrarSideNav = false;
  mostrarTopBar = false;
  mostrarSideNav$: BehaviorSubject<boolean>;
  mostrarTopBar$: BehaviorSubject<boolean>;
  private renderer: Renderer2;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null,null);
    this.router.events.subscribe(({url}: any) => {
      this.url = router.url;
      this.navigationControl();
      this.mostrarSideNav$.next(this.mostrarSideNav);
      this.mostrarTopBar$.next(this.mostrarTopBar);
    });
    this.mostrarSideNav$ = new BehaviorSubject<boolean>(this.mostrarSideNav);
    this.mostrarTopBar$ = new BehaviorSubject<boolean>(this.mostrarTopBar);
  }

  navigationControl(): void{
    if (this.url.indexOf(RoutesNoBars.autentication) !== -1 || this.url.indexOf(RoutesNoBars.error) !== -1) {
      this.renderer.removeClass(this.document.body, 'bars-load');
      this.mostrarSideNav = false;
      this.mostrarTopBar = false;
    } else {
      this.renderer.addClass(this.document.body, 'bars-load');
      this.mostrarSideNav = true;
      this.mostrarTopBar = true;
    }
  }
}
