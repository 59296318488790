import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpContextToken
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError, debounceTime, finalize, map, timeout } from 'rxjs/operators';
import {AuthService} from '@appNeo/neoCore/services/auth/auth.service';
import {Router} from '@angular/router';
import { environment } from '@environments/environment';
import { LoaderService } from '@appNeo/neoCore/services/loader/loader.service';

export const DISABLE_LOADER = new HttpContextToken(() => false);


export const DISABLE_DELAY_LOADER = new HttpContextToken(() => false);

export const LOADER_TRANSPARENT = new HttpContextToken(() => false);
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private countRequest = 0;
  timer;

  constructor( private _loading: LoaderService, private router: Router) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    clearTimeout(this.timer);

    const routeAuth = this.router.url.includes('auth');
    const routeAdmin = this.router.url.includes('admin');

    let delayFinalizacionPeticiones;
    if (routeAuth) {
      delayFinalizacionPeticiones = environment.delay_finalizacion_peticiones_auth_ms;
    } else {
      routeAdmin ? delayFinalizacionPeticiones = environment.delay_finalizacion_peticiones_admin_ms : delayFinalizacionPeticiones = environment.delay_finalizacion_peticiones_public_ms;
    }


    if (request.context.get(DISABLE_LOADER) == true) {
      this._loading.setLoading(false);
    } else {

      if (request.context.get(LOADER_TRANSPARENT) == true || request.url.includes('maps.googleapis.com')) {
        this._loading.setTransparent(true);
      } else {
        this._loading.setTransparent(false);
      }

      if (request.context.get(DISABLE_DELAY_LOADER) == true || !routeAdmin) {
        this._loading.setLoading(true);
      } else {
        this.timer = setTimeout(() => this._loading.setLoading(true), environment.spinner_delay_ms);
      }
    }


    this.countRequest++;
    return next.handle(request)
    .pipe(
      timeout(10000),
      finalize(() => {
        this.countRequest--;
        if (this.countRequest === 0) {
          // TODO: Dar una vuelta, revisar posibilidad de enviar mediante context de la petición los ms o solución alternativa
          this.finalizacionPeticiones(delayFinalizacionPeticiones);
        }
      }),
    );
  }

  finalizacionPeticiones(delay) {
    setTimeout(() => {
      this._loading.setLoading(false);
      clearTimeout(this.timer);
    }, delay)
  }

}
