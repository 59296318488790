import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { IValorMultiple } from '@appNeo/neoShared/helpers/interfaces/IValorMultiple';


@Component({
  selector: 'neo-campo-select [controlCampo]',
  templateUrl: './campo-select.component.html',
  styleUrls: ['./campo-select.component.scss']
})
export class CampoSelectComponent implements AfterViewInit {

  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() opcionGeneral = false;
  @Input() opcionGeneralNombre  = '';
  @Input() datos: IValorMultiple[]  = [];
  @Input() icon: string;

  @Input() multiple = false;
  @Input() textoAyuda = '';

  @ViewChild('campoSelectMultiple') campoSelectMultiple: MatSelect;
  @ViewChild('campoSelectIndividual') campoSelectIndividual: MatSelect;


  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.calcularEtiquetas();
  }

  numMaximoEtiquetas = 1;

  constructor(
    private cdRef: ChangeDetectorRef,
    private element: ElementRef
  ) { }

  ngAfterViewInit() { //Recién en este punto tendrás acceso al valor
    setTimeout(() => {
      this.calcularEtiquetas();
    });
    this.controlCampo.valueChanges.subscribe(()=>this.calcularEtiquetas())
    // solucion para error ExpressionChangedAfterItHasBeenCheckedError
    this.cdRef.detectChanges();
  }

  cambiaSeleccion(event) {
    if (event.value.length <= 0 ) {
      this.controlCampo.setValue(null);
    }
  }
  totalSeleccionados() {
    if (this.multiple) {
      return this.controlCampo.value?.length;
    } else {
      return this.controlCampo.value != '';
    }
  }

  vaciarInput() {
    if (this.multiple) {
      this.controlCampo.setValue(null);
    } else {
      if (this.opcionGeneral) {
        this.controlCampo.setValue('');
      } else {
        this.controlCampo.setValue(null);
      }
    }
    
  }

  numeroEtiquetas() {
    return (this.numMaximoEtiquetas) ? this.numMaximoEtiquetas : this.totalSeleccionados();
  }

  valorOpcionSeleccionada(id) {
    let valor = {nombre: '', id: ''};
    if (id && id.length) {
      valor = this.datos?.find(opcion => opcion?.id?.toString() === id);
    } else {
      valor.nombre = (this.opcionGeneral) ? this.opcionGeneralNombre : '';
    }    
    return valor;
  }

  vaciarSeleccion() {
    this.controlCampo.reset();
  }

  borrarEtiquetaIndividual(topping: any[]) {
    const toppings = this.controlCampo.value as string[];
    topping.forEach( item => this.borrarEtiqueta(toppings, item));
    this.controlCampo.setValue(toppings); // To trigger change detection
  }

  borrarEtiquetaGrupo(topping){
    const toppings = this.controlCampo.value as string[];
    let nuevoValor: string[] = toppings.filter( item => !topping.includes(item));
    this.controlCampo.setValue(nuevoValor);
  }

  private borrarEtiqueta<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  calcularEtiquetas() {
    // console.log('Recalculo etiqueta');
    let margen = 1;
    if (this.multiple) {
      let matChipAncho = 0;
      const matChip = this.element.nativeElement.querySelectorAll('neo-etiqueta-input');
      if (matChip.length && matChip[0]) {
        // console.log('Mat chip ', matChip.length, matChip);
        matChipAncho = matChip[0].offsetWidth + margen;
        // console.log('Ancho mat chip ', matChipAncho);
        const espacioSelect = this.element.nativeElement.querySelector('mat-select-trigger');
        // console.log('espacioSelect', espacioSelect);
        let ancho = espacioSelect.offsetWidth;
        // console.log(`Ancho disponible ${ancho} cada chip ${matChipAncho} si ${(ancho > 0 && ancho > matChipAncho)}  numero: ${Math.floor(ancho / matChipAncho)}`);

        let numEtiquetas = (ancho > 0 && ancho > matChipAncho) ? Math.floor(ancho / matChipAncho) : this.numMaximoEtiquetas;
        this.numMaximoEtiquetas = numEtiquetas;
      }
    }
  }

}
