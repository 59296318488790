import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, map, Observable, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  urlItem = environment.api_base_url + '/cuentas';

  public item$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public totalItems$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService
  ) { }

  getItems(filtros?: any, paginator?: Paginador, tipoPaginador?: TipoPaginadorEnum): Observable<any> {
    let _filtros = this.auxiliar.filtrosDefecto(filtros);
    let request = (tipoPaginador === TipoPaginadorEnum.offline)
      ? this.urlItem
      : `${this.urlItem}?${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => this.totalItems$.next(data.total)),
      map((data: IRespApi) => data)
    );
  }

  /****************************/
  /* FICHEROS                */
  /*****************************/
  subirFichero(ficheros: File[], idObjeto = 1): Observable<any> {
    const formData: FormData = new FormData();
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++)
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
    }
    return this.http.post(`${this.urlItem}/${idObjeto}/ficheros`, formData).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return throwError(err);
      })
    );
  }

  getFicheros(id: string) {
    return this.http.get(`${this.urlItem}/${id}/ficheros`).pipe(
      map((data: IRespApi) => data));
  }

  getFichero(id: string) {

    return this.http.get(`${environment.api_base_url}/ficheros/12`);
    //return this.http.get( `${environment.api_base_url}/ficheros/${id}`);
  }

  eliminarFichero(id: string) {
    return this.http.delete(`${environment.api_base_url}/ficheros/${id}`).pipe(
      map((data: IRespApi) => data));
  }

}
