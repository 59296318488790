<div class="form-field field-radio" *ngIf="controlCampo">
  <mat-radio-group [formControl]="controlCampo" [ngClass]="{'campo-error': controlCampo.invalid && controlCampo.dirty}">
    <mat-radio-button [color]="(color)?color:'primary'" *ngFor="let valor of datos" class="example-margin" value="{{valor['id']}}">
      {{valor['nombre']}}</mat-radio-button>
  </mat-radio-group>
  <div class="error-campo" *ngIf="controlCampo.invalid && controlCampo.dirty && controlCampo.errors?.required">
      <ng-container *ngIf="true">  <div class="pl-10">Seleccione un valor</div>  </ng-container>
  </div>
  <!-- <mat-error class="campo-error" *ngIf="controlCampo.invalid && controlCampo.dirty">
    <neo-mat-error-form
      [nombreCampo]="label"
      [controlCampo]=controlCampo>
    </neo-mat-error-form>
  </mat-error> -->
</div>
