<section class="tablaEditable">
  <div *ngIf="mostrarTituloCabecera" class="section-header">
    <h2 class="text-h2">{{ titulo }}</h2>
  </div>

  <div id="blq-tabla" class="blq-card">
    <h3 class="text-p6" *ngIf="mostrarTotalResultados">Total listado: {{ totalResultados }}</h3>

    <div class="tabla-editable-cont">

      <neo-busqueda *ngIf="filtrado" #filtrosBusqueda placeholder="Busqueda" [keyFiltrado]="keyFiltrado"
        [botonLimpiarBuscador]="true" (limpiarBuscador)="clearBuscador()" (changeBuscador)="eventoCambioBuscador($event)"
      ></neo-busqueda>

      <div class="table-container tabla-editable">
        <table mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let columna of columnas" matColumnDef="{{columna.key}}">
            <ng-container [ngSwitch]="columna.tipo">
              <ng-container *ngSwitchCase="tablaService._TipoColumna.Editable">
                <th mat-header-cell *matHeaderCellDef class="subtitulo text-h4" [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive">{{columna.nombre}}</th>

                <td mat-cell *matCellDef="let row; let posicion = index" [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive">
                  <ng-container *ngIf="!row.filaCreacion; else primeraFila">
                    <ng-container *ngIf="(columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.TextArea ||
                        columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Cantidad ||
                        columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Importe); else inputEdicion">


                      <container-element [ngSwitch]="columna.tipoColumnaEditable">

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Importe">
                        
                          <neo-input-importe-model
                            [importe]="row[columna.key].nombre"
                            [placeholder]="columna.placeholder"
                            [disabled]="celdaEliminada(row) || !editable"
                            (nuevoImporte)="row[columna.key].nombre = $event; this.actualizarDataSource();"
                          ></neo-input-importe-model>
                        </some-element>

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Cantidad">
                          <neo-input-cantidad-model
                            [cantidad]="row[columna.key].nombre"
                            [placeholder]="columna.placeholder"
                            [disabled]="celdaEliminada(row) || !editable"
                            (nuevaCantidad)="row[columna.key].nombre = $event; this.actualizarDataSource();"
                          ></neo-input-cantidad-model>
                        </some-element>

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.TextArea">
                          <mat-form-field appearance="outline" floatLabel="never" class="campo-editable-textarea" [ngClass]="{'textarea-abierto': textAreaOpen[dataSource.filteredData.indexOf(row)]}">
                            <!-- TODO buscar solucion para aplicar clase  campo-editable-ellipsis, mientras usamos pipe-->
                            <input matInput
                                   [ngClass]="{ 'campo-editable-oculto': campoEditableFoco == row}"
                                   [placeholder]="columna.placeholder"
                                   [disabled]="celdaEliminada(row) || !editable"
                                   [value]="row[columna.key].nombre | truncarTexto:30"
                                   (focus)="focusInputMascaraTextareaEdicion(row, dataSource.filteredData.indexOf(row))">
                            <textarea matInput
                                      [ngClass]="{ 'campo-editable-oculto': campoEditableFoco != row}"
                                      cdkTextareaAutosize
                                      #textAreaColumnaEditable = "cdkTextareaAutosize"
                                      cdkAutosizeMinRows = "{{numeroRowsCeldaEditableInicial}}"
                                      cdkAutosizeMaxRows = "{{numeroRowsCeldaEditableInicial}}"
                                      [(ngModel)]="row[columna.key].nombre"
                                      [disabled]="celdaEliminada(row) || !editable"
                                      (click)="$event.stopPropagation()"

                                      (focusout)="focusOutTextAreaEdicion(dataSource.filteredData.indexOf(row));"
                            >
                            </textarea>
                          </mat-form-field>
                        </some-element>
                      </container-element>

                    </ng-container>

                    <ng-template #inputEdicion>
                      <mat-form-field appearance="outline" floatLabel="never">
                        <input class="campo-editable-ellipsis" matInput [placeholder]="columna['placeholder']" [disabled]="celdaEliminada(row) || !editable" [value]="columna.placeholder" [(ngModel)]="row[columna.key].nombre" (change)="actualizarDataSource()">
                      </mat-form-field>
                    </ng-template>

                  </ng-container>

                  <ng-template #primeraFila>
                    <mat-form-field class="icono-mat-suffix" appearance="outline" floatLabel="never">
                      <input matInput [placeholder]="columna.placeholder" [disabled]="!editable" [type]="getInputType(columna)" [value]="'Test'" [(ngModel)]="row[columna.key].nombre"
                        (change)="actualizarDataSource()" (keyup.enter)="$event.stopPropagation();insertarRow(row)">
                    </mat-form-field>
                  </ng-template>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Acciones">
                <th mat-header-cell *matHeaderCellDef [ngClass]="columna?.ancho" [ngClass]="columna?.classResponsive">{{columna.nombre}}</th>
                <td mat-cell *matCellDef="let row; let posicion = index">
                  <ng-container *ngIf="row[columna.key] === tablaService._AccionesTabla.Crear; else btnEliminarRestaurar">
                    <neo-button matSuffix [icono]="true" [color]="''" [iconClass]="'icon-20 icon-ibm--add'" [disabled] = "!editable"
                                (accion)="$event.stopPropagation()" (accion)="insertarRow(row)"></neo-button>
                  </ng-container>
                  <ng-template #btnEliminarRestaurar>
                    <neo-button  matSuffix [icono]="true" [color]="''" [disabled] = "!editable"
                                [iconClass]="(celdaEliminada(row)) ? 'icon-20 icon-ibm--restart' : 'icon-20 icon-ibm--trash-can text-danger' "
                                (accion)="$event.stopPropagation()" (accion)="clickRowIcon(row)"></neo-button>
                  </ng-template>
                </td>
            </ng-container>


              </ng-container>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="keysColumnas; sticky: false"></tr>
          <tr  mat-row *matRowDef="let row; columns: keysColumnas;" (click)="clickRow(row)"
            [ngClass]="{ 'primeraFila' : row?.filaCreacion, 'nuevaFila': row?.nueva }"></tr>

        </table>
        </div>

        <!--  *ngIf="dataSource?.filteredData.length === 0 && dataSource?.filteredData[0].filaCreacion || dataSource?.filteredData.length === 0" -->
        <ng-container *ngIf="dataSource?.filteredData.length === 0 || dataSource?.filteredData.length === 0">
        <neo-sin-elementos [texto]="'Sin Elementos'"></neo-sin-elementos>
      </ng-container>

      <div class="blq-acciones" *ngIf="editable">
        <neo-button *ngIf="btnCancelar" [basic]="true" [text]="textoBtnCancelar" [color]="'primary'"
          (click)="cancelarCambios()"></neo-button>
        <neo-button *ngIf="btnGuardar" [flat]="true" [text]="textoBtnGuardar" [color]="'primary'" (click)="guardarCambios()">
        </neo-button>
      </div>
    </div>
  </div>
</section>
