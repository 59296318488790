import {LOCALE_ID, NgModule, Renderer2} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { DatePipe, registerLocaleData } from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
// import {JwtInterceptor} from './core/interceptors/jwt/jwt.interceptor';
// import {HttpErrorInterceptor} from './core/interceptors/httpError/http-error.interceptor';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {CoreModule} from './core/core.module';
import { HomeComponent } from './shared/pages/home/home.component';
import { SharedModule } from './shared/shared-module';
import { NeoSharedModule } from './neo/neoShared/neoShared.module';
import { NeoCoreModule } from './neo/neoCore/neoCore.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from '@appNeo/neoCore/interceptors/loaderInterceptor/loader.interceptor';
import { HttpErrorInterceptor } from './neo/neoCore/interceptors/httpError/http-error.interceptor';
import { JwtInterceptor } from './neo/neoCore/interceptors/jwt/jwt.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


// TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
// import { WINDOW_PROVIDERS } from './shared/services/window/window.service';




registerLocaleData(localeEs, 'es');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    NeoSharedModule,
    NeoCoreModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
    exports: [
        AppComponent
    ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
    // WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
