// TODO: ADAPTADO
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@appNeo/neoCore/guard/authGuard/auth.guard';
import { LayoutComponent } from '@appNeo/neoCore/layout/layout.component';
import { environment } from '@environments/environment';
import { AuthCuentasGuard } from './core/guard/authCuentasGuard/auth-cuentas.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  // },
  {
    path: 'auth',
    loadChildren: () => import('@appNeo/neoAuth/neoAuth.module').then(m => m.NeoAuthModule),
  },
  {
    path: 'error',
    loadChildren: () => import('@appNeo/neoShared/modules/error/error.module').then(m => m.ErrorModule)
  },
  // admin cliente
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  { path: '', redirectTo: '/' + environment.ruta_padre_admin + environment.pagina_aterrizaje, pathMatch: 'full' },
  { path: 'inicio', redirectTo: '/' + environment.ruta_padre_admin + environment.pagina_aterrizaje },
  { path: '**', redirectTo: '/error/404' },
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
