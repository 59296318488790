export class ITodoNodoItem {
    item: string;
    id?: string;
    children?: ITodoNodoItem[];
    visible?: boolean;
    coincidenciaBusqueda?: boolean;
    level?: number;
    disabled?: boolean;
    editable?: boolean;
    seleccionado?: boolean;
    descripcion?: boolean;
}
  
/** Flat to-do item node with expandable and level information */
export class ITodoNodoItemPlano {
    item: string;
    level: number;
    expandable: boolean;
    visible?: boolean;
    disabled?: boolean;
    coincidenciaBusqueda?: boolean;
    editable?: boolean;
    id?: string;
}