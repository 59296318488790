import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter} from '@angular-material-components/moment-adapter';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ClasesCampoLayoutCamposPorcentajeFormularioEnum, ClasesCampoLayoutCamposAnchoFijoFormularioEnum, IFormInput, LayoutFormularioEnum, ClasesFormularioAnchoEnum} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, switchMap, tap} from 'rxjs/operators';
import {ExtensionFormInput, FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {environment} from '@environments/environment';
import {CampoFicheroComponent} from '@appNeo/neoShared/components/campo-fichero/campo-fichero.component';
import { FicheroSubidaComponent } from '../fichero-subida/fichero-subida.component';
import {CampoTelefonoComponent} from '@appNeo/neoShared/components/campo-telefono/campo-telefono.component';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { TipoDatePickerEnum } from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';


//TODO: Analizar opción varios formatos
export const FORMATO_SELECTOR_FECHA_HORA = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm'
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMMM YYYY',
    dayShortLabel: 'L',
    dayShortA11yLabel: 'L',
  }
};



@Component({
  selector: 'neo-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
  providers: [
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: NGX_MAT_DATE_FORMATS, useValue:  FORMATO_SELECTOR_FECHA_HORA},
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
  ]
})
export class FormularioComponent implements OnInit, OnDestroy {
  @Output() submitForm = new EventEmitter ();
  @Output() limpiarNotificacion = new EventEmitter ();
  @Output() cancel = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() closeEdit = new EventEmitter();
  @Output() changeValueForm = new EventEmitter();
  //neo-campo-checkbox (Necesario para escenarios en los que se abre un dialog en vez de redirigir)
  @Output() campoCheckboxLinkClick = new EventEmitter<IFormInput>();
  @Input('aplicarFlex') aplicarFlex: boolean = true;
  @Input('formularioAncho') formularioAncho: ClasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  @Input('identificador') identificador: string = 'formulario'; 
  
  @Input('layout') layout: LayoutFormularioEnum = LayoutFormularioEnum.layoutCamposPorcentaje;
  @Input('valoresIniciales') valoresIniciales = {}; // Pueden venir valores en json campos  [valor] o mediante este input todo el data del formulario, se hara un reset
  @Input('camposFormulario') camposFormulario;
  @ViewChild('formElement') formElement: ElementRef;
  @ViewChildren(CampoFicheroComponent) camposFichero: QueryList<CampoFicheroComponent>;
  @ViewChildren(FicheroSubidaComponent) appFicheroSubida: QueryList<FicheroSubidaComponent>;
  @ViewChildren(CampoTelefonoComponent) camposTelefono: QueryList<CampoTelefonoComponent>;

  numeroMaximoEtiquetas = environment.numero_maximo_etiquetas_defecto;
  campos: IFormInput [];
  formValidators: FormGroup;
  formValueChanges: boolean = false;
  formValueReset: boolean = true;

  // public min = new Date();
  // public horaInicioDefault = new Date( this.min.getFullYear(), this.min.getMonth(), this.min.getDate(), 10, 0);
  // ayuda auxiliar para detectar cambio campo
  formularioAntesDeCambio;
  //Ficheros
  ficheros = [];


  // subscription
  subForm : Subscription;
  subFormValueChange : Subscription;

  clasesCampoDefectoLayoutCamposPorcentaje  = {
    input: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    inputFilter: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    textarea: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    select: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    chipsAutocomplete: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    date: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    boolean: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    importe: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    cantidad: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    telefono: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    radio: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    checkbox: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    contrasena: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    captcha: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    fichero: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    estado: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    editor: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    clipboard: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
  }


  clasesCampoDefectoLayoutCamposAnchoFijo  = {
    input: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    inputFilter: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    textarea: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    select: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    chipsAutocomplete: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    date: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    boolean: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    importe: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    cantidad: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    telefono: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    radio: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    checkbox: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    contrasena: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    captcha: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    fichero: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    estado: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx3x],
    editor: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    clipboard: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x]
  }

  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    private formularioService: FormularioService,
    private auxiliarService: AuxiliarService
  ) {
  }

  ngOnInit() {
    this.formValidators = this.fb.group([]);
    if (this.camposFormulario ) {
      this.establecerCampos(this.camposFormulario);
    } else {
      this.subscriptionForm();
    }

  }

  ngOnDestroy() {
    // console.log('Destroy formulario dinamico ');
    if ( this.subForm ) {
      this.subForm.unsubscribe();
    }
    if ( this.subFormValueChange ) {
      this.subFormValueChange.unsubscribe();
    }
  }

  subscriptionForm() {
    this.subForm = this.formularioService.inputs$.subscribe( data => {
      // console.log('subscriptionForm ', data);
      this.establecerCampos(data);
    })
  }

  get _FormTagEnum() {
    return FormTagEnum;
  }

  private establecerCampos(data) {
    if ( data && data.length ) {
      this.campos = data;
      this.buildForm();
    }
  }
  private creaFormGroup(): FormGroup {
    const group: any = {};

    this.campos.forEach( campo => {
      let control;
      if (campo.tag === FormTagEnum.date && campo.tipoDatePicker === TipoDatePickerEnum.FechaRango ) {
        control = new FormGroup({  
          start: new FormControl(),  
          end: new FormControl()  
        });  
        const valids: ValidatorFn[] = this.generarValidadoresCampo( campo );
        control.get('start').setValidators(valids);
        control.get('end').setValidators(valids);
        if ( campo.valor ) { control.setValue( campo.valor ); }
        if ( campo.disabled ) { control.disable(); }
      } else{

        control = new FormControl( '' );
        const valids: ValidatorFn[] = this.generarValidadoresCampo( campo );
        // console.log('================================');
      // console.log(campo.campo.label);
      // console.log('Validadores de ' + campo.campo.label, valids);
      control.setValidators( valids );
      if ( campo.valor ) { control.setValue( campo.valor ); }
      if ( campo.disabled ) { control.disable(); }

      // Para los campos lógicos, su no tienen valor, ponemos por defecto false
      // En este caso pierde sentido el tema de obligatorio
      // if ( !campo.valor && campo.tag === FormTagEnum.boolean ) { control.setValue( false ); }
      }

      group[campo.formControlName] = control;
    });

    return new FormGroup(group);
    // return new FormGroup(group, this.validaDependientesExcluyentes() );

  }

  public generarValidadoresCampo(campo: IFormInput): ValidatorFn[]{
    let validadores: ValidatorFn[] = [];

    // obligatorio
    if ( campo.obligatorio ) {
      if (campo.tag === FormTagEnum.boolean || campo.tag ===  FormTagEnum.checkbox){
        validadores.push( Validators.requiredTrue );
      }else {
        validadores.push( Validators.required );
      }
    }

    // control longitud
    if ( (
      (campo.tag === FormTagEnum.contrasena && !this.activadaExtension(campo, ExtensionFormInput.criteriosValidez)) ||
      campo.tag === FormTagEnum.textarea ||
      campo.tag === FormTagEnum.input  ||
      campo.tag === FormTagEnum.telefono ||
      campo.tag === FormTagEnum.editor ) &&
      !isNaN( campo.minLength ) &&
      ( campo.minLength !== null ) &&
      ( campo.minLength > 0 ) ) {
         validadores.push( Validators.minLength( campo.minLength ) );
      }
    if ( (
      (campo.tag === FormTagEnum.contrasena && !this.activadaExtension(campo, ExtensionFormInput.criteriosValidez))||
      campo.tag === FormTagEnum.textarea ||
      campo.tag === FormTagEnum.input  ||
      campo.tag === FormTagEnum.editor ) &&
    !isNaN( campo.maxLength ) &&
    ( campo.maxLength !== null ) && campo.maxLength > 0 ) {
      validadores.push( Validators.maxLength( campo.maxLength ) );
    }

    // min max valores
    if ( (
      campo.tag === FormTagEnum.cantidad  || campo.tag === FormTagEnum.importe
     ) && ( campo.min !== null ) ) {
        validadores.push( Validators.min( <number> campo.min ) );
        }
    if ( ( campo.tag === FormTagEnum.cantidad || campo.tag === FormTagEnum.importe
     ) && ( campo.max !== null ) ) {
          validadores.push( Validators.max( <number> campo.max ) );
    }

    // especificos formato
    // email
    if ( campo.tag === FormTagEnum.input  && campo.type === 'email') {
      validadores.push( Validators.pattern( this.auxiliarService.patternEmail()) );
    }
    if ( campo.tag === FormTagEnum.importe) {
      validadores.push( Validators.pattern("^-?[0-9]*\.[0-9]{1,3}$") );
    }

    return validadores;

  }
  
  public generarErrorValidacionCausaExternos(campoError, idCampo) {
    console.log('Forzar Validator error en el campo ', campoError, idCampo);
    this.addErrors( campoError, this.formValidators.get(idCampo));
    this.disparaValidacionCampos(this.formValidators);
  }

  private addErrors(errors: { [key: string]: any }, control: AbstractControl) {
    if (!control || !errors) {
      return;
    }
    console.log('Nuevo error ', control.errors, errors);
    control.setErrors({ ...control.errors, ...errors });
  }


  subscriptionValueChanges(){
    this.subFormValueChange = this.formValidators.valueChanges
    .pipe(
      tap(limpiarNotificacion => this.cleanError()),
      tap(event => this.formValueChanges = false),
      debounceTime(500),
      // distinctUntilChanged((formEntityOld, formEntityNew) => JSON.stringify(formEntityOld) === JSON.stringify(formEntityNew)),
      // tap(event => {
      //   // console.log('Se produce un cambio: ', event, this.formValidators.value);
      //   this.changeValueForm.emit( event );
      // }),
      distinctUntilChanged((formEntityOld, formEntityNew) => {
        this.formularioAntesDeCambio = (JSON.stringify(formEntityOld) !== JSON.stringify(formEntityNew)) ? formEntityOld : null;
        return JSON.stringify(formEntityOld) === JSON.stringify(formEntityNew);
      }),
      tap(event => {
        let campos = [];
        Object.entries(event).filter(([key, value]) => {
          if (this.formularioAntesDeCambio &&  event[key] !== this.formularioAntesDeCambio[key]) {
            campos.push(key);
          }
        });
        console.log('Detectado cambio en formularios [',{event, campos},']');
        this.changeValueForm.emit( {event, campos} );
      }),
      switchMap(formEvent => {
        this.formValueChanges = true;
        return fromEvent(this.formElement.nativeElement, 'submit')
          .pipe(
            first()
          );
      })
    )
    .subscribe(this.submit.bind(this));
  }

  buildForm() {
    this.formValidators  = this.creaFormGroup();
    if (this.valoresIniciales && Object.keys(this.valoresIniciales).length ) {
      this.reset(this.valoresIniciales);
    }
    this.subscriptionValueChanges();
  }


  cleanError() {
    this.limpiarNotificacion.emit();
  }

  submit() {
    // console.log('Submit', this.obtenerForm());
    this.formValidators.markAllAsTouched();
    if (this.formValidators.valid) {
      let formValue = this.obtenerForm();
      // console.log(formValue);
      this.submitForm.emit( formValue );
    } else {
      this.focusFirstInputInvalid();
    }

  }

  btnCancelClick() {
    this.cancel.emit();
  }

  esFormularioValido() {
    return this.formValidators.valid;
  }
  // Todo: INPUT-FILTER: Enviar en el valor del formulario la entidad selecionada con un id de elemento a mayores
  public obtenerForm(devolverCamposUndefined = false): any {
    // console.log(this.formValidators.value);

    let formValue;
    formValue =  { ... this.formValidators.value};
    for (let clave in formValue){
      if (formValue[clave] === undefined && !devolverCamposUndefined) {
        delete formValue[clave];
      }
      if (formValue[clave] === '' && !devolverCamposUndefined) {
        delete formValue[clave];
      }
      if ( Array.isArray( formValue[clave] ) ) {
        formValue[clave] = formValue[clave].toString();
      }

      // extraer ficheros
      
    }

    if (this.camposFichero.length){
      this.camposFichero.forEach((item, index, array)=>
        {
          formValue[item.nombreCampo] = item.storeFicheros[item.nombreCampo];
          
        }
      )
    }

    
    return formValue;
  }

  focusFirstInputInvalid() {
    const firstInvalidControl: HTMLElement = this.element.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }

    // for (const key of Object.keys(this.formValidators.controls)) {
    //   if (this.formValidators.controls[key].invalid) {
    //     console.log('Campo invalido ', key);
    //     const input = this.element.nativeElement.querySelector('[formcontrolname="' + key + '"]');
    //     console.log(input);
    //     if (input) {
    //       console.log(key, 'focus');
    //       // input.focus()
    //       input.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    //     }
    //     break;
    //   }
    // }
  }

  public validaCampos(emitirEventoDatosRecibidos: boolean = false, devolverCamposUndefined = false): any {
    // console.log('VALIDA CAMPOS: formValueChanges ', this.formValueChanges, 'formValueReset:', this.formValueReset);
    if ( this.formValueChanges || this.formValueReset) {
      // console.log(this.formValidators.value);

      // console.log('Hay cambios en formulario ó reset ');
      this.formValueChanges = false;
      this.formValueReset = false;
      // Pensado para llamar desde componente padre de este componente a través de @ViewChild
      // Dispara la validación de todos los campos y devuelve el formulario
      const obj = { formulario: null, valores: null};
      console.log('Objeto formulario ', obj);
      if ( this.formValidators ) {
        // validamos todos los campos
        this.disparaValidacionCampos(this.formValidators);
        obj.formulario = this.formValidators;
        obj.valores = this.obtenerForm(devolverCamposUndefined);
        // si es valido
        if ( emitirEventoDatosRecibidos && obj.formulario.valid ) { this.submit() }
      }
      // console.log('Objeto a devolver ', obj);
      return obj;
    } else {
      // console.log('Submit de formulario sin cambios');
    }
  }



  private disparaValidacionCampos(formGroup?: FormGroup) {
    // console.log('Dispara validacion campos ', Object.keys(this.formValidators.controls));
    // itera por todos lo campos. Si un campos es, a su vez, un grupo de campos, llama recursivo
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // console.log(field, control);
        if (control.hasValidator(Validators.requiredTrue)
        || (!control.value && control.hasValidator(Validators.required))
        )
        {
          control.markAsDirty();
        }
      } else if (control instanceof FormGroup) {
        this.disparaValidacionCampos(control);
      }
    });
    this.focusFirstInputInvalid();

  }


  // adaptacion  a filtros
  public reset( valoresForm?: any ) {
    // console.log('Reset: ', this.formValidators.value, valueForm);
    if (!valoresForm) valoresForm = this.valoresIniciales;

    if (valoresForm) {
      if (this.camposTelefono) this.resetCamposTelefono(valoresForm);
      this.formValidators.reset( valoresForm );
      this.formValueReset = true;
    }
  }

  /*
    Función necesaria para aplicar reset al input ngx-mat-intl-tel-input de <neo-campo-telefono>
   Ya que el la función reset() de ngx-mat-intl-tel-input únicamente lo vacía, no permite parametrizar el valor por defecto
   y el reset del FormGroup únicamente aplica si el formControlName asociado tiene un valor por defecto != null | undefined | ""
  */
  private resetCamposTelefono(valoresForm: object) {
    this.camposTelefono?.forEach(campo => {
      campo.input.reset();
      Object.entries(valoresForm).find(([key, value]) => {
        if (key === campo.controlName) campo.controlCampo.setValue(value);
      });
    });
  }

  private esObligatorio (input: IFormInput): boolean  {
    return ( input.obligatorio );
  }

  public vaciarInput(input: IFormInput) {
    const inputFormGroup = this.formValidators.get(input.formControlName);
    inputFormGroup.reset();
  }

  onToppingRemoved(id: string, topping: any[]) {
    // console.log('topping ', topping);
    const controlCampo = this.formValidators.get(id);
    const toppings = controlCampo.value as string[];
    topping.forEach( item => this.removeFirst(toppings, item));
    this.formValidators.get(id).setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }


  onFileSelected(){}

  numeroTags(){
    return 3;

  }

  nombreOpcionSeleccionada(datos, id) {
    // return 'CASO ' + id;
    return datos.find(opcion => opcion.id.toString() === id).nombre;

  }

  activadaExtension( campo: IFormInput, extension: ExtensionFormInput) {
    return (campo?.activarExtensiones && campo.activarExtensiones.length && campo.activarExtensiones.includes(extension));
  }

  getExtensionFormInput() {
    return ExtensionFormInput;
  }

  adjuntarFichero() {
    console.log('Adjuntar fichero!!');
  }


  /* Input Filter */
  saltarValidador(error, controlCampo) {
    console.log(this.formValidators.controls[controlCampo]);
    if (this.formValidators.controls[controlCampo] && this.formValidators.controls[controlCampo]?.value && this.formValidators.controls[controlCampo]?.value.length > 0) {
      this.formValidators.controls[controlCampo].setErrors({
        [`${error}`]: true
      });
    }
  }

  reiniciarValidador(error, controlCampo) {
    this.formValidators.controls[controlCampo].setErrors({
      [`${error}`]: false
    });
    this.formValidators.controls[controlCampo].updateValueAndValidity();
  }

  nuevoItemInputFilter(opciones: object []) {
    console.log('Nuevas opciones emit: ', opciones);
  }

  preSubirArchivos(event) {
    console.log('Emit preSubirArchivos', event);
  }
  submitFicheroSubida(event) {
    console.log('Emit submitFicheroSubida', event);
  }
  borrarFichero(event) {
    console.log('Emit borrarFichero', event);
  }

  get _LayoutFormularioEnum(){
    return LayoutFormularioEnum;
  }

  public actualizarValidador(newValidator:  ValidatorFn [], key: string) {
    console.log(' Aplicar validador ', key, newValidator);
    this.formValidators.controls[key].setValidators(newValidator);
    this.formValidators.controls[key].updateValueAndValidity();
  }

  obligatoriedadCampos(formInput, obligatorio: boolean = true, camposCondicionantes = [], reiniciarValidadorDefecto = false) {
    if (camposCondicionantes.length > 0) {
      if (!obligatorio) {
        camposCondicionantes.map(campo => {
          if (this.formValidators.controls[campo].hasValidator(Validators.required) ||
            this.formValidators.controls[campo].hasValidator(Validators.requiredTrue)) {
            this.formValidators.controls[campo].removeValidators([Validators.required, Validators.requiredTrue]);
            this.formValidators.controls[campo].updateValueAndValidity();
          }
        });
      } else {
        camposCondicionantes.map(campo => {
          let campoInicial = this.auxiliarService.busquedaPropiedad(formInput, 'formControlName', campo);
          if (!reiniciarValidadorDefecto) {
            campoInicial.obligatorio = true;
          }
          let validadores = this.generarValidadoresCampo(campoInicial);
          this.formValidators.controls[campo].setValidators(validadores);
          this.formValidators.controls[campo].updateValueAndValidity();
        });
      }
    }
  }

  visibilidadCampos(formInput, visible: boolean = true, camposCondicionantes = [], camposDisabled?: string[]) {
    if (camposCondicionantes.length > 0) {
      if (visible) {
        camposCondicionantes.forEach(key => this.auxiliarService.busquedaPropiedad(formInput, 'formControlName', key).oculto = false);
        setTimeout(() => {
          if (this.formValidators) {
            camposCondicionantes.forEach(campo => {
              if (camposDisabled && camposDisabled.indexOf(campo) >= 0) {
                this.formValidators?.get(campo).disable()
              } else {
                this.formValidators?.get(campo).enable()
              }
            });
          }
        })

      } else {

        camposCondicionantes.forEach(key => { console.log(key); this.auxiliarService.busquedaPropiedad(formInput, 'formControlName', key).oculto = true });
        setTimeout(() => {
          if (this.formValidators) {
            camposCondicionantes.forEach(campo => {
              this.formValidators?.get(campo).disable()
            });
          }
        })
      }
    }
  }

}


