import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import { TipoDatePickerEnum } from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';

export enum TipoColumnaTablaEnum {
  //La diferencia entre enlace principal y secundario es únicamente el aspecto
  EnlacePrincipal    = 'enlacePrincipal',
  EnlaceSecundario    = 'enlaceSecundario',
  Estado   = 'estado',
  Boolean = 'boolean',
  Texto   = 'texto',
  Acciones  = 'acciones',
  Icono   = 'icono',
  Imagen = 'imagen',
  Selector = 'selector',
  //TODO: CAMBIAR NOMENCLATURA PARA ADAPTAR A UNIDADES peso, moneda,...
  Importe = 'importe',
  Fecha = 'fecha',
  FechaHora = 'fechaHora',
  Hora = 'hora',
  Editable = 'editable',
  Avatar = 'avatar' ,
  Otro   = 'otro',
  DespliegueMasInfo = 'despliegueMasInfo' // columna configurada automaticamente, nunca desde usuario, privada
}
export enum IClasesCeldas {
  cell40 = 'cell-40',
  cell60 = 'cell-60',
  cell80 = 'cell-80',
  cell100 = 'cell-100',
  cell120 = 'cell-120',
  cell140 = 'cell-140',
  cell160 = 'cell-160',
  cell180 = 'cell-180',
  cell200 = 'cell-200',
  cell220 = 'cell-220',
  cell320 = 'cell-320',
  cellleft = 'cell-left',
  cellcenter = 'cell-center',
  cellright = 'cell-right',
}
export enum AnchoColumnaTablaEnum {
  lg = 'th-lg',
  md = 'th-md',
  xs = 'th-xs'
}

export interface IColumnaTabla  {
  key: string;
  nombre: string;
  tipo: TipoColumnaTablaEnum;
  tagCampo?: TagCampoEnum;
  tipoDatePicker?: TipoDatePickerEnum;
  ordenacion: boolean;
  unidad?: string;
  classResponsive?: ClassResponsiveEnum;
  data?: [object];
  activa?:boolean;
  fija?: boolean;
  tipoColumnaEditable?: TipoColumnaEditableEnum;
  placeholder?: string;
  clasePersonalizada?: IClasesCeldas[]; // las columnas por tag tiene unas aplicadas por defecto, pero podemos sobreescribirlas
}

export interface IBotonAccionCabeceraTabla {
  id: string;
  color: string;
  bloqueClass: string;
  mostrarBtnLg: boolean;
  neoButtonClassLg: string;
  iconClassLg: string;
  neoButtonInputClassLg: string;
  mostrarBtnXs: boolean;
  textoLg: string;
  neoButtonClassXs: string;
  iconClassXs: string;
  neoButtonInputClassXs: string;
  textoXs: string;
}

export interface IAccionRowTabla {
  idAccion: string;
  tooltip: string;
  icono: boolean;
  iconClass: string;
  disabled: boolean;
}

export enum AccionesTablaEnum {
  VerInformacion = 'verInformacion',
  Ocultar = 'ocultar',
  Editar  = 'editar',
  Eliminar = 'eliminar',
  Crear = 'crear',
  Restaurar = 'restaurar',
  Actualizar = 'actualizar'
}


export interface IColumnaTablaEstado {
  id?:string;
  nombre?: string;
  icono?: string;
  iconoClase?: string;
  color?: string;
}

export interface IColumnaTablaAvatar {
  nombre?: string;
  src?: string;
}

export enum TipoColumnaEditableEnum {
  Input = 'input',
  TextArea = 'textarea',
  Cantidad = 'cantidad',
  Importe = 'importe',
}

export interface IColumnaTablaImagen {
  src: string;
  alt: string;
}

export interface IColumnaTablaIcono {
  nombre: string;
  url?: string;
  class?: string;
  visible?: boolean;
}

export interface IColumnaTablaLink {
  id?: string;
  pag?: string;
  queryParams?: any[];
  class?: string;
  visible?: boolean;
}

export interface ICeldaEditable {
  id?: string;
  nombre?: string | number;
  filaCreacion?: boolean;
  nueva?: boolean;
  acciones?: AccionesTablaEnum;
}

export interface IActualizacionCeldaEditable {
  row: object;
  columnaKey: string;
  valorAnterior: string;
  valorActualizado: string;
}

@Injectable()
export class TablaService {
  // TODO: PLANTEAR SI USAR MATTABLEDATASOURCE O DATA DIRECTAMENTE
  public data$: BehaviorSubject<MatTableDataSource<any>> = new BehaviorSubject<MatTableDataSource<any>>(null);
  public filteredData$: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  public dataTablaEditable$: BehaviorSubject<ICeldaEditable[]> = new BehaviorSubject<ICeldaEditable[]>([]);
  public seleccionarTodo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public seleccionarItems$: BehaviorSubject<object []> = new BehaviorSubject<object []>(null);
  public columnas$: BehaviorSubject<[]> = new BehaviorSubject<[]>(null) ;
  public total$: BehaviorSubject<number> = new BehaviorSubject<number>(0) ;
  public btnClick$: BehaviorSubject<[]> = new BehaviorSubject<[]>(null) ;
  constructor() { }

  set data(data){
    this.data$.next(data);
  }

  set filteredData(data){
    this.filteredData$.next(data);
  }

  set dataTablaEditable(data){
    // console.log('Recibimos data para tabla EDITABLE en tableservice ', data);
    this.dataTablaEditable$.next(data);
  }

  set total(total){
    this.total$.next(total);
  }

  seleccionarTodo(){
    this.seleccionarTodo$.next(true);
  }

  set seleccionarItems(items: object []){
    this.seleccionarItems$.next(items);
  }

  deSeleccionarTodo(){
    this.seleccionarTodo$.next(false);
  }

  set columnas(columnas) {
    this.columnas$.next(columnas);
  }

  get _TipoColumna() {
    return TipoColumnaTablaEnum;
  }

  get _TipoColumnaEditable() {
    return TipoColumnaEditableEnum;
  }

  get _IClasesCeldas() {
    return IClasesCeldas;
  }

  get _AccionesTabla() {
    return AccionesTablaEnum;
  }

  determinarVisibilidadColumnas(data, columnas: IColumnaTabla[]): IColumnaTabla[] {
    const keysAcciones = [];
    let accionesVisibles = 0;

    columnas?.forEach((columna, index) => {
      if (columna.tipo === TipoColumnaTablaEnum.Acciones) {
        const infoColumna = {
          key: columna.key,
          indice: index
        };
        keysAcciones.push(infoColumna);
      }
    });

    keysAcciones.forEach(keyColumna => {
      // console.log(keyColumna, data);
      if (data && data[0]) {
        if (data[0][keyColumna.key]?.mostrarBorrado) accionesVisibles ++;
        if (data[0][keyColumna.key]?.mostrarEdicion) accionesVisibles ++;
        if (data[0][keyColumna.key]?.mostrarAccionOcultar) accionesVisibles ++;
      }
      if (accionesVisibles < 2) {
        if (columnas[keyColumna.indice].nombre){
          columnas[keyColumna.indice].nombre = '';
        }
      }
      accionesVisibles = 0;
    });

    return columnas;
  }
}
