
<div class="estado-container">
    <div class="estado">
        <div class="estado-icono">
            <neo-icono [iconoClase]="'icon-20 ' + item.icono  "></neo-icono>
        </div>
        <div class="estado-cuerpo">
            <neo-span-inner-html [contenido]="item?.texto"></neo-span-inner-html>
            <div class="estado-pie text-muted">
                {{ item.fecha | date: 'H:mm a' |  uppercase }} - {{ item.fecha | date: 'shortDate' }}
            </div>
        </div>            
    </div>
</div>
