import { CdkTextareaAutosize } from '@angular/cdk/text-field/autosize';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@appNeo/neoCore/services/auth/auth.service';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { ITEMS_HISTORIAL } from '@appNeo/neoShared/pages/demo-componentes/demo-componentes-data';
import { Observable } from 'rxjs';
import { NotificacionComponent } from '../../notificacion/notificacion.component';

export enum HistoriaTipoEnum {
  comentario = 'comentario',
  estado = 'estado',
}

export interface IHistoriaItem {
  texto:  string,
  fecha:  Date,
  tipo: HistoriaTipoEnum,
  avatar?: string,
  avatarAlt?: string,
  titulo?: string,
  icono?: string,
}

export enum LabelBotoneraOrdenacionEnum {
  reciente = 'Más reciente primero',
  antiguo = 'Más antiguo primero'
}

@Component({
  selector: 'neo-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush


})
export class HistorialComponent implements OnInit {

  // @Input() items :IHistoriaItem[] = [];
  @Output() crearItem = new EventEmitter();

  listadoHistorial: MatTableDataSource<IHistoriaItem> = new MatTableDataSource<IHistoriaItem>([]);
  listadoHistorial$: Observable<IHistoriaItem[]>;
  @ViewChild('notificacion') notificacion: NotificacionComponent;
  @ViewChild('textAreaColumnaEditable') textAreaColumnaEditable: CdkTextareaAutosize;
  botoneraPieVisible: boolean = false;
  form: FormGroup;
  numeroRowsTextareaInicial = 1;
  numeroRowsTextareaFoco = 10;
  expandirTextarea = false;
  botoneraOrdenacion = [{
      label: LabelBotoneraOrdenacionEnum.reciente,
      id: 'btn-cancelar',
      color: 'primary',
      disabled: false,
      activo: true,
      iconoClase: 'icon-20 icon-dua--caret-sort',
      matsuffix: true,
    basic: true,
    clase: 'btn-sm'
    }];

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private element: ElementRef,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.buildForm();

  }

  refresh() {
    this.cd.detectChanges();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      inputHistorial: new FormControl(),
    });
  }

  determinarOrdenacion(evento) {
    console.log('Derterminar ordenacion.... ', this.botoneraOrdenacion[0].label, evento);
    // this.items = this.ordenAscendente;
    const contenedorHistorias: HTMLElement = this.element.nativeElement.querySelector(
      '.timeline'
    );
    if (contenedorHistorias){
      if(this.botoneraOrdenacion[0].label === LabelBotoneraOrdenacionEnum.reciente) {
        contenedorHistorias.scrollTop = contenedorHistorias?.scrollHeight;
        this.botoneraOrdenacion[0].label = LabelBotoneraOrdenacionEnum.antiguo;
      } else {
        contenedorHistorias.scrollTop = 0;
        this.botoneraOrdenacion[0].label = LabelBotoneraOrdenacionEnum.reciente;
      }
    }

  }

  aplicarOrdenacion(ordenacion: LabelBotoneraOrdenacionEnum) {
    const contenedorHistorias: HTMLElement = this.element.nativeElement.querySelector(
      '.timeline'
    );
    if (contenedorHistorias) { 
      if (ordenacion === LabelBotoneraOrdenacionEnum.reciente) {
        contenedorHistorias.scrollTop = contenedorHistorias?.scrollHeight;
        this.botoneraOrdenacion[0].label = LabelBotoneraOrdenacionEnum.antiguo;
      }

      if (ordenacion === LabelBotoneraOrdenacionEnum.antiguo) {
        contenedorHistorias.scrollTop = 0;
        this.botoneraOrdenacion[0].label = LabelBotoneraOrdenacionEnum.reciente;
      }
    }
  }

  // NO USADO
  // public get ordenAscendente(){
  //   return this.items.sort((a, b) => {
  //     return new Date(a.fecha).getTime() - new  Date(b.fecha).getTime()}
  //   );

  // }

  public establecerHistorial(historias: IHistoriaItem[]) {
    this.listadoHistorial.data = historias;
    this.listadoHistorial$ = this.listadoHistorial.connect();
    this.refresh();
    setTimeout(() => {
      // this.determinarOrdenacion();
      setTimeout(() => {
        this.aplicarOrdenacion(LabelBotoneraOrdenacionEnum.reciente);
      })
    });
  }

  focusTextAreaEdicion() {
    this.expandirTextarea = true;
    this.botoneraPieVisible = true;
    this.textAreaColumnaEditable.maxRows = this.numeroRowsTextareaFoco;
  }

  focusOutTextAreaEdicion() {
    setTimeout(() => {
      this.botoneraPieVisible = this.form.get('inputHistorial').value?.length > 0;
      this.expandirTextarea = false;
    } );
    this.textAreaColumnaEditable.maxRows = this.numeroRowsTextareaInicial;
  }

  cancelar() {
    this. limpiarInputHistorial();
  }
  guardar(){
    let item = this.crearComentario(this.form.controls.inputHistorial.value);
    // this.items.push(item);
    this.crearItem.emit(item);
    this. limpiarInputHistorial();
    setTimeout(() => {
      this.expandirTextarea = false;
    });
  }

  limpiarInputHistorial() {
    this.form.get('inputHistorial').setValue('');
    setTimeout(()=> this.botoneraPieVisible = this.form.get('inputHistorial').value?.length > 0 );
  }

  crearComentario(texto) {
    return {
      avatar: this.authService.user.rutaImagenPerfil,
      titulo: this.authService.user.nombre + ' ' + this.authService.user.apellidos,
      texto: texto,
      fecha: new Date(),
      tipo: HistoriaTipoEnum.comentario,
    };
  }

  mostrarResultado(estado: string = 'ok') {
    console.log('[NEO-HISTORIAL] mostrar resultado ', estado);
    this.notificacion.eliminarNotificaciones();
    if (estado === 'ok') {
      this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Comentario creado!','Se ha creado el comentario con éxito', true, IconEnum.Exito, '', true);
    } else {
      this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Ha ocurrido un error!', 'No se ha creado el comentario', true, IconEnum.Error, '', true);
    }
  }


}
