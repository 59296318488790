import { FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { TipoDatePickerEnum } from "@appNeo/neoShared/helpers/enums/TipoDatePicker.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";
import { IConfiguracionCalendario } from "./calendario/calendario.component";
import {  DAYS_OF_WEEK } from 'angular-calendar';
import { CodigoColorCalendarioHexEnum } from "@appNeo/neoShared/pages/demo-calendario/demo-calendario-data";

export const INTERVALOMESESAGENDA = 6;

export const  CONFIGURACIONDEFECTO: IConfiguracionCalendario  = {
  horaInicioDia  : 0,
  horaFinDia : 23,
  semanaEmpiezaEn: DAYS_OF_WEEK.MONDAY,
  diasFinSemana:  [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY],
  locale : 'es',
  intervaloMinutosCampoHora: 30,
  vistaSemana: true,
  vistaDia: true,
  vistaAgenda: true,
}

export const INPUTS_SELECCIONVISTACALENDARIO : IFormInput[] = [
  {
    tag: FormTagEnum.select,
    label: '',
    type: '',
    placeholder: 'Mes',
    formControlName: 'vista',
    textoAyuda: '',
    obligatorio: false,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
 }
]
export enum ACCIONES_FIJAS_APLICARTARIFA {
  accionBloquear = 'accion-bloquear',
  accionEliminarTarifa = 'accion-eliminar-tarifa',
}
export const accionFijasAplicarRangos = [
  { id:9, nombre: 'Bloquear', iconoClase: 'icon-20 icon-ibm--blocked', color: '#000' },
  { id: 10, nombre: 'Eliminar tarifa', iconoClase: 'icon-20 icon-ibm--trash-can', color: '#F44336' }
]

export const INPUTS_SELECCIONRANGO : IFormInput[] = [
  {
    tag: FormTagEnum.date,
    label: 'Rango',
    type: 'fecha',
    tipoDatePicker: TipoDatePickerEnum.FechaRango,
    placeholder: 'dd/mm/aaaa',
    formControlName: 'rango',
    textoAyuda: '',
    // min: new Date(2021, 8, 1),
    // max: new Date(2021, 11, 12),
    obligatorio: true,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
  },
  {
    tag: FormTagEnum.select,
    label: 'Tarifa',
    type: '',
    placeholder: 'Tarifa',
    formControlName: 'idTipo',
    textoAyuda: '',
    opcionGeneral: false,
    multiple: false,
    datos: [
      { id: 1, nombre: 'Tarifa A', iconoClase: 'icon-20  icon-ibm--circle--solid', color: CodigoColorCalendarioHexEnum.CODIGOA },
      { id: 2, nombre: 'Tarifa B', iconoClase: 'icon-20  icon-ibm--circle--solid', color: CodigoColorCalendarioHexEnum.CODIGOB },
      { id: 3, nombre: 'Tarifa C', iconoClase: 'icon-20  icon-ibm--circle--solid', color: CodigoColorCalendarioHexEnum.CODIGOC },
      { id: 4, nombre: 'Descuento', iconoClase: 'icon-20  icon-ibm--circle--solid', color: '#DA1884' },
    ].concat(accionFijasAplicarRangos),
    obligatorio: true,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
  },    

]



export const INPUTS_FORMULARIO_EVENTO : IFormInput[] = [
    {
      tag: FormTagEnum.input,
      label: 'Título',
      type: '',
      placeholder: 'Añade un título y una hora',
      formControlName: 'title',
      maxLength:250,
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    },
    {
        tag: FormTagEnum.date,
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        label: 'Inicio evento',
        type: '',
        // placeholder: 'Seleccione fecha evento',
        formControlName: 'start',
        obligatorio: true,
        validadores: [],
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Fecha,
      label: 'Fin evento',
      type: '',
      // placeholder: 'Seleccione fecha evento',
      formControlName: 'end',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.checkbox,
      label: 'Todo el día',
      type: '',
      placeholder: '',
      formControlName: 'allDay',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Hora,
      label: 'Hora inicio evento',
      type: '',
      // placeholder: 'Seleccione hora inicio evento',
      formControlName: 'horaInicio',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Hora,
      label: 'Hora fin evento',
      type: '',
      // placeholder: 'Seleccione hora inicio evento',
      formControlName: 'horaFin',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    },
    
    {
        tag: FormTagEnum.select,
        label: 'Color',
        type: '',
        placeholder: 'Color evento',
        formControlName: 'color',
        opcionGeneral: false,
        multiple: false,
        datos:  [{
            id: 'red',
            nombre: 'primary: #ad2121 secondary: #FAE3E3',
            meta: {
                primary: '#ad2121',
                secondary: '#FAE3E3',
              }
            },
            {
              id: 'blue',
              nombre: 'primary: #1e90ff secondary: #D1E8FF',
              meta: {
                primary: '#1e90ff',
                secondary: '#D1E8FF',
              }
            },
            {
              id: 'yellow',
              nombre: 'primary: #e3bc08 secondary: #FDF1BA',
              meta: {
                primary: '#e3bc08',
                secondary: '#FDF1BA',
              }
            }
        ],        
        obligatorio: true,
        validadores: [],
    }
];


// TODO crear interface
export const BOTONERAACCIONESCABECERA: any[] = [
  {
    id: 'descargar',
    color: 'primary',
    bloqueClass: 'btn-add',
    mostrarBtnLg: true,
    neoButtonClassLg: 'btn-crear-lg',
    iconClassLg: 'icon-20 icon-ibm--document--pdf',
    neoButtonInputClassLg: 'btn-sm',
    mostrarBtnXs: true,
    textoLg: 'Descargar Eventos',
    neoButtonClassXs: 'btn-crear-xs',
    iconClassXs: 'icon-16 icon-ibm--document--pdf',
    neoButtonInputClassXs: 'btn-sm',
    textoXs: '',
  },
  {
    id: 'crear',
    color: 'primary',
    bloqueClass: 'btn-add',
    mostrarBtnLg: true,
    neoButtonClassLg: 'btn-crear-lg',
    iconClassLg: 'icon-16 icon-ibm--add',
    neoButtonInputClassLg: 'btn-sm',
    mostrarBtnXs: true,
    textoLg: 'Nueva Entrada',
    neoButtonClassXs: 'btn-crear-xs',
    iconClassXs: 'icon-16 icon-ibm--add',
    neoButtonInputClassXs: 'btn-sm',
    textoXs: '',
  }
  
]