
<div>
  <h4 class="page-title mb-20">COMPONENTES GENÉRICOS</h4>

  <mat-tab-group class="no-border">
    <mat-tab>
        <ng-template mat-tab-label>
            Historial
        </ng-template>
        <!-- <h4 class="page-title mb-10">Componente Historial</h4> -->
        <div class="blq-card">
            <neo-historial #historialDemo
             (crearItem)="onCrearItemHistorial($event)"
             >
           </neo-historial>
        </div>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        Kanban
      </ng-template>
      <neo-kanban
      [desactivarMovimientos]="false"
      (clickMovimientoItemColumna)="onClickMovimientoItemColumna($event)"
      (clickMovimientoItemEntreColumnas)="onClickMovimientoItemEntreColumnas($event)"
    ></neo-kanban>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        Subida imágenes
      </ng-template>
      <div class="blq-card">
        <neo-fichero
          [formatosPermitidos]="formatosPermitidos"
          [subidaMultiple]="false"
          [maximoElementosASubir]="10"
          [tamanoMaximoTotal]="1000000000000000000"
          [tamanoMaximoFichero]="200000000000000000"
          [vista]="'card'"
          [visualizarDetalleItems]="true"
          [arrayArchivos]="testArrayArchivos"
          [ordenable] = "true"
          (borrarItems)="eliminarFichero($event)"
          (cambiarPosicion)="peticionCambiarPosicionEjemplo($event)"
          (preSubirArchivos)="preSubirFicheros($event)"
          (subirArchivos)="subirFicheros($event)"
          (editarArchivo)="eventoEditarFichero($event)"
        ></neo-fichero>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Circulo progreso
      </ng-template>
      <div class="blq-card">
        <div class="flex mt-10">

          <neo-circulo-progreso-icono-completado class="inline-flex-center" #progresoComponente [completado]="tiempoProceso >= 100" [identificador]="'DEMO_CIRCULO_PROGRESO'"></neo-circulo-progreso-icono-completado>

          <!-- <div class="progreso-container">
            <neo-icono *ngIf="tiempoProceso >= 100; else progresoCarga" [iconoClase]="'icon-32 icon-ibm--checkmark--filled text-success' "></neo-icono>
            <ng-template #progresoCarga>
              <neo-circulo-progreso #progresoComponente2 class="circulo-progreso"
                                    [identificador]="'DEMO_CIRCULO_PROGRESO'"
              ></neo-circulo-progreso>
            </ng-template>
          </div> -->

          <neo-button tabindex="7"  class="ml-10"
                      [text]="'Iniciar circulo progreso'"
                      [flat]="true"
                      [color]="'primary'"
                      (click)="iniciarCirculoProcesoCarga()"
          ></neo-button>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Contenedor cabecera
      </ng-template>
      <div class="blq-card">
        <neo-contenedor-cabecera
          [titulo]="'Titulo demo'"
          [subTitulo]="'Titulo demo'"
          [botonera]="[
            {
              label: 'Accion#1',
              id: 'btn-cancelar',
              color: 'primary',
              disabled: false,
              activo: true,
              iconoClase: 'icon-20 icon-ibm--save',
              flat: true
            },{
              label: 'Accion#2',
              id: 'btn-cancelar',
              color: 'primary',
              disabled: false,
              activo: true,
              iconoClase: 'icon-20 icon-ibm--edit',
              flat: true
            }
      ]"
          (accionBoton)="onAccionContenedorCabecera($event)"
        >
         # Proyeccion componente
        </neo-contenedor-cabecera>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Árbol
      </ng-template>
      <div class="blq-card">
        <neo-arbol-anidado (submitArbol)="submitRespuestaArbol($event)"></neo-arbol-anidado>
      </div>
      <div class="blq-card">
        <neo-arbol (submitArbol)="submitRespuestaArbol($event)"></neo-arbol>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Drag & drop
      </ng-template>
      <div class="blq-card">
        <neo-drag-drop [atributoOrdenacion]="'id'" [mostrarIdItem]="true"></neo-drag-drop>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Campos avanzados
      </ng-template>
        <h4 class="page-title mb-10">Chips Autocompletable no responsivo</h4>
        <div class="blq-card">
          <neo-formulario
          #formularioChipsNoResponsivo
          [camposFormulario]="formInputChipsNoResponsivo"
          [aplicarFlex]="true"
          [formularioAncho]="'formulario-ancho-reducido'"></neo-formulario>
        </div>

        <h4 class="page-title mb-10">Chips Autocompletable responsivo</h4>
        <div class="blq-card">
          <neo-formulario
            #formularioChipsResponsivo
            [camposFormulario]="formInputChipsResponsivo"
            [aplicarFlex]="true"
            [valoresIniciales]="datosChips"
            [formularioAncho]="'formulario-ancho-reducido'">
          </neo-formulario>
          <!-- <neo-chips-autocomplete #chipsParticipantesResponsivo
            [fuenteItems]="items"
            [responsivo]="true"
            [chipItemsSeleccionados]="itemsPreestablecidos"
            [label]="'Seleccion Chips'" (cambioSeleccion)="submitChipsAutocompletable($event)"></neo-chips-autocomplete> -->
        </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Tabla editable
      </ng-template>
        <div class="blq-card">
          <neo-tabla-editable
          [tablaOnline]="false"
          [titulo]="'Tabla Editable'"
          [filtrado]="true"
          [keyFiltrado]="'tablaEditableDemo'"
          [columnasFiltro]="['proyecto', 'presupuesto', 'incidencias']"
          [btnGuardar]="true"
          [btnCancelar]="true">
         </neo-tabla-editable>
         </div>
    </mat-tab>

  </mat-tab-group>

  <neo-block-spinner></neo-block-spinner>





