<!-- <pre>
  {{propiedades| json}}
</pre> -->
<!-- LAYOUT PERFIL CAMPOS PORCENTAJE -->
<div class="perfil-container perfil-container-campos-porcentaje" [ngClass]="{'perfil-formulario': perfilFormulario}"
    *ngIf="!layout || layout === _LayoutPerfilEnum.layoutCamposPorcentaje">
    <div [class]="perfilAncho">
        <div class="listado-campos" [ngClass]="{'perfil-flex': aplicarFlex}">
            <ng-container *ngFor="let propiedad of propiedades">
                <ng-container *ngTemplateOutlet="templateCampos;context:{propiedad:propiedad,i:i, clasedefecto: clasesCampoDefectoLayoutCamposPorcentaje[propiedad.tagCampo]?.join(' '), clasepersonalizada: propiedad?.clasePersonalizadaLayoutCamposPorcentaje?.join(' '),layout:layout}">
                </ng-container>                   
            </ng-container>
            </div>
            </div>
</div>

<!-- LAYOUT PERFIL CAMPOS ANCHO FIJO -->
<div class="perfil-container perfil-container-campos-fijo" [ngClass]="{'perfil-formulario': perfilFormulario}"
    *ngIf="layout === _LayoutPerfilEnum.layoutCamposAnchoFijo">
    <div [class]="perfilAncho">
        <div class="listado-campos" [ngClass]="{'perfil-flex': aplicarFlex}">
            <ng-container *ngFor="let propiedad of propiedades">
                <ng-container *ngTemplateOutlet="templateCampos;context:{propiedad:propiedad,i:i, clasedefecto: clasesCampoDefectoLayoutCamposAnchoFijo[propiedad.tagCampo]?.join(' '), clasepersonalizada: propiedad?.clasePersonalizadaLayoutCamposAnchoFijo?.join(' '),layout:layout}">
                </ng-container> 
            </ng-container>
        </div>
    </div>
</div>


<ng-template #templateCampos let-propiedad="propiedad" let-i="i" let-clasepersonalizada='clasepersonalizada'
  let-clasedefecto='clasedefecto'  let-layout='layout'>
  <ng-container [ngSwitch]="propiedad.tagCampo"> 
    <div *ngSwitchCase="_TagCampoEnum.Boolean" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.ColorPicker" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.Checkbox" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.Radio" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Contrasena" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Input" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.InputFilter" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Clipboard" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Select" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Estado" class="campo perfil-propiedad"
    [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.ChipsAutocomplete" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Date" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
      <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Importe" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Cantidad" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Telefono" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Textarea" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.Captcha" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Fichero" class="subida-fichero campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.FicheroZonaArrastre" class="campo subida-fichero perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <div *ngSwitchCase="_TagCampoEnum.Editor" class="campo perfil-propiedad"
      [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

    <!-- otros -->
    <div *ngSwitchCase="_TagCampoEnum.Enlace" class="campo perfil-propiedad"
    [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
        <neo-perfil-propiedad [propiedad]="propiedad" [layout]="layout"
          (clickPropiedadEnlace)="clickPropiedadEnlace.emit($event)">
        </neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.Objeto" class="campo perfil-propiedad"
    [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
        <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <div *ngSwitchCase="_TagCampoEnum.ArrayObjeto" class="campo perfil-propiedad"
    [ngClass]="clasepersonalizada ? clasepersonalizada : clasedefecto">
        <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>
    <!-- no enumerado -->
    <div class="campo perfil-propiedad" *ngSwitchDefault>
         <neo-perfil-propiedad  [propiedad]="propiedad" [layout]="layout"></neo-perfil-propiedad>
    </div>

  </ng-container>
</ng-template>