import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthState {
  permissions: Permissions[];
  roles: RolesEnum[];
}


export enum Permissions {
  ADMINISTRADOR = 'administrador',
  // PROYECTOS
  PROYECTOS_FRONT = 'proyectos-front',
  PROYECTOS_FRONT_EDITAR = 'proyectos-front-editar',
  PROYECTOS_FRONT_VER_FACTURAS = 'proyectos-front-ver-facturas',
  PROYECTOS_FRONT_EDITAR_BASICO = 'proyectos-front-editar-basico',
  PROYECTOS_FRONT_EDITAR_ESTADOS = 'proyectos-front-editar-estados',
  PROYECTOS_FRONT_EDITAR_FACTURAS = 'proyectos-front-editar-facturas',
  PROYECTOS_FRONT_SERVICIOS = 'proyectos-front-servicios',
  PROYECTOS_FRONT_SERVICIOS_EDITAR_ANALISISFUNCIONAL = 'proyectos-front-servicios-editar-analisisFuncional',
  PROYECTOS_FRONT_SERVICIOS_EDITAR = 'proyectos-front-servicios-editar',
  PROYECTOS_FRONT_SERVICIOS_EDITAR_BASICO = 'proyectos-front-servicios-editar-basico',
  PROYECTOS_FRONT_SERVICIOS_EDITAR_ESTIMACIONPORDEPARTAMENTO = 'proyectos-front-servicios-editar-estimacionporDepartamento',
  PROYECTOS_FRONT_SERVICIOS_EDITAR_ESTIMACIONTOTAL = 'proyectos-front-servicios-editar-estimacionTotal',
  PROYECTOS_FRONT_SERVICIOS_EDITAR_HITOSFACTURACION = 'proyectos-front-servicios-editar-hitosFacturacion',
  PROYECTOS_FRONT_SERVICIOS_VER_ANALISISFUNCIONAL = 'proyectos-front-servicios-ver-analisisFuncional',
  PROYECTOS_FRONT_SERVICIOS_VER = 'proyectos-front-servicios-ver',
  PROYECTOS_FRONT_SERVICIOS_VER_BASICO = 'proyectos-front-servicios-ver-basico',
  PROYECTOS_FRONT_SERVICIOS_VER_ESTIMACIONPORDEPARTAMENTO = 'proyectos-front-servicios-ver-estimacionporDepartamento',
  PROYECTOS_FRONT_SERVICIOS_VER_ESTIMACIONTOTAL = 'proyectos-front-servicios-ver-estimacionTotal',
  PROYECTOS_FRONT_SERVICIOS_VER_HITOSFACTURACION = 'proyectos-front-servicios-ver-hitosFacturacion',
  PROYECTOS_FRONT_VER = 'proyectos-front-ver',
  PROYECTOS_FRONT_VER_BASICO = 'proyectos-front-ver-basico',
  PROYECTOS_FRONT_VER_ESTADOS = 'proyectos-front-ver-estados',
  
  // CUENTAS
  CUENTAS_FRONT = 'cuentas-front',
  CUENTAS_FRONT_EDITAR = 'cuentas-front-editar',
  CUENTAS_FRONT_VER_DATOSBANCARIOS = 'cuentas-front-ver-datosBancarios',
  CUENTAS_FRONT_VER_FACTURAS = 'cuentas-front-ver-facturas',
  CUENTAS_FRONT_VER_PRECIOSHORAPORDEPARTAMENTO = 'cuentas-front-ver-preciosHoraPorDepartamento',
  CUENTAS_FRONT_FICHEROS = 'cuentas-front-ficheros',
  CUENTAS_FRONT_FICHEROS_SUBIR = 'cuentas-front-ficheros-subir',
  CUENTAS_FRONT_FICHEROS_SUBIR_BASICO = 'cuentas-front-ficheros-subir-basico',
  CUENTAS_FRONT_FICHEROS_SUBIR_PROPUESTASCOMERCIALES = 'cuentas-front-ficheros-subir-propuestasComerciales',
  CUENTAS_FRONT_FICHEROS_SUBIR_CONTRATOS = 'cuentas-front-ficheros-subir-contratos',
  CUENTAS_FRONT_FICHEROS_SUBIR_DOCUMENTOSADICIONAL = 'cuentas-front-ficheros-subir-documentosAdicional',
  CUENTAS_FRONT_FICHEROS_VER = 'cuentas-front-ficheros-ver',
  CUENTAS_FRONT_FICHEROS_VER_BASICO = 'cuentas-front-ficheros-ver-basico',
  CUENTAS_FRONT_FICHEROS_VER_PROPUESTASCOMERCIALES = 'cuentas-front-ficheros-ver-propuestasComerciales',
  CUENTAS_FRONT_FICHEROS_VER_CONTRATOS = 'cuentas-front-ficheros-ver-contratos',
  CUENTAS_FRONT_FICHEROS_VER_DOCUMENTOSADICIONAL = 'cuentas-front-ficheros-ver-documentosAdicional',

  // OPORTUNIDADES
  OPORTUNIDADES_FRONT = 'oportunidades-front',  
  OPORTUNIDADES_FRONT_ACCION = 'oportunidades-front-accion',
  OPORTUNIDADES_FRONT_ACCION_ENVIARPROPUESTAALCLIENTE = 'oportunidades-front-accion-enviarPropuestaCliente',
  OPORTUNIDADES_FRONT_ACCION_GANAR = 'oportunidades-front-accion-ganar',
  OPORTUNIDADES_FRONT_ACCION_INDICARINFORMACIONINSUFICIENTEPARAESTIMAR = 'oportunidades-front-accion-indicarInformacionInsuficienteParaEstimar',
  OPORTUNIDADES_FRONT_ACCION_MANTENERALAESPERA = 'oportunidades-front-accion-mantenerALaEspera',
  OPORTUNIDADES_FRONT_ACCION_PERDER = 'oportunidades-front-accion-perder',
  OPORTUNIDADES_FRONT_ACCION_SOLICITARPRESUPUESTO = 'oportunidades-front-accion-solicitarPresupuesto',
  OPORTUNIDADES_FRONT_ACCION_SOLICITARREUNION = 'oportunidades-front-accion-solicitarReunion',
  OPORTUNIDADES_FRONT_ACCION_SOLICITARVALIDACIONDELDIRECTORMARKETINGVENTAS = 'oportunidades-front-accion-solicitarValidacionDirectorMarketingVentas',  
  OPORTUNIDADES_FRONT_CREAR = 'oportunidades-front-crear',  
  OPORTUNIDADES_FRONT_EDITAR = 'oportunidades-front-editar',
  OPORTUNIDADES_FRONT_EDITAR_BASICO = 'oportunidades-front-editar-basico',
  OPORTUNIDADES_FRONT_EDITAR_CONTACTO = 'oportunidades-front-editar-contacto',
  OPORTUNIDADES_FRONT_EDITAR_DESCRIPCION = 'oportunidades-front-editar-descripcion',
  OPORTUNIDADES_FRONT_EDITAR_FECHA = 'oportunidades-front-editar-fecha',
  OPORTUNIDADES_FRONT_EDITAR_JEFEPROYECTO = 'oportunidades-front-editar-jefeProyecto',
  OPORTUNIDADES_FRONT_EDITAR_NOMBRECUENTA = 'oportunidades-front-editar-nombreCuenta',
  OPORTUNIDADES_FRONT_EDITAR_NOMBREPROYECTO = 'oportunidades-front-editar-nombreProyecto',
  OPORTUNIDADES_FRONT_EDITAR_RESPONSABLECOMERCIAL = 'oportunidades-front-editar-responsableComercial',
  OPORTUNIDADES_FRONT_EDITAR_RESPONSABLETECNICO = 'oportunidades-front-editar-responsableTecnico',
  OPORTUNIDADES_FRONT_EDITAR_TIPOPROYECTO = 'oportunidades-front-editar-tipoProyecto',
  OPORTUNIDADES_FRONT_EDITAR_SOLICITARPRESUPUESTO = 'oportunidades-front-editar-solicitarPresupuesto',
  OPORTUNIDADES_FRONT_EDITAR_SOLICITARREUNION = 'oportunidades-front-editar-solicitarReunion',  
  OPORTUNIDADES_FRONT_HISTORIAL = 'oportunidades-front-historial',  
  OPORTUNIDADES_FRONT_PROPUESTA = 'oportunidades-front-propuesta',
  OPORTUNIDADES_FRONT_PROPUESTA_ACCION_VALIDAR = 'oportunidades-front-propuesta-accion-validar',  
  OPORTUNIDADES_FRONT_PROPUESTA_VER = 'oportunidades-front-propuesta-ver',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_BASICO = 'oportunidades-front-propuesta-ver-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_CONCEPTOS = 'oportunidades-front-propuesta-ver-conceptos',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_CONCEPTOS_BASICO = 'oportunidades-front-propuesta-ver-conceptos-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_CONCEPTOS_CONIMPORTE = 'oportunidades-front-propuesta-ver-conceptos-conImporte',  
  OPORTUNIDADES_FRONT_PROPUESTA_VER_ESTIMACIONHORAS = 'oportunidades-front-propuesta-ver-estimacionHoras',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_ESTIMACIONHORAS_BASICO = 'oportunidades-front-propuesta-ver-estimacionHoras-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_ESTIMACIONHORAS_CONIMPORTE = 'oportunidades-front-propuesta-ver-estimacionHoras-conImporte',  
  OPORTUNIDADES_FRONT_PROPUESTA_VER_FUNCIONAL = 'oportunidades-front-propuesta-ver-funcional',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_REQUISITOS = 'oportunidades-front-propuesta-ver-requisitos',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_LINEASINFRAESTRUCTURA = 'oportunidades-front-propuesta-ver-lineasInfraestructura',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_LINEASINFRAESTRUCTURA_BASICO = 'oportunidades-front-propuesta-ver-lineasInfraestructura-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_LINEASINFRAESTRUCTURA_CONIMPORTE = 'oportunidades-front-propuesta-ver-lineasInfraestructura-conImporte',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_MANTENIMIENTO = 'oportunidades-front-propuesta-ver-mantenimiento',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_MANTENIMIENTO_BASICO = 'oportunidades-front-propuesta-ver-mantenimiento-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_MANTENIMIENTO_CONIMPORTE = 'oportunidades-front-propuesta-ver-mantenimiento-conImporte',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_HITOSFACTURACION = 'oportunidades-front-propuesta-ver-hitosFacturacion',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_HITOSFACTURACION_BASICO = 'oportunidades-front-propuesta-ver-hitosFacturacion-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_VER_HITOSFACTURACION_CONIMPORTE = 'oportunidades-front-propuesta-ver-hitosFacturacion-conImporte',    
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR = 'oportunidades-front-propuesta-editar',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_BASICO = 'oportunidades-front-propuesta-editar-basico',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_CONCEPTOS = 'oportunidades-front-propuesta-editar-conceptos',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_DURACIONESTIMADA = 'oportunidades-front-propuesta-editar-duracionEstimada',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_FUNCIONAL = 'oportunidades-front-propuesta-editar-funcional',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_ESTIMACION = 'oportunidades-front-propuesta-editar-estimacion',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_HITOSFACTURACION = 'oportunidades-front-propuesta-editar-hitosFacturacion',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_LINEASINFRAESTRUCTURA = 'oportunidades-front-propuesta-editar-lineasInfraestructura',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_MANTENIMIENTO = 'oportunidades-front-propuesta-editar-mantenimiento',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_REQUISITOS = 'oportunidades-front-propuesta-editar-requisitos',
  OPORTUNIDADES_FRONT_PROPUESTA_EDITAR_PRECIOTOTAL = 'oportunidades-front-propuesta-editar-precioTotal',
  OPORTUNIDADES_FRONT_TAREAS = 'oportunidades-front-tareas',
  OPORTUNIDADES_FRONT_TAREAS_VERTODASSINSERUSUARIOASIGNADO = 'oportunidades-front-tareas-vertodassinserusuarioasignado',  
  OPORTUNIDADES_FRONT_VER = 'oportunidades-front-ver',
 

}


export enum RolesIdentificadorEnum {
  ADMINISTRADOR = '2',
  ADMIN = '2',
  SW_D  = '3',
  SW_C  = '4',
  SW_T  = '5',
  DIS_D = '6',
  DIS_C = '7',
  DIS_T = '8',
  RRHH_D= '9',
  RRHH_C= '10',
  RRHH_T= '11',
  ADM_D = '12',
  ADM_C = '13',
  ADM_T = '14',
  MKV_D = '15',
  MKV_C = '16',
  MKV_T = '17',
}

export const RolesNoComercial = [
  RolesIdentificadorEnum.SW_D,
  RolesIdentificadorEnum.SW_C,
  RolesIdentificadorEnum.SW_T,
  RolesIdentificadorEnum.DIS_D,
  RolesIdentificadorEnum.DIS_C,
  RolesIdentificadorEnum.DIS_T,
  RolesIdentificadorEnum.RRHH_D,
  RolesIdentificadorEnum.RRHH_C,
  RolesIdentificadorEnum.RRHH_T,
  RolesIdentificadorEnum.ADM_D,
  RolesIdentificadorEnum.ADM_C,
  RolesIdentificadorEnum.ADM_T
];


export enum RolesEnum {
  ADMINISTRADOR = 'ADMIN',
  ADMIN = 'ADMIN',
  SW_D  = 'SW-D',
  SW_C  = 'SW-C',
  SW_T  = 'SW-T',
  DIS_D = 'DIS-D',
  DIS_C = 'DIS-C',
  DIS_T = 'DIS-T',
  RRHH_D= 'RRHH-D',
  RRHH_C= 'RRHH-C',
  RRHH_T= 'RRHH-T',
  ADM_D = 'ADM-D',
  ADM_C = 'ADM-C',
  ADM_T = 'ADM-T',
  MKV_D = 'MKV-D',
  MKV_C = 'MKV-C',
  MKV_T = 'MKV-T',
}

export function createInitialState(): AuthState {

  return {
    permissions: [],
    roles: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }

}
