

<div class="kanban">
    <!-- filtros entidad-->
    <div *ngIf="filtrado" class="tabla-filtros blq-card" [ngClass]="{'contenedorFiltrosOcultarM': numeroFiltros>1, 'contenedorFiltrosOcultarTV': numeroFiltros>2}">
        <neo-filtros-tabla
            [keyFiltrado]="keyFiltrado"
            [valoresIniciales]="valoresInicialesFiltrado"
            (cambioFormulario)="eventoCambioFormularioFiltros($event)"
        >

        <div class="campo-busqueda">
        <neo-busqueda #filtrosBusqueda
            placeholder="Busqueda"
            [valorDefault]="true"
            [botonLimpiarBuscador]="true"
            (limpiarBuscador)="clearBuscador()"
            (changeBuscador)="eventoCambioBuscador($event)"
            class="mr-4 ml-4"
        ></neo-busqueda>
        </div>
        <div class="btns-filtrado" *ngIf="!filtradoSinBotonSubmit && numeroFiltros > 1">
        <neo-button
                        [disabled]="deshabilitarLimpiarFiltros"
                        [text]="'Limpiar filtros'"
                        [basic]="true"
                        [color]="'primary'"
                        [iconClass]="'icon-20 icon-ibm--clean'"
                        (click)="!deshabilitarLimpiarFiltros ? clearFilter(): null;"
        ></neo-button>
        <neo-button
                    [text]="'Aplicar filtros'"
                    [color]="'primary'"
                    [flat]="true"
                    (click)="submitFiltrado()"
        ></neo-button>
        </div>
    </neo-filtros-tabla>
    </div>

    <div class="blq-cont" *ngIf="filtrado">
    <!-- boton acceso filtros en caso de estar ocultos por temas responsivos -->
    <neo-button class="botonAccesoFiltros btn-block"
        [ngClass]="{'botonAccesoFiltrosOcultarM': numeroFiltros==1, 'botonAccesoFiltrosOcultarTV': numeroFiltros==2 || numeroFiltros==1}"
    [text]="'Filtrar'"
    [flat]="true"
    [color]="'secondary'"
    [iconClass]="'icon-20 icon-ibm--filter'"
    (click)="abrirFiltros()"
    ><span *ngIf="cambioValoresFiltrado" class="filtro-activo"></span>
    </neo-button>
    <!--fin filtros entidad-->
    </div>
    <div class="blq-card mt-15">
        <div class="blq-header" *ngIf="mostrarTotalElementos">
            <div *ngIf="btnAccionesLote || botoneraAccionesCabecera" class="blq-acciones">
                <div *ngIf="btnAccionesLote" class="mr-2">
                    <ng-container *ngIf="botoneraAccionesCabecera">
                        <div [ngClass]="accionCabecera.bloqueClass" *ngFor="let accionCabecera of botoneraAccionesCabecera">
                            <neo-button *ngIf="accionCabecera.mostrarBtnLg" [ngClass]="accionCabecera.neoButtonClassLg"
                                [flat]="true" [color]="accionCabecera.color" (click)="accionCabeceraClick.emit(accionCabecera)"
                                [iconClass]="accionCabecera.iconClassLg" [text]="accionCabecera.textoLg"
                                [clase]="accionCabecera.neoButtonInputClassLg"></neo-button>
                            <neo-button *ngIf="accionCabecera.mostrarBtnXs" [ngClass]="accionCabecera.neoButtonClassXs"
                                [flat]="true" [color]="accionCabecera.color" (click)="accionCabeceraClick.emit(accionCabecera)"
                                [iconClass]="accionCabecera.iconClassXs" [text]="accionCabecera.textoXs"
                                [clase]="accionCabecera.neoButtonInputClassXs"></neo-button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="drag-flex " cdkDropListGroup>
            <div  class="drag-container" *ngFor="let columna of kanbanService.columnas$ | async">
                <div class="cabecera">
                    <h3 class="kanban-title text-p5">{{ columna.nombre }}</h3>
                    <div class="kanban-numero-items" *ngIf="mostrarContadorCabecera">
                        <ng-container *ngIf="columna?.items?.length>0 || columna?.totalCabecera">
                            <i class="numero-icono icon-20 text-grey500" [ngClass]="iconoCabecera"> </i>
                            <span class="numero-texto text-grey500">
                                <ng-container *ngIf="columna?.totalCabecera; else totalItems">{{columna?.totalCabecera}}</ng-container>
                                <ng-template #totalItems>{{columna?.items?.length}}</ng-template>
                            </span>
                        </ng-container>
                    </div>                
                </div>
                
                <div cdkDrop cdkDropList
                    id="{{columna.id}}"
                    [cdkDropListData]="columna.items"
                    [ngClass]="(columna.items && columna.items.length>0) ? 'drag-list': 'drag-list drag-list-vacio'" 
                    (cdkDropListDropped)="drop($event)"
                >
                    <ng-container *ngIf="columna.items && columna.items.length>0; else otro">
                        <ng-container *ngFor="let item of columna.items">
                            <div class="drag-box" id="item-drag-{{item?.id}}" [ngClass]="{'move-desactivado': desactivarMovimientos}" *ngIf="item && item.visibilidadFiltrado"
                                (click)="onClickItem($event, item)"
                                [cdkDragDisabled]="desactivarMovimientos"
                                cdkDrag>
                                <div class="drag-placeholder" *cdkDragPlaceholder></div>
                                <!-- <i *ngIf="!desactivarMovimientos" class="icon-20 icon-ibm--draggable text-grey500"></i> -->
                                <ng-template *ngTemplateOutlet="customKanbanCard || defaultTemplate;context:{item:item}">
                                </ng-template>
                                <ng-template #defaultTemplate>
                                    <neo-kanban-card [item]="item"></neo-kanban-card>
                                </ng-template>
                                <ng-container *ngIf="mostrarIdItem">{{ item.id }}</ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                
                    <ng-template #otro>
                        <div class="drag-placeholder" *cdkDragPlaceholder></div>
                        <neo-sin-elementos class="blq-sin-resultados" [texto]="'Sin elementos'"></neo-sin-elementos>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- filtros responsivos ocultos -->
<ng-template #dialogFiltrosResponsivos>
    <neo-acciones-dialog
    [dialogTittle]="'Filtros'"
    [botonCerrarDialogoCabecera]=true
    [botonera]="botoneraDialogFiltrosResponsivos"
    (accionBoton)="submitAccionDialogFiltrosResponsivos($event)"
      >
      <neo-busqueda #filtrosBusqueda
          placeholder="Busqueda"
          [valorDefault]="true"
          [botonLimpiarBuscador]="true"
          (limpiarBuscador)="clearBuscador()"
          (changeBuscador)="eventoCambioBuscador($event)"
      ></neo-busqueda>
      <neo-filtros-tabla
         [keyFiltrado]="keyFiltrado"
         [valoresIniciales]="valoresInicialesFiltrado"
         (cambioFormulario)="determinarCambioValoresFiltradoIniciales($event)"
      ></neo-filtros-tabla>
  </neo-acciones-dialog>
</ng-template>