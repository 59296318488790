import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LogoComponent} from './components/logo/logo.component';
import {MessageComponent} from './components/message/message.component';
import {ButtonComponent} from './components/button/button.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {TableComponent} from './components/tabla/table/table.component';
import {DialogAlertComponent} from './components/dialog-alert/dialog-alert.component';
import {DynamicPipe} from './helpers/pipes/dynamicPipe/dynamic.pipe';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {NoElementsComponent} from './components/no-elements/no-elements.component';
import {ItemComponent} from './components/item/item.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {BusquedaComponent} from './components/tabla/busqueda/busqueda.component';
import {RouterModule} from '@angular/router';
import {CaptchaComponent} from './components/captcha/captcha.component';
import {DynamicComponentDirective} from './directives/dynamic-component.directive';
import {KeypadComponent} from './components/keypad/keypad.component';
import {HeaderComponent} from './components/header/header.component';
import {BreadCrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {EditorComponent} from './components/editor/editor.component';
import {ComentarioComponent} from './components/comentarios/comentario/comentario.component';
import {BlockSpinnerComponent} from './components/block-spinner/block-spinner.component';
import {ClickOutsideModule} from 'ng-click-outside';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageAvatarPipe } from './helpers/pipes/imageAvatar/image-avatar.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PropuestaCardComponent } from '../admin/pages/oportunidades/components/propuestas/propuesta-card/propuesta-card.component';
import { FormFieldsetComponent } from './components/form-fieldset/form-fieldset.component';
import { SelectNgxSingleSelectionComponent } from './components/select-ngx-single-selection/select-ngx-single-selection.component'
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { EmbudoCardOportunidadComponent } from './components/embudo-card-oportunidad/embudo-card-oportunidad.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { NguCarouselModule } from '@ngu/carousel';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { EstadoLabelComponent } from './components/estado-label/estado-label.component';
import { EstadoActivacionLabelComponent } from './components/estado-activacion-label/estado-activacion-label.component';
import { ComentariosCardComponent } from './components/comentarios/comentarios-card/comentarios-card.component';
import { FiltrosTablaComponent } from './components/tabla/filtros-tabla/filtros-tabla.component';
import { AccionDialogComponent } from './components/accion-dialog/accion-dialog.component';
import { FormularioFiltrosTablaComponent } from './components/tabla/formulario-filtros-tabla/formulario-filtros-tabla.component';
import { SubirImagenComponent } from './components/subir-imagen/subir-imagen.component';
import { FormularioComponent } from './components/formulario/formulario.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { VisualizadorTextoComponent } from './components/visualizador-texto/visualizador-texto.component';
import { AccionesDialogComponent } from './components/acciones-dialog/acciones-dialog.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TextareaContadorCaracteresComponent } from './components/textarea-contador-caracteres/textarea-contador-caracteres.component';
import { InputPortapapelesComponent } from './components/input-portapapeles/input-portapapeles.component';
import { SpanInnerHtmlComponent } from './components/span-inner-html/span-inner-html.component';
import {NeoMaterialModule} from '@app/neo/neoMaterial/neoMaterial.module';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { DocumentacionComponent } from './components/documentacion/documentacion.component';
import { SubidaDocumentacionComponent } from './components/subida-documentacion/subida-documentacion.component';
import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { RequisitosComponent } from './componentes/requisitos/requisitos.component';
import { ConceptosComponent } from './componentes/conceptos/conceptos.component';
import { AnalisisFuncionalComponent } from './componentes/analisis-funcional/analisis-funcional.component';
import { EstimacionesComponent } from './componentes/estimaciones/estimaciones.component';
import { FormularioEstimacionesComponent } from './componentes/estimaciones/formulario-estimaciones/formulario-estimaciones.component';
import {NeoSharedModule} from '@appNeo/neoShared/neoShared.module';
import { FicheroItemComponent } from './components/fichero-item/fichero-item.component';
import { DepartamentoPrecioComponent } from './componentes/departamento-precio/departamento-precio.component';
import { DepartamentoSoportePrecioComponent } from './componentes/departamento-soporte-precio/departamento-soporte-precio.component';
import { ListadoTareasComponent } from './componentes/listado-tareas/listado-tareas.component';
import { FormularioFacturacionConfiguracionComponent } from './componentes/form/formulario-facturacion-configuracion/formulario-facturacion-configuracion.component';
import { MantenimientoComponent } from './componentes/mantenimiento/mantenimiento.component';
import { InfraestructuraListadoComponent } from './componentes/lineasInfraestructura/infraestructura-listado/infraestructura-listado.component';
import { HitosFacturacionComponent } from './componentes/hitos-facturacion/hitos-facturacion.component';
import { EventosProyectoItemComponent } from './componentes/eventos-proyecto-item/eventos-proyecto-item.component';
import { ComentariosComponent } from './componentes/comentarios/comentarios.component';



export const MY_MOMENT_FORMATS = {
  // parseInput: 'DD/MM/YYYY HH:mm',
  // fullPickerInput: 'DD/MM/YYYY HH:mm',
  // datePickerInput: '',
  // timePickerInput: 'DD/MM/YYYY HH:mm',
  // monthYearLabel: 'MMM YYYY',
  // dateA11yLabel: 'LL',
  // monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    LogoComponent,
    TopBarComponent,
    NavbarComponent,
    MessageComponent,
    ButtonComponent,
    TableComponent,
    CaptchaComponent,
    DialogAlertComponent,
    ConfirmDialogComponent,
    HeaderComponent,
    DynamicPipe,
    NoElementsComponent,
    ItemComponent,
    DynamicComponentDirective,
    KeypadComponent,
    BreadCrumbsComponent,
    ComentarioComponent,
    ComentariosCardComponent,
    EditorComponent,
    BlockSpinnerComponent,
    ImageAvatarPipe,
    PropuestaCardComponent,
    FormFieldsetComponent,
    SelectNgxSingleSelectionComponent,
    DragDropComponent,
    EmbudoCardOportunidadComponent,
    CarouselComponent,
    ChipsAutocompleteComponent,
    EstadoLabelComponent,
    EstadoActivacionLabelComponent,
    FiltrosTablaComponent,
    BusquedaComponent,
    AccionDialogComponent,
    FormularioFiltrosTablaComponent,
    FormularioComponent,
    SubirImagenComponent,
    PerfilComponent,
    VisualizadorTextoComponent,
    AccionesDialogComponent,
    WizardComponent,
    TextareaContadorCaracteresComponent,
    InputPortapapelesComponent,
    SpanInnerHtmlComponent,
    HasPermissionDirective,
    DocumentacionComponent,
    SubidaDocumentacionComponent,
    InputFilterComponent,
    RequisitosComponent,
    ConceptosComponent,
    AnalisisFuncionalComponent,
    EstimacionesComponent,
    FormularioEstimacionesComponent,
    FicheroItemComponent,
    DepartamentoPrecioComponent,
    DepartamentoSoportePrecioComponent,
    ListadoTareasComponent,
    FormularioFacturacionConfiguracionComponent,
    MantenimientoComponent,
    InfraestructuraListadoComponent,
    HitosFacturacionComponent,
    EventosProyectoItemComponent,
    ComentariosComponent
  ],
  imports: [
    NeoSharedModule,
    CommonModule,
    NeoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ClickOutsideModule,
    DragDropModule,
    DragulaModule,
    NguCarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CKEditorModule,
    CdkStepperModule, // sustituye a MatStepperModule, version mas primitiva que MatStepperModule
  ],
  providers: [
    DragulaService,
    // {provide: OWL_DATE_TIME_LOCALE, useValue: 'es'},
    // {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
  ],
  exports: [
    CommonModule,
    LogoComponent,
    MessageComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    NavbarComponent,
    TableComponent,
    CaptchaComponent,
    DialogAlertComponent,
    HeaderComponent,
    DynamicPipe,
    ConfirmDialogComponent,
    NoElementsComponent,
    ItemComponent,
    TopBarComponent,
    DynamicComponentDirective,
    KeypadComponent,
    BreadCrumbsComponent,
    ComentarioComponent,
    EditorComponent,
    BlockSpinnerComponent,
    ClickOutsideModule,
    DragDropModule,
    ImageAvatarPipe,
    PropuestaCardComponent,
    FormFieldsetComponent,
    SelectNgxSingleSelectionComponent,
    DragDropComponent,
    DragulaModule,
    EmbudoCardOportunidadComponent,
    CarouselComponent,
    NguCarouselModule,
    ChipsAutocompleteComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    EstadoLabelComponent,
    EstadoActivacionLabelComponent,
    ComentariosCardComponent,
    FiltrosTablaComponent,
    BusquedaComponent,
    AccionDialogComponent,
    FormularioFiltrosTablaComponent,
    FormularioComponent,
    SubirImagenComponent,
    PerfilComponent,
    DynamicComponentDirective,
    CKEditorModule,
    VisualizadorTextoComponent,
    AccionesDialogComponent,
    WizardComponent,
    CdkStepperModule,
    TextareaContadorCaracteresComponent,
    InputPortapapelesComponent,
    SpanInnerHtmlComponent,
    NeoMaterialModule,
    HasPermissionDirective,
    DocumentacionComponent,
    SubidaDocumentacionComponent,
    InputFilterComponent,
    RequisitosComponent,
    ConceptosComponent,
    AnalisisFuncionalComponent,
    EstimacionesComponent,
    FormularioEstimacionesComponent,
    FicheroItemComponent,
    DepartamentoPrecioComponent,
    DepartamentoSoportePrecioComponent,
    ListadoTareasComponent,
    FormularioFacturacionConfiguracionComponent,
    MantenimientoComponent,
    InfraestructuraListadoComponent,
    HitosFacturacionComponent,
    EventosProyectoItemComponent,
    ComentariosComponent
  ],
  entryComponents: [
    NoElementsComponent,
    MessageComponent,
  ]
})
export class SharedModule { }
