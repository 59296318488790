<div class="form-field field-filtro">
  <mat-form-field appearance="outline">
    <mat-label *ngIf="label">{{ label }} </mat-label>
    <input matInput [type]="tipo"
           [formControl]="controlCampo"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           (blur)="blurInput()"
    >
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option[propiedadObjetoVisualizar]">
        {{ option[propiedadObjetoVisualizar] }}
      </mat-option>
    </mat-autocomplete>
<!--    <mat-icon   *ngIf="!controlCampo.valid && controlCampo.touched; else iconoInput" color="warn" matSuffix>-->
<!--      <neo-icono-form-->
<!--        [matsuffix]=true-->
<!--        [iconoClase]="'mat-icon icon-20 icon-ion&#45;&#45;information-circle'"-->
<!--      ></neo-icono-form>-->
<!--    </mat-icon>-->
<!--    <ng-template #iconoInput>-->
<!--      <mat-icon matSuffix *ngIf="insertarItemPerdidaFoco">-->
<!--        <neo-icono-form-->
<!--          [matsuffix]=true-->
<!--          [iconoClase]="'mat-icon icon-20 icon-ibm&#45;&#45;add'"-->
<!--        ></neo-icono-form>-->
<!--      </mat-icon>-->
<!--    </ng-template>-->
    <mat-hint *ngIf="textoAyuda">
      <div *ngIf="textoAyuda">
        {{textoAyuda}}
      </div>
    </mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors">
      <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]="controlCampo"
        [customError] = "true">
        <div *ngIf="controlCampo.errors[itemNoExiste]">
          {{mensajeErrorSinSeleccion}}
        </div>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>

  <neo-button class="btn-nuevo-item"
              [flat]="true"
              [color]="'primary'"
              [iconClass]="'icon-16 icon-ibm--add'"
              [clase]="'btn-cuadrado'"
              [disabled]="disabledBtnNuevoItem || !controlCampo.value"
              (click)="!disabledBtnNuevoItem && controlCampo.value && nuevoItem(); this.reiniciarValidador.emit(itemNoExiste);"
  ></neo-button>
</div>

