
<div class="captcha-block">
  <mat-form-field  #matFormField appearance="outline">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <div class="captcha-cont">
      <input  type="text" class="form-control auth-control ng-untouched ng-pristine ng-valid" matInput placeholder="" [formControl]="controlCampo" >
      <div class="captcha">
        <neo-button (click)="generateCaptcha()"
          [basic]="true"
          [iconClass]="'icon-20 icon-ibm--restart'"
        ></neo-button>
        <span class="captcha-text not-selectable">{{ captcha }}</span>
      </div>
    </div>
    <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
          [nombreCampo]="label"
          [controlCampo]="controlCampo"
          [customError]="true">
        <ng-container *ngIf="controlCampo.errors?.captcha">  <div data-testid="matErrorCaptcha">Introduce el {{label | lowercase}} correctamente.</div>  </ng-container>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>

