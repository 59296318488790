import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class FormularioFiltrosTablaService {
  public inputsForm$: BehaviorSubject<[]> = new BehaviorSubject<[]>(null) ;

  constructor() {}

  set inputsForm(inputs) {
    this.inputsForm$.next(inputs);
  }
}
