<section>
  <div *ngIf="mostrarTituloCabecera" class="section-header">
    <div>
      <h2 class="text-h2">{{ titulo }}</h2>
    </div>

    <ng-container *ngIf="btnDescargarPdfCabecera">
      <div>
        <neo-button
          [icono]="true"
          [color]="'primary'"
          [clase]="'btn-outline btn-margin-left'"
          [iconClass]="'icon-20 icon-ibm--document--pdf'"
          (click)="descargarPdf()"
        ></neo-button>
      </div>
    </ng-container>
  </div>

  <!-- filtros entidad-->
  <div *ngIf="filtrado" class="tabla-filtros blq-card" [ngClass]="{'contenedorFiltrosOcultarM': numeroFiltros>1, 'contenedorFiltrosOcultarTV': numeroFiltros>2}">
    <neo-filtros-tabla #filtrosTabla
                       [keyFiltrado]="keyFiltrado"
                       [valoresIniciales]="valoresInicialesFiltrado"
                       (cambioFormulario)="eventoCambioFormularioFiltros($event)"
    >

      <div class="campo-busqueda">
        <neo-busqueda #filtrosBusqueda
                      placeholder="Busqueda"
                      [valorDefault]="true"
                      [botonLimpiarBuscador]="true"
                      (limpiarBuscador)="clearBuscador()"
                      (changeBuscador)="eventoCambioBuscador($event)"
                      class="mr-4 ml-4"
        ></neo-busqueda>
      </div>

      <div class="btns-filtrado" *ngIf="!filtradoSinBotonSubmit && numeroFiltros>1">
        <neo-button
          [disabled]="deshabilitarLimpiarFiltros"
          [text]="'Limpiar filtros'"
          [basic]="true"
          [color]="'primary'"
          [iconClass]="'icon-20 icon-ibm--clean'"
          (click)="!deshabilitarLimpiarFiltros ? clearFilter(): null;"
        ></neo-button>
        <neo-button
          [text]="'Aplicar filtros'"
          [color]="'primary'"
          [flat]="true"
          (click)="submitFiltrado()"
        ></neo-button>
      </div>
    </neo-filtros-tabla>
  </div>

  <div class="blq-cont" *ngIf="filtrado">
    <!-- boton acceso filtros en caso de estar ocultos por temas responsivos -->
    <neo-button class="botonAccesoFiltros btn-block" [ngClass]="{'botonAccesoFiltrosOcultarM': numeroFiltros==1, 'botonAccesoFiltrosOcultarTV': numeroFiltros==2 || numeroFiltros==1}"
    [text]="'Filtrar'"
    [flat]="true"
    [color]="'secondary'"
    [iconClass]="'icon-20 icon-ibm--filter'"
    (click)="abrirFiltros()"
    ><span *ngIf="cambioValoresFiltrado" class="filtro-activo"></span>
    </neo-button>
  </div>

  <div class="mt-15">
    <div id="blq-tabla" class="blq-card">
      <div class="blq-header" *ngIf="mostrarAccionesCabecera">
        <h3 class="text-p6">
          <span *ngIf="rowsSeleccionadas > 1">{{rowsSeleccionadas}} elementos seleccionados</span>
          <span *ngIf="rowsSeleccionadas === 1">{{rowsSeleccionadas}} elemento seleccionado</span>
          <span *ngIf="rowsSeleccionadas <= 0">{{ totalResultados }} elementos</span>
        </h3>
        <div *ngIf="btnAccionesLote || botoneraAccionesCabecera" class="blq-acciones">

          <div *ngIf="btnAccionesLote" class="mr-2">
            <neo-boton-desplegable #btnDepegableAccioneMultiples
                                   [referenciaElemento]="'tabla'"
                                   [disabled]="selection.selected.length < 1"
                                   [iconoClaseBotonPrincipal]="'icon-20 icon-mdi--arrow_drop_down'"
                                   [textoBotonPrincipal]="'Acciones múltiples'"
                                   [strokedBotonPrincipal]=true
                                   [claseBotonPrincipal]="'btn-sm'"
            ></neo-boton-desplegable>
          </div>

          <ng-container *ngIf="botoneraAccionesCabecera">
            <div [ngClass]="accionCabecera.bloqueClass" *ngFor="let accionCabecera of botoneraAccionesCabecera">
              <neo-button  *ngIf="accionCabecera.mostrarBtnLg"
                           [ngClass]="accionCabecera.neoButtonClassLg"
                           [flat]="true"
                           [color]="accionCabecera.color"
                           (click)="accionCabeceraClick.emit(accionCabecera)"
                           [iconClass]="accionCabecera.iconClassLg"
                           [text]="accionCabecera.textoLg"
                           [clase]="accionCabecera.neoButtonInputClassLg"
              ></neo-button>
              <neo-button *ngIf="accionCabecera.mostrarBtnXs"
                          [ngClass]="accionCabecera.neoButtonClassXs"
                          [flat]="true"
                          [color]="accionCabecera.color"
                          (click)="accionCabeceraClick.emit(accionCabecera)"
                          [iconClass]="accionCabecera.iconClassXs"
                          [text]="accionCabecera.textoXs"
                          [clase]="accionCabecera.neoButtonInputClassXs"
              ></neo-button>
            </div>
          </ng-container>
        </div>
      </div>

        <div class="table-container" #rowTest *ngIf="!tablaOnline">
          <ng-container *ngTemplateOutlet="tablaDraggableOffline"></ng-container>
        </div>

        <div class="table-container" *ngIf="tablaOnline">
          <ng-container *ngTemplateOutlet="tablaDraggableOnline"></ng-container>
        </div>
    </div>

    <ng-template #tablaDraggableOnline>
            <cdk-virtual-scroll-viewport [tvsItemSize]="ROW_HEIGHT"   class="virtual-scroll wrapper mat-elevation-z2" #virtualScroller>
              <table #tablaDraggable multiTemplateDataRows matSort mat-table [dataSource]="dataSource"  cdkDropList [cdkDropListData]="dataSource"  (cdkDropListDropped)="eventoDrag($event)" *ngIf="totalResultados; else tableEmpty"  (matSortChange)="ordenarDataSourceByColumna($event)">
                <ng-container *ngFor="let columna of columnas" matColumnDef="{{columna.key}}">
                  <ng-container [ngSwitch]="columna.tipo">
                    <ng-container *ngIf="columna.tipo === 'selector'">
                      <th #columnaSelect mat-header-cell *matHeaderCellDef [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-40 cell-center'">
                        <mat-checkbox #toogleCheckbox id="checkbox-master"
                                      [ngClass]="'tbl-checkbox'"
                                      [color]="'primary'"
                                      (click)="toogleMasterClick($event)"
                                      [checked]="toogleMasterChecked()"
                                      [indeterminate]="toogleMasterIndeterminate()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row; index as i;" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-40 cell-center'" [attr.key]="columna.key">
                        <mat-checkbox #childCheckbox (click)="$event.stopPropagation()"  [ngClass]="'tbl-checkbox'"
                                      [color]="'primary'"
                                      (change)="$event ? selection.toggle(row) : null"
                                      (change) = "seleccionarRow(row)"
                                      [checked]="row['seleccionado']">
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Imagen">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell  *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'" [attr.key]="columna.key">
                        <img [src]="row[columna.key]?.src ? row[columna.key]?.src : imgDefault"  alt="{{row[columna.key]?.alt}}" />
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Avatar">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'"
                        >
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell  *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'" [attr.key]="columna.key">
                        <div class="avatar-txt">
                          <ngx-avatar name="{{row[columna.key]?.nombre}}"  initialsSize="1"  src="{{row[columna.key]?.src}}" size="40" class="ngx-mi-avatar"></ngx-avatar>
                          <span>{{row[columna.key]?.nombre}}</span>
                        </div>
                    </ng-container>


                    <!--            TODO: Revisar-->
                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Icono">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell *matCellDef="let row; index as i;" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'" [attr.key]="columna.key">
                        <neo-icono [color]="row[columna.key]?.color"  [iconoClase]="row[columna.key]?.class"></neo-icono>
                      </td>
                    </ng-container>


                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Estado">
                      <ng-container *ngIf="columna.ordenacion">
                        <th  mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                             [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th  mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                             [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell  *matCellDef="let row" class="" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                        <neo-estado-label [estado]="row[columna.key]"></neo-estado-label>
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Boolean">
                      <ng-container *ngIf="columna.ordenacion">
                        <th  mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                             [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell *matCellDef="let row" class="" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'" [attr.key]="columna.key">
                        <neo-campo-boolean-palanca-model
                          [valor]="row[columna.key]"
                          (nuevoValor)="row[columna.key] = $event; this.actualizarDataSource();"
                          [color]="'primary'"
                        ></neo-campo-boolean-palanca-model>
                      </td>

                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Texto">
                      <ng-container *ngIf="columna.ordenacion">
                        <th  mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                             [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell  *matCellDef="let row" class="limite-lineas campo-texto" [attr.key]="columna.key"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                        <span>{{ row[columna.key] }}</span>
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlacePrincipal" class="">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-320 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-320 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell *matCellDef="let row" class="limite-lineas campo-enlace" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-320 cell-left'">
                        <a class="link linkPrincipal" (click)="$event.stopPropagation()" (click)="clickLink(row[columna.key + 'Mapping']?.page, row[columna.key + 'Mapping']?.id)">
                          {{ row[columna.key] }} {{columna.unidad}}
                        </a>
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlaceSecundario" class="">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell  *matCellDef="let row" class="limite-lineas campo-enlace"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'" [attr.key]="columna.key">
                        <a class="link linkSecundario" (click)="$event.stopPropagation()" (click)="clickLink(row[columna.key + 'Mapping']?.page, row[columna.key + 'Mapping']?.id)">
                          {{ row[columna.key] }} {{columna.unidad}}
                        </a>
                      </td>

                    </ng-container>


                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Importe">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <td mat-cell *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'" [attr.key]="columna.key">
                        {{ row[columna.key] }} {{columna.unidad}}
                      </td>
                    </ng-container>


                    <ng-container *ngSwitchCase="[tablaService._TipoColumna.Fecha, tablaService._TipoColumna.FechaHora, tablaService._TipoColumna.Hora].includes(columna.tipo) ? columna.tipo : '' ">
                      <ng-container *ngIf="columna.ordenacion">
                        <th [style.top.px]="offset" mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                          [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : ' cell-120 cell-left '">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th [style.top.px]="offset" mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                          [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : ' cell-120 cell-left '">
                          {{columna.nombre}}
                        </th>
                      </ng-container>

                      <td mat-cell *matCellDef="let element" class="limite-lineas campo-fecha"
                        [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : ' cell-120 cell-left '"
                        [attr.key]="columna.key">
                        <div *ngIf="!esString(element[columna.key])">
                          <ng-container *ngIf="tablaService._TipoColumna.Fecha == columna.tipo ">
                            {{element[columna.key] | date: 'd/M/y'}}
                          </ng-container>
                          <ng-container *ngIf="tablaService._TipoColumna.FechaHora == columna.tipo ">
                            {{element[columna.key] | date: 'd/M/y, h:mm'}}
                          </ng-container>
                          <ng-container *ngIf="tablaService._TipoColumna.Hora == columna.tipo ">
                            {{element[columna.key] | date: 'h:mm'}}
                          </ng-container>
                        </div>
                        <div *ngIf="esString(element[columna.key])">
                          {{element[columna.key]}}
                        </div>
                      </td>


                  </ng-container>


                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Otro">
                      <ng-container *ngIf="columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                          [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                          <div>{{columna.nombre}}</div>
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"
                            [ngClass]="' cell-160 cell-left' + columna?.clasePersonalizada?.join(' ')">
                          <div>{{columna.nombre}}</div>
                        </th>
                      </ng-container>
                      <td mat-cell *matCellDef="let row" class="campo-otro" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                        <div>{{ row[columna.key] }}</div>
                      </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="tablaService._TipoColumna.Acciones">
                      <th  mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-center'">
                        <span [ngClass]="{'botonera-acciones': columna.tipo == tablaService._TipoColumna.Acciones}"> {{columna.nombre}}</span>
                      </th>
                      <td mat-cell *matCellDef="let row" class="" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-center'" [attr.key]="columna.key">
                        <div class="botonera-acciones">
                          <!--  boton fijo para mostrar columnas ocultas por responsivo-->
                          <neo-button *ngIf="columnasOcultas?.length && mostrarColumnasOcultasEnDialogo"  [disabled]="deshabilitarAcciones"
                                      class="btn-accion"
                                      [icono]="true"
                                      [iconClass]="'icon-20 icon-ibm--information'"
                                      matTooltip="Ver información"
                                      (click)="$event.stopPropagation()" (click)="verInformacionRowClick(row)">
                          </neo-button>

                          <!--  botonera configurable-->
                          <neo-button *ngFor="let accion of row[columna.key]" [disabled]="deshabilitarAccionRow(accion)"
                                      class="btn-accion"
                                      [icono]="accion.icono"
                                      [iconClass]="accion.iconClass"
                                      matTooltip="{{accion.tooltip}}"
                                      (click)="$event.stopPropagation()" (click)="!deshabilitarAccionRow(accion) && clickAccionRowTabla.emit([row, accion])">
                          </neo-button>
                        </div>
                        <div class="botonera-acciones-desplegable">
                          <neo-boton-desplegable
                            [referenciaElemento]="'tabla'"
                            [iconoBotonPrincipal]="'more_vert'"
                            [colorBotonPrincipal]="'black'"
                            [iconoTipoBotonPrincipal]=true
                            [textoBotonPrincipal]="''"
                            [item]="row"
                            [disabled]="deshabilitarAcciones"
                            [accionesBotonDesplegable]="obtenerAccionesBotonDesplegable(row)"
                            (click)="rowSeleccionadaBotonDesplegable = row; $event.stopPropagation()"
                          ></neo-boton-desplegable>
                        </div>
                      </td>

                      <!-- <td mat-cell *matCellDef="let row" class="" [attr.key]="columna.key">

                      </td> -->
                    </ng-container>

                    <ng-container *ngIf="columna.tipo === columnaDespligueMasInfoKey">

                      <th  mat-header-cell [style.top.px]="offset" *matHeaderCellDef [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                        <!-- columna vacia -->
                      </th>
                      <td mat-cell *matCellDef="let row;" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'" [attr.key]="columna.key">
                        <neo-icono-despliegue [identificador]="'fila_' + dataSource.filteredData.indexOf(row) +'_'"></neo-icono-despliegue>
                      </td>

                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <!--TODO: Codigo duplicado. Se puede hacer depender la directiva mat-sort-top de withSorting en default y para cada columna tipada?-->
                      <ng-container *ngIf="columna.ordenacion">
                        <th [style.top.px]="offset"  mat-header-cell mat-sort-header  *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.classResponsive + ' ' + columna?.clasePersonalizada?.join(' ')">
                          {{columna.nombre}}
                        </th>
                      </ng-container>
                      <ng-container *ngIf="!columna.ordenacion">
                        <th [style.top.px]="offset" mat-header-cell *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.classResponsive + '  ' + columna?.clasePersonalizada?.join(' ')">
                          {{columna.nombre}}
                        </th>
                      </ng-container>

                      <td mat-cell  *matCellDef="let element" class="align-left"  [ngClass]="'  ' + columna?.clasePersonalizada?.join(' ')" [attr.key]="columna.key"> {{element[columna.key]}} {{columna?.unidad}} </td>

                    </ng-container>


                  </ng-container>

                </ng-container>


                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let row;" [attr.colspan]="columnas.length">
                    <div class="contenedor-mas-info" [@detailExpand]="row == filaExpandida ? 'expanded' : 'collapsed'">
                      <neo-contenedor-masinfo [propiedades]="propiedadesMasInfo"></neo-contenedor-masinfo>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="keysColumnas; sticky: true"></tr>
                <tr mat-row dataActual="{{row | json}}" *matRowDef="let row; columns: keysColumnas;let i = index;"
                    (click)="clickRow(row, dataSource.filteredData.indexOf(row))"
                    #tableRow [ngClass]="{'seleccionado' : row['seleccionado']}" cdkDrag>
                </tr>
                <tr #row mat-row *matRowDef="let row; columns: ['expandedDetail']" class="contenedor-mas-info-fila" [ngClass]="{'expandida' : row == filaExpandida, 'colapsada': row == null || row != filaExpandida}"></tr>
                <!-- <tr mat-header-row *matHeaderRowDef="keysColumnas"></tr>
                <tr mat-row  *matRowDef="let row; columns: keysColumnas;let i = index;" (click)="clickRow(row, i)" class="filaTabla"  #tableRow></tr> -->
              </table>

            </cdk-virtual-scroll-viewport>
    </ng-template>


    <ng-template #tablaDraggableOffline>
      <table #tablaDraggable multiTemplateDataRows matSort mat-table [dataSource]="dataSource.filteredData"  cdkDropList [cdkDropListData]="dataSource.filteredData"  (cdkDropListDropped)="eventoDrag($event)" *ngIf="totalResultados; else tableEmpty"  (matSortChange)="ordenarDataSourceByColumna($event)">
        <ng-container *ngFor="let columna of columnas" matColumnDef="{{columna.key}}">
          <ng-container [ngSwitch]="columna.tipo">

            <ng-container *ngIf="columna.tipo === 'selector'">
              <th #columnaSelect mat-header-cell *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-40 cell-center'">
                <mat-checkbox #toogleCheckbox id="checkbox-master"
                              [ngClass]="'tbl-checkbox'"
                              [color]="'primary'"
                              (click)="toogleMasterClick($event)"
                              [checked]="toogleMasterChecked()"
                              [indeterminate]="toogleMasterIndeterminate()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row; index as i;" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-40 cell-center'">
                <mat-checkbox #childCheckbox (click)="$event.stopPropagation()"  [ngClass]="'tbl-checkbox'"
                              (change)="$event ? selection.toggle(row) : null"
                              [color]="'primary'"
                              (change) = "seleccionarRow(row)"
                              [checked]="row['seleccionado']">
                </mat-checkbox>
              </td>
            </ng-container>


            <ng-container *ngSwitchCase="tablaService._TipoColumna.Imagen">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'" [attr.key]="columna.key">
                <img [src]="row[columna.key]?.src ? row[columna.key]?.src : imgDefault"  alt="{{row[columna.key]?.alt}}" />
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="tablaService._TipoColumna.Avatar">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-220 cell-left'" [attr.key]="columna.key">
                <div class="avatar-txt">
                  <ngx-avatar name="{{row[columna.key]?.nombre}}"  initialsSize="1"  src="{{row[columna.key]?.src}}" size="40" class="ngx-mi-avatar"></ngx-avatar>
                  <span>{{row[columna.key]?.nombre}}</span>
                </div>
            </ng-container>


            <!--            TODO: Revisar-->
            <ng-container *ngSwitchCase="tablaService._TipoColumna.Icono">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row; index as i;" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-40 cell-center'" [attr.key]="columna.key">
                <neo-icono [iconoClase]="row[columna.key]?.class"></neo-icono>
              </td>
            </ng-container>


            <ng-container *ngSwitchCase="tablaService._TipoColumna.Estado">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" class="" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                <neo-estado-label [estado]="row[columna.key]"></neo-estado-label>
              </td>
            </ng-container>


            <ng-container *ngSwitchCase="tablaService._TipoColumna.Boolean">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell *matCellDef="let row" class="" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-80 cell-center'" [attr.key]="columna.key">
                <neo-campo-boolean-palanca-model
                  [valor]="row[columna.key]"
                  (nuevoValor)="row[columna.key] = $event; this.actualizarDataSource();"
                  [color]="'primary'"
                ></neo-campo-boolean-palanca-model>
              </td>

            </ng-container>

            <ng-container *ngSwitchCase="tablaService._TipoColumna.Texto">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" class="limite-lineas campo-texto" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                <span>{{ row[columna.key] }}</span>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlacePrincipal" class="">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="' cell-320 cell-left' + columna?.clasePersonalizada?.join(' ')">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="' cell-320 cell-left' + columna?.clasePersonalizada?.join(' ')">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" class="limite-lineas campo-enlace" [attr.key]="columna.key" [[ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-320 cell-left'">
                <a class="link linkPrincipal" (click)="$event.stopPropagation()" (click)="clickLink(row[columna.key + 'Mapping']?.page, row[columna.key + 'Mapping']?.id)">
                  {{ row[columna.key] }} {{columna.unidad}}
                </a>
              </td>
            </ng-container>


            <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlaceSecundario" class="">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" class="limite-lineas campo-enlace"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'" [attr.key]="columna.key">
                <a class="link linkSecundario" (click)="$event.stopPropagation()" (click)="clickLink(row[columna.key + 'Mapping']?.page, row[columna.key + 'Mapping']?.id)">
                  {{ row[columna.key] }} {{columna.unidad}}
                </a>
              </td>

            </ng-container>


            <ng-container *ngSwitchCase="tablaService._TipoColumna.Importe">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <td mat-cell   *matCellDef="let row"   [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-right'" [attr.key]="columna.key">
                {{ row[columna.key] }} {{columna.unidad}}
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="tablaService._TipoColumna.Otro">
              <ng-container *ngIf="columna.ordenacion">
                <th  mat-header-cell  mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  <div>{{columna.nombre}}</div>
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"
                     [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                  <div>{{columna.nombre}}</div>
                </th>
              </ng-container>
              <td mat-cell  *matCellDef="let row" class="campo-otro" [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-160 cell-left'">
                <div>{{ row[columna.key] }}</div>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="tablaService._TipoColumna.Acciones">
              <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-center'">
                <span [ngClass]="{'botonera-acciones': columna.tipo == tablaService._TipoColumna.Acciones}"> {{columna.nombre}}</span>
              </th>
              <td mat-cell *matCellDef="let row" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-140 cell-center'">
                <div class="botonera-acciones">
                  <!--  boton fijo para mostrar columnas ocultas por responsivo-->
                  <neo-button *ngIf="columnasOcultas?.length && mostrarColumnasOcultasEnDialogo"  [disabled]="deshabilitarAcciones"
                              class="btn-accion"
                              [icono]="true"
                              [iconClass]="'icon-20 icon-ibm--information'"
                              matTooltip="Ver información"
                              (click)="$event.stopPropagation()" (click)="verInformacionRowClick(row)">
                  </neo-button>

                  <!--  botonera configurable-->
                  <neo-button *ngFor="let accion of row[columna.key]" [disabled]="deshabilitarAccionRow(accion)"
                              class="btn-accion"
                              [icono]="accion.icono"
                              [iconClass]="accion.iconClass"
                              matTooltip="{{accion.tooltip}}"
                              (click)="$event.stopPropagation()" (click)="!deshabilitarAccionRow(accion) && clickAccionRowTabla.emit([row, accion])">
                  </neo-button>
                </div>
                <div class="botonera-acciones-desplegable">
                  <neo-boton-desplegable
                    [referenciaElemento]="'tabla'"
                    [iconoBotonPrincipal]="'more_vert'"
                    [colorBotonPrincipal]="'black'"
                    [iconoTipoBotonPrincipal]=true
                    [textoBotonPrincipal]="''"
                    [item]="row"
                    [disabled]="deshabilitarAcciones"
                    [accionesBotonDesplegable]="obtenerAccionesBotonDesplegable(row)"
                    (click)="$event.stopPropagation()"
                  ></neo-boton-desplegable>
                </div>
              </td>

              <!-- <td mat-cell *matCellDef="let row" class="" [attr.key]="columna.key">

              </td> -->
            </ng-container>

            <ng-container *ngIf="columna.tipo === columnaDespligueMasInfoKey">

              <th  mat-header-cell  *matHeaderCellDef [attr.key]="columna.key" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'">
                <!-- columna vacia -->
              </th>
              <td mat-cell *matCellDef="let row;" [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-60 cell-center'" [attr.key]="columna.key">
                <neo-icono-despliegue [identificador]="'fila_' + dataSource.filteredData.indexOf(row) +'_'"></neo-icono-despliegue>
              </td>

            </ng-container>

            <ng-container *ngSwitchDefault>
              <!--TODO: Codigo duplicado. Se puede hacer depender la directiva mat-sort-top de withSorting en default y para cada columna tipada?-->
              <ng-container *ngIf="columna.ordenacion">
                <th   mat-header-cell mat-sort-header  *matHeaderCellDef [attr.key]="columna.key"  [ngClass]="columna?.classResponsive + ' ' + columna?.clasePersonalizada?.join(' ')">
                  {{columna.nombre}}
                </th>
              </ng-container>
              <ng-container *ngIf="!columna.ordenacion">
                <th  mat-header-cell *matHeaderCellDef [attr.key]="columna.key" [ngClass]="columna?.classResponsive + '  ' + columna?.clasePersonalizada?.join(' ')">
                  {{columna.nombre}}
                </th>
              </ng-container>

              <td mat-cell  *matCellDef="let element" class="align-left"  [ngClass]="'  ' + columna?.clasePersonalizada?.join(' ')" [attr.key]="columna.key"> {{element[columna.key]}} {{columna?.unidad}} </td>

            </ng-container>


          </ng-container>

        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row;" [attr.colspan]="columnas.length">
            <div class="contenedor-mas-info" [@detailExpand]="row == filaExpandida ? 'expanded' : 'collapsed'">
              <neo-contenedor-masinfo [propiedades]="propiedadesMasInfo"></neo-contenedor-masinfo>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="keysColumnas; sticky: true"></tr>
        <tr dataActual="{{row | json}}"  mat-row  *matRowDef="let row; columns: keysColumnas;let i = index;"
            (click)="clickRow(row, dataSource.filteredData.indexOf(row))"
            #tableRow [ngClass]="{'seleccionado' : row['seleccionado']}" cdkDrag>
        </tr>
        <tr #row mat-row *matRowDef="let row; columns: ['expandedDetail']" class="contenedor-mas-info-fila" [ngClass]="{'expandida' : row == filaExpandida, 'colapsada': row == null || row != filaExpandida}"></tr>
        <!-- <tr mat-header-row *matHeaderRowDef="keysColumnas"></tr>
        <tr mat-row  *matRowDef="let row; columns: keysColumnas;let i = index;" (click)="clickRow(row, i)" class="filaTabla"  #tableRow></tr> -->
      </table>

    </ng-template>

    <ng-template #tableEmpty>
      <neo-sin-elementos></neo-sin-elementos>
    </ng-template>
  </div>
</section>


<!-- <h4>Columnas ocultas a mostrar en Ver info</h4>
<pre>
  {{ columnasOcultas | json}}
</pre> -->

<ng-template #dialogoMasInfo>
  <neo-acciones-dialog #accionDialogMasInfo
                       [dialogTittle]="'Información'"
                       [botonera]="[]">
    <neo-perfil #perfilMasInfo
                [propiedades]="propiedadesMasInfo">
    </neo-perfil>
  </neo-acciones-dialog>
</ng-template>


<!-- filtros responsivos ocultos -->
<ng-template #dialogFiltrosResponsivos>
  <neo-acciones-dialog
    [dialogTittle]="'Filtros'"
    [botonCerrarDialogoCabecera]=true
    [botonera]="botoneraDialogFiltrosResponsivos"
    (accionBoton)="submitAccionDialogFiltrosResponsivos($event)"
  >
    <neo-busqueda #filtrosBusqueda
                  placeholder="Busqueda"
                  [botonLimpiarBuscador]="true"
    ></neo-busqueda>
    <neo-filtros-tabla #filtrosTabla
                       [keyFiltrado]="keyFiltrado"
                       [valoresIniciales]="valoresInicialesFiltrado"
                       (cambioFormulario)="determinarCambioValoresFiltradoIniciales($event)"
    ></neo-filtros-tabla>
  </neo-acciones-dialog>
</ng-template>
