import { Component, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {IFichero, IFicheroExtendido} from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { FORM_INPUT_EDICION, PROPIEDADES_MAS_INFO_FICHERO } from '@appNeo/neoShared/components/fichero/fichero-data';
import { FicheroComponent } from '@appNeo/neoShared/components/fichero/fichero.component';
import { AliasFicherosEnum, AliasPluralFicherosEnum } from '@appNeo/neoShared/helpers/enums/AliasFIcheros.enum';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioComponent } from '../formulario/formulario.component';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import {AccionesTablaEnum, IAccionRowTabla} from '@appNeo/neoShared/services/tabla/tabla.service';
import { FicheroSubidaComponent } from '../fichero-subida/fichero-subida.component';

@Component({
  selector: 'neo-fichero-card',
  templateUrl: './fichero-card.component.html',
  styleUrls: ['./fichero-card.component.scss'],
  providers:[FormularioService]
})
export class FicheroCardComponent implements OnInit {
  // configuracion servicio interno
  @Input('idObjeto') idObjeto: string = '1';
  @Input('camposExtra') camposExtra: object;
  @Input('objetoRelacionado') objetoRelacionado;
  @Input('urlArchivos') urlArchivos;
  @Input('formInputEdicion') formInputEdicion: IFormInput[] = FORM_INPUT_EDICION;
  @Input('item') item: IFicheroExtendido;
  // padre resuelve el despliegue de masinfor
  @Input() externalizarVerInformacion:  boolean = true;
  @ViewChild('dialogoMasInfo') dialogoMasInfo: TemplateRef<any>;
  @ViewChild('appFicheroSubidaEdicion') appFicheroSubidaEdicion: FicheroSubidaComponent;

  @Input() externalizarProcesoEliminar:  boolean = true;
  // 1: emite peticion apertura dialogo y proceso desencadenado [peticionEdicion/peticionEliminacion]
  @Output() peticionEliminar = new EventEmitter ();
  // 0: gestion apertura dialogo y emite proceso edicion/eliminacion desencadenado [editar/eliminar]
  @ViewChild('dialogConfirmarEliminarArchivos') dialogConfirmarEliminarArchivos: TemplateRef<any>;
  @Output() eliminar = new EventEmitter ();


  @Input() externalizarProcesoEditar:  boolean = true;
  // 1: emite peticion apertura dialogo y proceso desencadenado [peticionEdicion/peticionEliminacion]
  @Output() peticionEditar = new EventEmitter ();
  // 0: gestion apertura dialogo y emite proceso edicion/eliminacion desencadenado [editar/eliminar]
  @ViewChild('dialogEditarArchivo') dialogEditarArchivo: TemplateRef<any>;
  @Input('formatosPermitidos') formatosPermitidos = environment.fichero_formatos_permitidos;
  @Input('tamanoMaximoFichero') tamanoMaximoFichero = environment.fichero_tamano_maximo;
  @ViewChild('formularioEdicion') formularioEdicion: FormularioComponent;
  @Output() editar = new EventEmitter <IFicheroExtendido>();
  // eventos procedentes de dialogo fichero subida
  @Output() preSubirArchivos = new EventEmitter ();
  @Output() submitFicheroSubida = new EventEmitter ();
  @Output() borrarFichero = new EventEmitter <IFicheroExtendido>();
  @Output() clickAccionRow = new EventEmitter();

  // elemento seleccionable en conjunto con otro, checkbox
  @Input('itemSeleccionable') itemSeleccionable: boolean = true;
  // elemento movibl.e en conjunto con otro, dragdrop
  @Input('itemMovible') itemMovible: boolean = true;










  // desactivada emision si activo [externalizarVerInformacion]
  @Output() verInformacionItem = new EventEmitter ();
  // no se llega hacer emision si no activo [itemSeleccionable]
  @Output() itemSeleccionado = new EventEmitter<IFichero>();

  url: string = environment.base_url;
  propiedadesMasInfoFichero = PROPIEDADES_MAS_INFO_FICHERO;


  constructor(
    private dialog: MatDialog,
    public auxiliarService: AuxiliarService,
    @Optional() public padre: FicheroComponent
  ) { }

  ngOnInit(): void {
    // this.propiedadesMasInfoFichero.map( propiedad => propiedad.valor = this.item[propiedad.identificador]);
  }

  seleccionarItem(event, item: IFicheroExtendido){
    item.seleccionado = event.checked;
    this.itemSeleccionado.emit(item);
  }

  determinarAccionCard(accion: IAccionRowTabla) {
    switch (accion.idAccion) {
      case AccionesTablaEnum.Editar:
        this.emitirEditarItem();
        break;
      case AccionesTablaEnum.Eliminar:
        this.emitirBorrarItem();
        break;
      default:
        this.clickAccionRow.emit([this.item, accion]);
        break;
    }
  }

  emitirEditarItem(){
    console.log(`click btn editar fichero-card Externalizacion [${this.externalizarProcesoEditar}]`);
    if (this.externalizarProcesoEditar) {
      this.peticionEditar.emit(this.item);
    } else {
      this.abrirDialogo(DialogActionEnum.Update);
    }
  }

  emitirBorrarItem(){
    console.log(`click btn eliminar fichero-card Externalizacion [${this.externalizarProcesoEliminar}]`);
    if (this.externalizarProcesoEliminar) {
      this.peticionEliminar.emit(this.item);
    } else {
      this.abrirDialogo(DialogActionEnum.Delete);
    }
  }

  emitirVerInformacion() {
    this.propiedadesMasInfoFichero.map( propiedad => propiedad.valor = this.item[propiedad.identificador]);
    if (this.externalizarVerInformacion) {
      this.verInformacionItem.emit(this.propiedadesMasInfoFichero);
    } else {
      this.abrirDialogo(DialogActionEnum.Ver);
    }
  }

  getRuta(ruta){
    return this.url + ruta;
  }

  get aliasFicherosEnum(){
    if (this.padre) {
      return this.padre.getAliasFicherosEnum();
    } else {
      return AliasFicherosEnum;
    }
  }

  get tipoFicheroPlural() {
    if (this.padre) {
      return this.padre.tipoFicheroPlural;
    } else {
      return AliasPluralFicherosEnum.generico;
    }

  }


  get tipoFichero() {
    if (this.padre) {
      return this.padre.tipoFichero;
    } else {
      return AliasFicherosEnum.generico;
    }

  }

  abrirDialogo(dialog: DialogActionEnum) {
    console.log(`Apertura dialogo internamente por desactivado externalizacion`, dialog);
    switch (dialog) {
      case DialogActionEnum.Ver:
        this.dialog.open(this.dialogoMasInfo);
        break;
      case DialogActionEnum.Delete:
        this.dialog.open(this.dialogConfirmarEliminarArchivos);
        break;
      case DialogActionEnum.Update:
        this.dialog.open(this.dialogEditarArchivo);
        break;
    }
  }

  submitAccionDialogConfirmarEliminarArchivos(){
    this.dialog.closeAll();
    // let idsItems = [this.item.id];
    this.eliminar.emit(this.item);
  }

  submitAccionDialogEditarArchivo(){
    let idFichero = this.appFicheroSubidaEdicion.fileAdjuntos[0].fichero.id;
    console.log('Id fichero', idFichero);
    this.item.id = idFichero;
    console.log('Item', this.item);
    this.dialog.closeAll();
    // let idsItems = [this.item.id];
    // console.log('idsItems', idsItems);
    this.editar.emit(this.item);
  }

  get _formatosPermitidos() {
    if (this.padre) {
      return this.padre.formatosPermitidos;
    } else {
      return this.formatosPermitidos;
    }
  }

  // calculo
  calcularTamanoMaximoTotal() {
    if (this.padre) {
      return this.padre.calcularTamanoMaximoTotal();
    } else {
      return this.tamanoMaximoFichero;
    }
  }

  // environment | input
  get _tamanoMaximoFichero() {
    if (this.padre) {
      return this.padre.tamanoMaximoFichero;
    } else {
      return this.tamanoMaximoFichero;
    }
  }

  cargarArchivo(event) {
    console.log('presubir fichero');
    this.preSubirArchivos.emit(event);
  }

  guardarArchivo(event) {
    console.log('GUARDAR....', event);
    this.submitFicheroSubida.emit(event);
  }

  eventoBorrarFicheroEnEdicion(evento) {
    this.borrarFichero.emit(evento);
  }

  cambiosFormularioEdicion($event) {
    this.item.tituloSeo = $event.event.tituloSeo
    this.item.textoAlternativoSeo = $event.event.textoAlternativoSeo
  }

}
