<div class="form-field field-contrasena">
    <mat-form-field appearance="outline" class="icono-mat-suffix">
        <mat-label *ngIf="label">{{label}}</mat-label>
        <input
            autocomplete="current-password"
            name="password"
            [type]="hide ? 'password' : 'text'" matInput
            [formControl]="controlCampo"
            [maxlength]="maxCaracteres"
            [minlength]="minCaracteres"
            class="form-control auth-control ng-untouched ng-pristine ng-valid"
        >
        <mat-hint *ngIf="aviso && !criterioValidez">Utilice al menos entre {{minCaracteres}} y {{maxCaracteres}} carácteres con una combinación de mayúsculas, minúsculas, números y símbolos.</mat-hint>
        <mat-icon color="warn" matSuffix *ngIf="!controlCampo.valid && controlCampo.touched && mostrarIconError">
            <neo-icono-form *ngIf="!controlCampo.valid && controlCampo.touched "
            [matsuffix]=true
            [iconoClase]="'mat-icon icon-20 icon-ion--information-circle'"
            ></neo-icono-form>
        </mat-icon>
        <i data-testid="alternarVisualizarContrasena" *ngIf="controlCampo?.value?.length > 0" class="mat-icon icon-20 icon-password pointer mat-form-field-suffix form-field-suffix-icon" matSuffix (click)="hide = !hide" [ngClass]="{ 'icon-ibm--view': hide, 'icon-ibm--view--off':!hide }"></i>
        <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
          <neo-mat-error-form
              [nombreCampo]="label"
              [controlCampo]=controlCampo
              [customError]="true">
            <ng-container *ngIf="controlCampo.errors?.mustMatch">  <div data-testid="mat-error-mustMatch">Las contraseñas no coinciden.</div>  </ng-container>
          </neo-mat-error-form>
        </mat-error>
      <!-- este error no se muestra, solo activacion invalid campo -->
      <!-- <ng-container *ngIf="controlCampo.hasError('errorCriterios')">  <div>Utilice al menos entre {{minCaracteres}} y {{maxCaracteres}} carácteres con una combinación de mayúsculas, minúsculas, números y símbolos.</div>  </ng-container> -->
    </mat-form-field>
</div>
<neo-fortaleza-contresena *ngIf="barraFortaleza && controlCampo?.value?.length" [contrasena]="controlCampo.value"></neo-fortaleza-contresena>
<neo-criterio-validez-contrasena
    *ngIf="criterioValidez && controlCampo?.value?.length"
    [controlCampo]="controlCampo">
</neo-criterio-validez-contrasena>

