import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {InputTypeEnum} from '@appNeo/neoShared/components/input/input.component';

@Component({
  selector: 'neo-input-importe [controlCampo]',
  templateUrl: './input-importe.component.html',
  styleUrls: ['./input-importe.component.scss']
})
export class InputImporteComponent {

  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() mostrarMatError = true;
  @Input() tipo = InputTypeEnum.text;
  @Input() placeholder = '000.00';


  constructor() { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.controlCampo.value == '0') {
      this.controlCampo.setValue('0.00');
    }
  }
  public formatearImporte(valor) {
      this.controlCampo.setValue(parseFloat(valor).toFixed(2));
  }

}
