import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-icono',
  templateUrl: './icono.component.html',
  styleUrls: ['./icono.component.scss']
})
export class IconoComponent {

  @Input() icono = '';
  @Input() iconoClase = '';
  @Input() color = '';
  @Input() titulo = '';
  constructor() { }
}
