import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutPerfiloEnum, PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { TipoDatePickerEnum } from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { ClasesCampoLayoutCamposAnchoFijoFormularioEnum, ClasesCampoLayoutCamposPorcentajeFormularioEnum } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { environment } from '@environments/environment';

@Component({
  selector: 'neo-perfil-propiedad [propiedad]',
  templateUrl: './perfil-propiedad.component.html',
  styleUrls: ['./perfil-propiedad.component.scss']
})
export class PerfilPropiedadComponent implements OnInit {

  @Input() propiedad: IPerfilPropiedad;
  @Input('layout') layout: LayoutPerfiloEnum = null;
  @Output() clickPropiedadEnlace = new EventEmitter(); 

  // TODO unificar y llevarlo a un sitio comun tanto para perfil como propieadad
  clasesCampoDefectoLayoutCamposPorcentaje  = {
    input: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    inputFilter: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    textarea: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    select: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    chipsAutocomplete: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    date: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    boolean: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    importe: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    cantidad: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    telefono: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    radio: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    checkbox: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    contrasena: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    captcha: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    fichero: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    estado: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    editor: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    clipboard: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
  }


  clasesCampoDefectoLayoutCamposAnchoFijo  = {
    input: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    inputFilter: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    textarea: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    select: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    chipsAutocomplete: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    date: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    boolean: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    importe: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    cantidad: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    telefono: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    radio: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    checkbox: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    contrasena: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    captcha: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    fichero: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    estado: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx3x],
    editor: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    clipboard: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x]
  }
  
  logintudTruncado = environment.longuitud_truncar_textos_defecto;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  get _PerfilPropiedadTagEnum() {
    return PerfilPropiedadTagEnum;
  }

  get _TagCampoEnum() {
    return TagCampoEnum;
  }

  get _claseCampoLayout(): any{
    let clases = '';
    if (this.layout) {
      switch(this.layout) {
        case LayoutPerfiloEnum.layoutCamposAnchoFijo: {
          clases = (this.propiedad?.clasePersonalizadaLayoutCamposAnchoFijo?.join(''))  
          ? this.propiedad?.clasePersonalizadaLayoutCamposAnchoFijo?.join('') 
          : this.clasesCampoDefectoLayoutCamposAnchoFijo[(this.propiedad.tagCampo).toLowerCase()]?.join('') 
          break;
        }

        case LayoutPerfiloEnum.layoutCamposPorcentaje: {
          clases = (this.propiedad?.clasePersonalizadaLayoutCamposPorcentaje?.join(''))  
          ? this.propiedad?.clasePersonalizadaLayoutCamposPorcentaje?.join('') 
          : this.clasesCampoDefectoLayoutCamposPorcentaje[(this.propiedad.tagCampo).toLowerCase()]?.join('') 
          break;
        }
      }
     
    }
    return clases;
  }

  esString(valor) {
    return typeof valor === 'string' || valor instanceof String;
  }

  clickLink(pagina: string, identificador?: string) {
    this.clickPropiedadEnlace.emit({ pagina, identificador });
    let ruta = [`/${pagina}`];
    if (identificador) {
      ruta = [`/${pagina}`, identificador];
    }

    this.router.navigate(ruta);
    // this.router.navigate([]).then((result) => {
    //   window.open(`/${pagina}`, '_blank');
    // });
  }

  get _TipoDatePickerEnum(){
    return TipoDatePickerEnum;
  }

}
