import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';
import { LayoutComponent } from './layout/layout.component'
import { SharedModule } from '@app/shared/shared-module';
import { LayoutsModule } from '@app/shared/modules/layouts/layouts.module';




@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    LayoutsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
  ],
  exports: [
    LayoutComponent
  ]
})
export class CoreModule {
  // Asegurarnos de que CoreModule solo sea importado desde el AppModule
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }

}
