<div class="drag-drop">
  <!-- <h2>{{titulo}}</h2> -->
  <neo-busqueda #buscadorDragDrop
                placeholder="Busqueda"
                *ngIf="filtrado"
                [keyFiltrado]="'dragDrop'"
                (changeBuscador)="aplicarFiltrado($event)"
                (limpiarBuscador)="aplicarFiltrado($event)"
  ></neo-busqueda>

  <div class="drag-flex">
    <div class="drag-container">
      <h3 class="drag-title">DISPONIBLES</h3>
      <div
        cdkDropList
        #columnaIzqDragDrop="cdkDropList"
        [cdkDropListData]="columnaIzq"
        [cdkDropListConnectedTo]="[columnaDchaDragDrop]"
        class="drag-list"
        (cdkDropListDropped)="drop($event, dragDropService._DireccionArrastreEnum.Izquierda)"
      >
        <ng-container *ngFor="let item of columnaIzq">
          <div class="drag-box pt-5 pb-5 pl-5 pr-5" id="item-drag-{{item?.id}}" *ngIf="item && item.visibilidadFiltrado"
               (click)="clickOutColumnaIzq($event, item)"
               cdkDrag>
            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <i class="icon-20 icon-ibm--draggable text-grey500"></i>
            {{ item.nombre | truncarTexto:300 }}
            <ng-container *ngIf="mostrarIdItem">{{ item.id }}</ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex botoneraDragDrop">
      <neo-button
        [miniFab]="true"
        [iconClass]="'icon-16 icon-dua--double-arrow-left'"
        (click)="seleccionarTodoColumnaDcha()"
      ></neo-button>
      <neo-button
        [miniFab]="true"
        [iconClass]="'icon-16 icon-dua--double-arrow-right'"
        (click)="seleccionarTodoColumnaIzq()"
      ></neo-button>
    </div>

    <div class="drag-container">
      <h3 class="drag-title">AÑADIDOS</h3>
      <div
        cdkDropList
        #columnaDchaDragDrop="cdkDropList"
        [cdkDropListData]="columnaDcha"
        [cdkDropListConnectedTo]="[columnaIzqDragDrop]"
        class="drag-list"
        (cdkDropListDropped)="drop($event, dragDropService._DireccionArrastreEnum.Derecha)">
        <ng-container *ngFor="let item of columnaDcha">
          <div class="drag-box pt-5 pb-5 pl-5 pr-5" id="item-drag-{{item.id}}" *ngIf="item && item.visibilidadFiltrado"
               (click)="clickOutColumnaDcha(item)"
               cdkDrag>
            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <i class="icon-20 icon-ibm--draggable text-grey500"></i>
            {{ item.nombre | truncarTexto: 300}}
            <ng-container *ngIf="mostrarIdItem">{{ item.id }}</ng-container>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>
