import { IUsuario, IUsuarioEstadoActual } from '@appNeo/neoCore/interfaces/IUsuario';
import { IArbolData } from '../../demo-componentes/demo-componentes.component';
import { IFichero, IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import {AccionesTablaEnum} from '@appNeo/neoShared/services/tabla/tabla.service';

export const USUARIOS_ESTADOS_DEMO: IUsuarioEstadoActual[] = [
  {id: '1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Activo' },
{id: '2', iconoClase: 'icon-20 icon-ibm--restart', color: '#00D400', nombre: 'Nuevo' }]

export const USUARIO_SESSION_ROL = [
  {
    "id": "2",
    "identificador": "ADMIN",
    "nombre": "Administrador"
  },
  // {
  //     "id": "17",
  //     "identificador": "MKV-T",
  //     "nombre": "Marketing y ventas - Técnico"
  // }
];

export const USUARIO_SESSION_PERMISOS = [
  {
      "identificador": "administrador",
      "descripcion": "Administrador"
  }  
];
export const USUARIOS_DEMO : IUsuario[]= [
    {
        id: '1',
        nombre: 'Martina',
        apellidos: 'Gómez Pérez',
        email: 'martina.gomez@duacode.com',
        avatar: {
            id: '1',
            nombre: 'Martina',
            extension: '.jpg',
            peso: 2300,
            imagen: {
                src: '@assets/images/avatardemo.png'
            }
        },
        usuarioEstadoActual: USUARIOS_ESTADOS_DEMO[0],
        fechaNacimiento: new Date(),
        imagen: '',
        imagenListado: '',
        imagenPerfil: '',
        nombreCompleto: '',
        rutaFoto: '',
        alergia: ''
    }
]

export const PERMISOS_USUARIO_SIN_FORMATO = [
  {
    "identificador": "api-login",
    "descripcion": "Login mediante API"
  },
  {
    "identificador": "cuentas-front-ver-basico",
    "descripcion": "Ver datos básicos de cuenta"
  },
  {
    "identificador": "cuentas-front-ver",
    "descripcion": "Ver cuentas"
  },
  {
    "identificador": "cuentas-front",
    "descripcion": "Cuentas para ver en el front"
  },
  // {
  //   "identificador": "oportunidades-front-accion-enviarPropuestaCliente",
  //   "descripcion": "Enviar propuesta al cliente"
  // },
  // {
  //   "identificador": "oportunidades-front-accion",
  //   "descripcion": "Permiso de realización de acciones"
  // },
  // {
  //   "identificador": "oportunidades-front",
  //   "descripcion": "oportunidades para ver en el front"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-ganar",
  //   "descripcion": "Ganar"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-mantenerALaEspera",
  //   "descripcion": "Mantener a la espera"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-perder",
  //   "descripcion": "Perder"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-solicitarPresupuesto",
  //   "descripcion": "Solicitar presupuesto"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-solicitarReunion",
  //   "descripcion": "Solicitar reunión"
  // },
  // {
  //   "identificador": "oportunidades-front-crear",
  //   "descripcion": "Crear oportunidades"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-basico",
  //   "descripcion": "Editar oportunidades con sus datos básicos"
  // },
  // {
  //   "identificador": "oportunidades-front-editar",
  //   "descripcion": "Permiso de edición de oportunidades"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-contacto",
  //   "descripcion": "Editar contacto"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-descripcion",
  //   "descripcion": "Editar descripción"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-fecha",
  //   "descripcion": "Editar fecha"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-jefeProyecto",
  //   "descripcion": "Editar jefe de proyecto"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-nombreCuenta",
  //   "descripcion": "Editar nombre de cuenta"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-nombreProyecto",
  //   "descripcion": "Editar nombre de proyecto"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-responsableComercial",
  //   "descripcion": "Editar responsable comercial"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-responsableTecnico",
  //   "descripcion": "Editar responsable técnico"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-solicitarPresupuesto",
  //   "descripcion": "Editar solicitar presupuesto"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-solicitarReunion",
  //   "descripcion": "Editar solicitar reunión"
  // },
  // {
  //   "identificador": "oportunidades-front-editar-tipoProyecto",
  //   "descripcion": "Editar tipo de proyecto"
  // },
  // {
  //   "identificador": "oportunidades-front-historial",
  //   "descripcion": "Historial de oportunidades"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-basico",
  //   "descripcion": "Ver informacion básica de propuestas"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver",
  //   "descripcion": "Permisos sobre ver  propuesta"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta",
  //   "descripcion": "Permiso de operar sobre la propuesta"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-conceptos-basico",
  //   "descripcion": "Ver información básica de conceptos"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-conceptos",
  //   "descripcion": "Permiso para ver conceptos"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-conceptos-conImporte",
  //   "descripcion": "Ver conceptos con importe"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-estimacionHoras-basico",
  //   "descripcion": "Ver información básica estimación de horas"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-estimacionHoras",
  //   "descripcion": "Permiso para estimar horas"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-estimacionHoras-conImporte",
  //   "descripcion": "Ver estimación de horas con importe"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-funcional",
  //   "descripcion": "Ver funcional"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion-basico",
  //   "descripcion": "Ver información básica de hitos facturacion"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion",
  //   "descripcion": "Permiso para ver hitosFacturacion"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion-conImporte",
  //   "descripcion": "Ver hitos facturación con importe"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura-basico",
  //   "descripcion": "Ver información básica de lineas infraestructura"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura",
  //   "descripcion": "Permiso para ver lineas de infraestructura"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura-conImporte",
  //   "descripcion": "Ver líneas infraestructura con importe"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-mantenimiento-basico",
  //   "descripcion": "Ver información básica de mantenimiento"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-mantenimiento",
  //   "descripcion": "Permiso para ver mantenimientos"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-mantenimiento-conImporte",
  //   "descripcion": "Ver mantenimiento con importe"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-ver-requisitos",
  //   "descripcion": "Ver requisitos"
  // },
  // {
  //   "identificador": "oportunidades-front-tareas",
  //   "descripcion": "Tareas"
  // },
  // {
  //   "identificador": "oportunidades-front-ver",
  //   "descripcion": "Ver oportunidades"
  // },
  // {
  //   "identificador": "proyectos-front-ver-basico",
  //   "descripcion": "Ver datos básicos de proyectos"
  // },
  // {
  //   "identificador": "proyectos-front-ver",
  //   "descripcion": "Ver proyectos"
  // },
  // {
  //   "identificador": "proyectos-front",
  //   "descripcion": "Proyectos para ver en el front"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver-basico",
  //   "descripcion": "Ver datos básicos"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver",
  //   "descripcion": ""
  // },
  // {
  //   "identificador": "proyectos-front-servicios",
  //   "descripcion": "Servicios de proyectos"
  // },
  // {
  //   "identificador": "administrador",
  //   "descripcion": "Permiso único para el usuario administrador"
  // },
  // {
  //   "identificador": "cuentas-front-editar-basico",
  //   "descripcion": "Crear/editar cuentas con datos básicos"
  // },
  // {
  //   "identificador": "cuentas-front-editar",
  //   "descripcion": "Crear editar cuentas"
  // },
  // {
  //   "identificador": "cuentas-front-editar-datosBancarios",
  //   "descripcion": "Editar datos bancarios"
  // },
  // {
  //   "identificador": "cuentas-front-editar-facturas",
  //   "descripcion": "Editar facturas"
  // },
  // {
  //   "identificador": "cuentas-front-editar-preciosHoraPorDepartamento",
  //   "descripcion": "Editar precios hora por departamento"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-subir-basico",
  //   "descripcion": "Subir ficheros tipo basico"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-subir",
  //   "descripcion": "Subir ficheros"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros",
  //   "descripcion": "Gestionar ficheros en cuentas"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-subir-documentosAdicional",
  //   "descripcion": "Subir ficheros tipo documentacion adicional"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-ver-basico",
  //   "descripcion": "Ver ficheros tipo basico"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-ver",
  //   "descripcion": "Ver ficheros"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-ver-contratos",
  //   "descripcion": "Ver ficheros tipo contrato"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-ver-documentosAdicional",
  //   "descripcion": "Ver ficheros tipo documentacion adicional"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-ver-propuestasComerciales",
  //   "descripcion": "Ver ficheros tipo propuestas comerciales"
  // },
  // {
  //   "identificador": "cuentas-front-ver-datosBancarios",
  //   "descripcion": "Ver datos bancarios"
  // },
  // {
  //   "identificador": "cuentas-front-ver-facturas",
  //   "descripcion": "Ver facturas"
  // },
  // {
  //   "identificador": "cuentas-front-ver-preciosHoraPorDepartamento",
  //   "descripcion": "Ver precios hora por departamento"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-indicarInformacionInsuficienteParaEstimar",
  //   "descripcion": "Indicar información insuficiente para estimar"
  // },
  // {
  //   "identificador": "oportunidades-front-accion-solicitarValidacionDirectorMarketingVentas",
  //   "descripcion": "Solicitar la validación del director de MKV"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-accion-validar",
  //   "descripcion": "Validar"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-basico",
  //   "descripcion": "Creación/edición de propuesta con datos básicos"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar",
  //   "descripcion": "Permisos de edición de propuesta"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-conceptos",
  //   "descripcion": "Conceptos"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-duracionEstimada",
  //   "descripcion": "Duración estimada"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-funcional",
  //   "descripcion": "Funcional"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-hitosFacturacion",
  //   "descripcion": "Hitos de facturación"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-lineasInfraestructura",
  //   "descripcion": "Líneas de infraestructura"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-mantenimiento",
  //   "descripcion": "Mantenimiento"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-precioTotal",
  //   "descripcion": "Precio total"
  // },
  // {
  //   "identificador": "oportunidades-front-propuesta-editar-requisitos",
  //   "descripcion": "Requisitos"
  // },
  // {
  //   "identificador": "proyectos-front-editar-basico",
  //   "descripcion": "Crear/editar proyectos con datos básicos"
  // },
  // {
  //   "identificador": "proyectos-front-editar",
  //   "descripcion": "Crear editar proyectos"
  // },
  // {
  //   "identificador": "proyectos-front-editar-estados",
  //   "descripcion": "Editar estados"
  // },
  // {
  //   "identificador": "proyectos-front-editar-facturas",
  //   "descripcion": "Editar facturas"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar-analisisFuncional",
  //   "descripcion": "Editar análisis funcional"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar",
  //   "descripcion": "Crear/editar datos de servicios"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar-basico",
  //   "descripcion": "Editar datos básicos"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar-estimacionPorDepartamento",
  //   "descripcion": "Editar estimaciones por departamento"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar-estimacionTotal",
  //   "descripcion": "Editar estimación total"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-editar-hitosFacturacion",
  //   "descripcion": "Editar hitos facturación"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver-analisisFuncional",
  //   "descripcion": "Ver análisis funcional"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver-estimacionPorDepartamento",
  //   "descripcion": "Ver estimaciones por departamento"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver-estimacionTotal",
  //   "descripcion": "Ver estimación total"
  // },
  // {
  //   "identificador": "proyectos-front-servicios-ver-hitosFacturacion",
  //   "descripcion": "Ver hitos facturación"
  // },
  // {
  //   "identificador": "proyectos-front-ver-estados",
  //   "descripcion": "Ver estados"
  // },
  // {
  //   "identificador": "proyectos-front-ver-facturas",
  //   "descripcion": "Ver facturas"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-subir-contratos",
  //   "descripcion": "Subir ficheros tipo contrato"
  // },
  // {
  //   "identificador": "cuentas-front-ficheros-subir-propuestasComerciales",
  //   "descripcion": "Subir ficheros tipo propuestas comerciales"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros",
  //   "descripcion": "Gestionar ficheros en proyectos"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-subir",
  //   "descripcion": "Subir ficheros"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-subir-basico",
  //   "descripcion": "Subir ficheros tipo basico"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-subir-contratos",
  //   "descripcion": "Subir ficheros tipo contrato"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-subir-documentosAdicional",
  //   "descripcion": "Subir ficheros tipo documentacion adicional"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-subir-propuestasComerciales",
  //   "descripcion": "Subir ficheros tipo propuestas comerciales"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-ver",
  //   "descripcion": "Ver ficheros"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-ver-basico",
  //   "descripcion": "Ver ficheros tipo basico"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-ver-contratos",
  //   "descripcion": "Ver ficheros tipo contrato"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-ver-documentosAdicional",
  //   "descripcion": "Ver ficheros tipo documentacion adicional"
  // },
  // {
  //   "identificador": "proyectos-front-ficheros-ver-propuestasComerciales",
  //   "descripcion": "Ver ficheros tipo propuestas comerciales"
  // }
];
export const PERMISOS_DISPONIBLES_SINFORMATO = [
  { "id": "1", "idPermisoPadre": null, "identificador": "admin-front-login", "descripcion": "Login en el área pública" },
  { "id": "2", "idPermisoPadre": null, "identificador": "admin-back-login", "descripcion": "Acceso al panel de administración" },
  { "id": "3", "idPermisoPadre": null, "identificador": "admin-back-usuarios", "descripcion": "Acceso a la gestión de usuarios" },
  { "id": "4", "idPermisoPadre": "3", "identificador": "admin-back-usuarios-ver", "descripcion": "Ver listado e información de usuarios" },
  { "id": "5", "idPermisoPadre": "3", "identificador": "admin-back-usuarios-editar", "descripcion": "Crear y editar usuarios" },
  { "id": "6", "idPermisoPadre": "3", "identificador": "admin-back-usuarios-excel", "descripcion": "Descargar listados de usuarios en excel" },
  { "id": "7", "idPermisoPadre": "3", "identificador": "admin-back-usuarios-eliminar", "descripcion": "Eliminar usuarios" },
  { "id": "8", "idPermisoPadre": "3", "identificador": "admin-back-usuarios-permisos", "descripcion": "Gestionar los permisos de un usuario" },
  { "id": "9", "idPermisoPadre": null, "identificador": "admin-back-roles", "descripcion": "Acceso a la gestión de roles" },
  { "id": "10", "idPermisoPadre": "9", "identificador": "admin-back-roles-ver", "descripcion": "Ver listado e información de usuarios" },
  { "id": "11", "idPermisoPadre": "9", "identificador": "admin-back-roles-editar", "descripcion": "Crear y editar roles" },
  { "id": "12", "idPermisoPadre": "9", "identificador": "admin-back-roles-eliminar", "descripcion": "Eliminar roles" },
  { "id": "13", "idPermisoPadre": "9", "identificador": "admin-back-roles-permisos", "descripcion": "Gestionar los permisos de un rol" },
  { "id": "14", "idPermisoPadre": null, "identificador": "admin-back-tipos", "descripcion": "Acceso al módulo de tipos editables" },
  { "id": "15", "idPermisoPadre": "14", "identificador": "admin-back-tipos-ver", "descripcion": "Ver todos los tipos, y editar solo los tipos editables" },
  { "id": "16", "idPermisoPadre": "14", "identificador": "admin-back-tipos-editar", "descripcion": "Editar todos los tipos, incluídos los restringidos" },
  { "id": "17", "idPermisoPadre": null, "identificador": "admin-back-historico", "descripcion": "Acceso al módulo de histórico" },
  { "id": "18", "idPermisoPadre": "17", "identificador": "admin-back-historico-ver", "descripcion": "Ver todo el histórico" },
  { "id": "19", "idPermisoPadre": null, "identificador": "admin-back-preferencias", "descripcion": "Acceso al módulo de preferencias" },
  { "id": "20", "idPermisoPadre": "19", "identificador": "admin-back-preferencias-ver", "descripcion": "Ver preferencias" },
  { "id": "21", "idPermisoPadre": "19", "identificador": "admin-back-preferencias-editar", "descripcion": "Editar preferencias" },
  { "id": "22", "idPermisoPadre": null, "identificador": "admin-back-contacto", "descripcion": "Acceso al módulo de contacto" },
  { "id": "23", "idPermisoPadre": "22", "identificador": "admin-back-contacto-ver", "descripcion": "Ver contacto" },
  { "id": "24", "idPermisoPadre": "22", "identificador": "admin-back-contacto-editar", "descripcion": "Editar contacto" },
  { "id": "25", "idPermisoPadre": null, "identificador": "admin-back-curriculum", "descripcion": "Acceso al módulo de curriculum" },
  { "id": "26", "idPermisoPadre": "25", "identificador": "admin-back-curriculum-ver", "descripcion": "Ver curriculum" },
  { "id": "27", "idPermisoPadre": "25", "identificador": "admin-back-curriculum-editar", "descripcion": "Editar curriculum" },
  { "id": "28", "idPermisoPadre": null, "identificador": "admin-back-newsletter", "descripcion": "Acceso al módulo de newsletter" },
  { "id": "29", "idPermisoPadre": "28", "identificador": "admin-back-newsletter-ver", "descripcion": "Ver newsletter" },
  { "id": "30", "idPermisoPadre": "28", "identificador": "admin-back-newsletter-editar", "descripcion": "Editar newsletter" },
  { "id": "31", "idPermisoPadre": "28", "identificador": "admin-back-newsletter-excel", "descripcion": "Editar newsletter" },
  { "id": "61", "idPermisoPadre": null, "identificador": "administrador", "descripcion": "Permiso único para el usuario administrador" },
  { "id": "62", "idPermisoPadre": null, "identificador": "oportunidades-front", "descripcion": "oportunidades para ver en el front" },
  { "id": "63", "idPermisoPadre": "62", "identificador": "oportunidades-front-ver", "descripcion": "Ver oportunidades" },
  { "id": "64", "idPermisoPadre": "62", "identificador": "oportunidades-front-crear", "descripcion": "Crear oportunidades" },
  { "id": "65", "idPermisoPadre": "62", "identificador": "oportunidades-front-editar", "descripcion": "Permiso de edición de oportunidades" },
  { "id": "66", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-basico", "descripcion": "Editar oportunidades con sus datos básicos" },
  { "id": "67", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-fecha", "descripcion": "Editar fecha" },
  { "id": "68", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-descripcion", "descripcion": "Editar descripción" },
  { "id": "69", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-nombreProyecto", "descripcion": "Editar nombre de proyecto" },
  { "id": "70", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-tipoProyecto", "descripcion": "Editar tipo de proyecto" },
  { "id": "71", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-nombreCuenta", "descripcion": "Editar nombre de cuenta" },
  { "id": "72", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-contacto", "descripcion": "Editar contacto" },
  { "id": "73", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-responsableTecnico", "descripcion": "Editar responsable técnico" },
  { "id": "74", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-jefeProyecto", "descripcion": "Editar jefe de proyecto" },
  { "id": "75", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-responsableComercial", "descripcion": "Editar responsable comercial" },
  { "id": "76", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-solicitarPresupuesto", "descripcion": "Editar solicitar presupuesto" },
  { "id": "77", "idPermisoPadre": "65", "identificador": "oportunidades-front-editar-solicitarReunion", "descripcion": "Editar solicitar reunión" },
  { "id": "78", "idPermisoPadre": "62", "identificador": "oportunidades-front-accion", "descripcion": "Permiso de realización de acciones" },
  { "id": "79", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-solicitarPresupuesto", "descripcion": "Solicitar presupuesto" },
  { "id": "80", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-solicitarReunion", "descripcion": "Solicitar reunión" },
  { "id": "81", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-mantenerALaEspera", "descripcion": "Mantener a la espera" },
  { "id": "82", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-solicitarValidacionDirectorMarketingVentas", "descripcion": "Solicitar la validación del director de MKV" },
  { "id": "83", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-enviarPropuestaCliente", "descripcion": "Enviar propuesta al cliente" },
  { "id": "84", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-ganar", "descripcion": "Ganar" },
  { "id": "85", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-perder", "descripcion": "Perder" },
  { "id": "86", "idPermisoPadre": "78", "identificador": "oportunidades-front-accion-indicarInformacionInsuficienteParaEstimar", "descripcion": "Indicar información insuficiente para estimar" },
  { "id": "87", "idPermisoPadre": "62", "identificador": "oportunidades-front-propuesta", "descripcion": "Permiso de operar sobre la propuesta" },
  { "id": "88", "idPermisoPadre": "87", "identificador": "oportunidades-front-propuesta-ver", "descripcion": "Permisos sobre ver  propuesta" },
  { "id": "89", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-basico", "descripcion": "Ver informacion básica de propuestas" },
  { "id": "90", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-estimacionHoras", "descripcion": "Permiso para estimar horas" },
  { "id": "91", "idPermisoPadre": "90", "identificador": "oportunidades-front-propuesta-ver-estimacionHoras-basico", "descripcion": "Ver información básica estimación de horas" },
  { "id": "92", "idPermisoPadre": "90", "identificador": "oportunidades-front-propuesta-ver-estimacionHoras-conImporte", "descripcion": "Ver estimación de horas con importe" },
  { "id": "93", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-requisitos", "descripcion": "Ver requisitos" },
  { "id": "94", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-funcional", "descripcion": "Ver funcional" },
  { "id": "95", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura", "descripcion": "Permiso para ver lineas de infraestructura" },
  { "id": "96", "idPermisoPadre": "95", "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura-basico", "descripcion": "Ver información básica de lineas infraestructura" },
  { "id": "97", "idPermisoPadre": "95", "identificador": "oportunidades-front-propuesta-ver-lineasInfraestructura-conImporte", "descripcion": "Ver líneas infraestructura con importe" },
  { "id": "98", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-mantenimiento", "descripcion": "Permiso para ver mantenimientos" },
  { "id": "99", "idPermisoPadre": "98", "identificador": "oportunidades-front-propuesta-ver-mantenimiento-basico", "descripcion": "Ver información básica de mantenimiento" },
  { "id": "100", "idPermisoPadre": "98", "identificador": "oportunidades-front-propuesta-ver-mantenimiento-conImporte", "descripcion": "Ver mantenimiento con importe" },
  { "id": "101", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-conceptos", "descripcion": "Permiso para ver conceptos" },
  { "id": "102", "idPermisoPadre": "101", "identificador": "oportunidades-front-propuesta-ver-conceptos-basico", "descripcion": "Ver información básica de conceptos" },
  { "id": "103", "idPermisoPadre": "101", "identificador": "oportunidades-front-propuesta-ver-conceptos-conImporte", "descripcion": "Ver conceptos con importe" },
  { "id": "104", "idPermisoPadre": "88", "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion", "descripcion": "Permiso para ver hitosFacturacion" },
  { "id": "105", "idPermisoPadre": "104", "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion-basico", "descripcion": "Ver información básica de hitos facturacion" },
  { "id": "106", "idPermisoPadre": "104", "identificador": "oportunidades-front-propuesta-ver-hitosFacturacion-conImporte", "descripcion": "Ver hitos facturación con importe" },
  { "id": "107", "idPermisoPadre": "87", "identificador": "oportunidades-front-propuesta-editar", "descripcion": "Permisos de edición de propuesta" },
  { "id": "108", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-basico", "descripcion": "Creación\/edición de propuesta con datos básicos" },
  { "id": "109", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-requisitos", "descripcion": "Requisitos" },
  { "id": "110", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-funcional", "descripcion": "Funcional" },
  { "id": "111", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-estimacion", "descripcion": "Estimación" },
  { "id": "112", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-precioTotal", "descripcion": "Precio total" },
  { "id": "113", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-duracionEstimada", "descripcion": "Duración estimada" },
  { "id": "114", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-conceptos", "descripcion": "Conceptos" },
  { "id": "115", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-hitosFacturacion", "descripcion": "Hitos de facturación" },
  { "id": "116", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-lineasInfraestructura", "descripcion": "Líneas de infraestructura" },
  { "id": "117", "idPermisoPadre": "107", "identificador": "oportunidades-front-propuesta-editar-mantenimiento", "descripcion": "Mantenimiento" },
  { "id": "118", "idPermisoPadre": "87", "identificador": "oportunidades-front-propuesta-accion-validar", "descripcion": "Validar" },
  { "id": "119", "idPermisoPadre": "62", "identificador": "oportunidades-front-historial", "descripcion": "Historial de oportunidades" },
  { "id": "120", "idPermisoPadre": "62", "identificador": "oportunidades-front-tareas", "descripcion": "Tareas" },
  { "id": "121", "idPermisoPadre": null, "identificador": "cuentas-front", "descripcion": "Cuentas para ver en el front" },
  { "id": "122", "idPermisoPadre": "121", "identificador": "cuentas-front-ver", "descripcion": "Ver cuentas" },
  { "id": "123", "idPermisoPadre": "122", "identificador": "cuentas-front-ver-basico", "descripcion": "Ver datos básicos de cuenta" },
  { "id": "124", "idPermisoPadre": "122", "identificador": "cuentas-front-ver-datosBancarios", "descripcion": "Ver datos bancarios" },
  { "id": "125", "idPermisoPadre": "122", "identificador": "cuentas-front-ver-facturas", "descripcion": "Ver facturas" },
  { "id": "126", "idPermisoPadre": "122", "identificador": "cuentas-front-ver-preciosHoraPorDepartamento", "descripcion": "Ver precios hora por departamento" },
  { "id": "127", "idPermisoPadre": "121", "identificador": "cuentas-front-editar", "descripcion": "Crear editar cuentas" },
  { "id": "128", "idPermisoPadre": "127", "identificador": "cuentas-front-editar-basico", "descripcion": "Crear\/editar cuentas con datos básicos" },
  { "id": "129", "idPermisoPadre": "127", "identificador": "cuentas-front-editar-datosBancarios", "descripcion": "Editar datos bancarios" },
  { "id": "130", "idPermisoPadre": "127", "identificador": "cuentas-front-editar-facturas", "descripcion": "Editar facturas" },
  { "id": "131", "idPermisoPadre": "127", "identificador": "cuentas-front-editar-preciosHoraPorDepartamento", "descripcion": "Editar precios hora por departamento" },
  { "id": "132", "idPermisoPadre": null, "identificador": "api-login", "descripcion": "Login mediante API" },
  { "id": "133", "idPermisoPadre": null, "identificador": "loginAdministradorDuacode-api", "descripcion": "Permiso para loguearse como administrador Duacode en aplicaciones Matrix" },
  { "id": "134", "idPermisoPadre": null, "identificador": "proyectos-front", "descripcion": "Proyectos para ver en el front" },
  { "id": "135", "idPermisoPadre": "134", "identificador": "proyectos-front-ver", "descripcion": "Ver proyectos" },
  { "id": "136", "idPermisoPadre": "135", "identificador": "proyectos-front-ver-basico", "descripcion": "Ver datos básicos de proyectos" },
  { "id": "137", "idPermisoPadre": "135", "identificador": "proyectos-front-ver-facturas", "descripcion": "Ver facturas" },
  { "id": "138", "idPermisoPadre": "135", "identificador": "proyectos-front-ver-estados", "descripcion": "Ver estados" },
  { "id": "139", "idPermisoPadre": "134", "identificador": "proyectos-front-editar", "descripcion": "Crear editar proyectos" },
  { "id": "140", "idPermisoPadre": "139", "identificador": "proyectos-front-editar-basico", "descripcion": "Crear\/editar proyectos con datos básicos" },
  { "id": "141", "idPermisoPadre": "139", "identificador": "proyectos-front-editar-estados", "descripcion": "Editar estados" },
  { "id": "142", "idPermisoPadre": "139", "identificador": "proyectos-front-editar-facturas", "descripcion": "Editar facturas" },
  { "id": "143", "idPermisoPadre": "134", "identificador": "proyectos-front-servicios", "descripcion": "Servicios de proyectos" },
  { "id": "144", "idPermisoPadre": "143", "identificador": "proyectos-front-servicios-ver", "descripcion": "" },
  { "id": "145", "idPermisoPadre": "144", "identificador": "proyectos-front-servicios-ver-basico", "descripcion": "Ver datos básicos" },
  { "id": "146", "idPermisoPadre": "144", "identificador": "proyectos-front-servicios-ver-hitosFacturacion", "descripcion": "Ver hitos facturación" },
  { "id": "147", "idPermisoPadre": "144", "identificador": "proyectos-front-servicios-ver-analisisFuncional", "descripcion": "Ver análisis funcional" },
  { "id": "148", "idPermisoPadre": "144", "identificador": "proyectos-front-servicios-ver-estimacionPorDepartamento", "descripcion": "Ver estimaciones por departamento" },
  { "id": "149", "idPermisoPadre": "144", "identificador": "proyectos-front-servicios-ver-estimacionTotal", "descripcion": "Ver estimación total" },
  { "id": "150", "idPermisoPadre": "143", "identificador": "proyectos-front-servicios-editar", "descripcion": "Crear\/editar datos de servicios" },
  { "id": "151", "idPermisoPadre": "150", "identificador": "proyectos-front-servicios-editar-basico", "descripcion": "Editar datos básicos" },
  { "id": "152", "idPermisoPadre": "150", "identificador": "proyectos-front-servicios-editar-hitosFacturacion", "descripcion": "Editar hitos facturación" },
  { "id": "153", "idPermisoPadre": "150", "identificador": "proyectos-front-servicios-editar-analisisFuncional", "descripcion": "Editar análisis funcional" },
  { "id": "154", "idPermisoPadre": "150", "identificador": "proyectos-front-servicios-editar-estimacionPorDepartamento", "descripcion": "Editar estimaciones por departamento" },
  { "id": "155", "idPermisoPadre": "150", "identificador": "proyectos-front-servicios-editar-estimacionTotal", "descripcion": "Editar estimación total" },
  { "id": "156", "idPermisoPadre": "121", "identificador": "cuentas-front-ficheros", "descripcion": "Gestionar ficheros en cuentas" },
  { "id": "157", "idPermisoPadre": "156", "identificador": "cuentas-front-ficheros-ver", "descripcion": "Ver ficheros" },
  { "id": "158", "idPermisoPadre": "157", "identificador": "cuentas-front-ficheros-ver-basico", "descripcion": "Ver ficheros tipo basico" },
  { "id": "159", "idPermisoPadre": "157", "identificador": "cuentas-front-ficheros-ver-propuestasComerciales", "descripcion": "Ver ficheros tipo propuestas comerciales" },
  { "id": "160", "idPermisoPadre": "157", "identificador": "cuentas-front-ficheros-ver-contratos", "descripcion": "Ver ficheros tipo contrato" },
  { "id": "161", "idPermisoPadre": "157", "identificador": "cuentas-front-ficheros-ver-documentosAdicional", "descripcion": "Ver ficheros tipo documentacion adicional" },
  { "id": "167", "idPermisoPadre": "156", "identificador": "cuentas-front-ficheros-subir", "descripcion": "Subir ficheros" },
  { "id": "168", "idPermisoPadre": "167", "identificador": "cuentas-front-ficheros-subir-basico", "descripcion": "Subir ficheros tipo basico" },
  { "id": "169", "idPermisoPadre": "167", "identificador": "cuentas-front-ficheros-subir-propuestasComerciales", "descripcion": "Subir ficheros tipo propuestas comerciales" },
  { "id": "170", "idPermisoPadre": "167", "identificador": "cuentas-front-ficheros-subir-contratos", "descripcion": "Subir ficheros tipo contrato" },
  { "id": "171", "idPermisoPadre": "167", "identificador": "cuentas-front-ficheros-subir-documentosAdicional", "descripcion": "Subir ficheros tipo documentacion adicional" },
  { "id": "172", "idPermisoPadre": "134", "identificador": "proyectos-front-ficheros", "descripcion": "Gestionar ficheros en proyectos" },
  { "id": "173", "idPermisoPadre": "172", "identificador": "proyectos-front-ficheros-ver", "descripcion": "Ver ficheros" },
  { "id": "174", "idPermisoPadre": "173", "identificador": "proyectos-front-ficheros-ver-basico", "descripcion": "Ver ficheros tipo basico" },
  { "id": "175", "idPermisoPadre": "173", "identificador": "proyectos-front-ficheros-ver-propuestasComerciales", "descripcion": "Ver ficheros tipo propuestas comerciales" },
  { "id": "176", "idPermisoPadre": "173", "identificador": "proyectos-front-ficheros-ver-contratos", "descripcion": "Ver ficheros tipo contrato" },
  { "id": "177", "idPermisoPadre": "173", "identificador": "proyectos-front-ficheros-ver-documentosAdicional", "descripcion": "Ver ficheros tipo documentacion adicional" },
  { "id": "178", "idPermisoPadre": "172", "identificador": "proyectos-front-ficheros-subir", "descripcion": "Subir ficheros" },
  { "id": "179", "idPermisoPadre": "178", "identificador": "proyectos-front-ficheros-subir-basico", "descripcion": "Subir ficheros tipo basico" },
  { "id": "180", "idPermisoPadre": "178", "identificador": "proyectos-front-ficheros-subir-propuestasComerciales", "descripcion": "Subir ficheros tipo propuestas comerciales" },
  { "id": "181", "idPermisoPadre": "178", "identificador": "proyectos-front-ficheros-subir-contratos", "descripcion": "Subir ficheros tipo contrato" },
  { "id": "182", "idPermisoPadre": "178", "identificador": "proyectos-front-ficheros-subir-documentosAdicional", "descripcion": "Subir ficheros tipo documentacion adicional" }
];


export const TREE_DATA: IArbolData[] = [
    {
      item: 'cuentas-front',
    id: 'cuentas-front',
    seleccionado: true,
      children: [
        {
          item: 'cuentas-front-acceso',
            id: 'cuentas-front-editar',
            seleccionado: true
        },
        {
          item: 'cuentas-front-editar',
          id: 'cuentas-front-editar',
          seleccionado: false
        },
        {
          item: 'cuentas-front-ver',
          id: 'cuentas-front-ver',
          seleccionado: true,
          children: [
            {
              item: 'cuentas-front-ver-datosBancarios',
              id: 'cuentas-front-ver-datosBancarios',
              seleccionado: true
            },
            {
              item: 'cuentas-front-ver-precios',
              id: 'cuentas-front-ver-precios',
              seleccionado: true
            },
            {
              item: 'cuentas-front-ver-infoBasica',
                id: 'cuentas-front-ver-infoBasica',
                seleccionado: true
            }

          ]
        }

      ]
    }
  ];

  export const DEMOARCHIVOS: IFicheroExtendido[] = [
    {
      id: '1',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '2',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen2.jpg',
      extension: '.jpg',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '3',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen3.pdf',
      extension: '.pdf',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '4',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '5',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    }
  ];

  export const DEMOARCHIVOSSUBIDA: IFichero[] = [
    {
      id: '1',
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
    },
    {
      id: '2',
      nombre: 'imagen2.pdf',
      extension: '.pdf',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      }
    },
    {
      id: '3',
      nombre: 'imagen3.jpg',
      extension: '.jpg',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://tinypng.com/images/social/website.jpg'
      }
    },
    {
      id: '4',
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      }
    },
    {
      id: '5',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '6',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '7',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    }
  ];
