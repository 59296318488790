export enum PerfilPropiedadTagEnum {
    Boolean = 'boolean',
    Estado   = 'estado',
    Basico  = 'basico', 
    Texto   = 'texto',
    //TODO: CAMBIAR NOMENCLATURA PARA ADAPTAR A UNIDADES peso, moneda,...
    Importe = 'importe',
    Fecha = 'fecha',
    
    // externo a colaborador formulario
    Objeto  = 'objeto', // incluye datos valor
    // ArrayObjeto  = 'arrayobjeto', // incluye datos valor
    // colaborador tabla
    Enlace  = 'enlace', // incluye datos valor
    Editable = 'editable', //propiedad no tag aqui iria que tipo es de editable text cantidad inporte
    EnlacePrincipal    = 'enlacePrincipal',
    EnlaceSecundario    = 'enlaceSecundario',
    Accion  = 'accion', 
    Acciones  = 'acciones',
    Icono   = 'icono',
    Imagen = 'imagen',
    Selector = 'selector',
    Avatar = 'avatar' ,
    DespliegueMasInfo = 'despliegueMasInfo', // 
    colorPicker = 'colorPicker'
}


// Layout Porcentajes
// Cada campo tiene asociado un porcentaje, y se va adaptando al ancho disponible, que esta establecido a 600px de forma general
  export enum ClasesPerfilAnchoEnum {
    perfilAnchoCompleto='perfil-ancho-completo',
    perfilAnchoMedio='perfil-ancho-medio',
    perfilAnchoReducido='perfil-ancho-reducido'
  }
  
  export enum LayoutPerfiloEnum {
    layoutCamposPorcentaje='layoutCamposPorcentaje',
    layoutCamposAnchoFijo='layoutCamposAnchoFijo',
  }