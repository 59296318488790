<ng-container [ngSwitch]="propiedad.tagCampo"> 
    <!-- este div no estaba, si fastida aplicamos a perfil-valor -->
    <ng-container *ngSwitchCase="_TagCampoEnum.Boolean"  >
      <div class="perfil-label">{{propiedad?.label}}</div>
      <div class="perfil-valor">
        <neo-campo-boolean-palanca-model
            [color]="'primary'"
            [valor]="propiedad['valor']"
            [disabled]="true"
            (nuevoValor)="propiedad['valor'] = $event">
         </neo-campo-boolean-palanca-model>
        <textarea matInput hidden></textarea>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.ColorPicker">
        <div class="inline-flex flex-column align-items-center">
            <div class="perfil-label">{{propiedad?.label}}</div>
            <div class="perfil-valor">
              <neo-visor-color-picker
                [color]="propiedad?.valor"
              ></neo-visor-color-picker>
            </div>
        </div>
       </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.Checkbox"   >
     <div class="perfil-label">{{propiedad?.label}}</div>
      <div class="perfil-valor" *ngIf="propiedad?.valor; else sinValor">
        {{propiedad?.label}}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.Radio"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
          <ng-container  *ngIf="propiedad?.opcionesSeleccionadas &&  propiedad?.opcionesSeleccionadas.length>0; else sinValor">
              <neo-perfil-valores-multiples [valores]="propiedad.opcionesSeleccionadas"></neo-perfil-valores-multiples>
          </ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Contrasena"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Input"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Avatar"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <ng-container  *ngIf="propiedad?.valor?.nombre; else sinValor">
                <ng-container *ngIf="propiedad.truncarTexto">{{propiedad?.valor?.nombre | truncarTexto: propiedad.truncarTexto}}</ng-container>
                <ng-container *ngIf="!propiedad.truncarTexto">{{propiedad?.valor?.nombre}}</ng-container>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.InputFilter"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Clipboard" 
      >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Select"   >
      <div class="perfil-label">{{propiedad?.label}}</div>
      <div class="perfil-valor">
        <ng-container  *ngIf="propiedad?.opcionesSeleccionadas &&  propiedad?.opcionesSeleccionadas.length>0; else sinValor">
            <neo-perfil-valores-multiples [valores]="propiedad.opcionesSeleccionadas"></neo-perfil-valores-multiples>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Estado"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <ng-container *ngIf="propiedad?.opcionesSeleccionadas &&  propiedad?.opcionesSeleccionadas.length>0; else estado">
                <neo-perfil-valores-multiples [valores]="propiedad.opcionesSeleccionadas"></neo-perfil-valores-multiples>
            </ng-container>
            <ng-template #estado>
                <ng-container *ngIf="propiedad?.valor; else sinValor">
                    <neo-estado-label [estado]="propiedad?.valor"></neo-estado-label>
                </ng-container>
            </ng-template>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.ChipsAutocomplete"   >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
          <ng-container  *ngIf="propiedad?.opcionesSeleccionadas &&  propiedad?.opcionesSeleccionadas.length>0; else sinValor">
              <neo-perfil-valores-multiples [valores]="propiedad.valor"></neo-perfil-valores-multiples>
          </ng-container>
        </div>
        <!-- <ng-template [ngTemplateOutlet]="valorBasico"></ng-template> -->
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Date"  >
      <div class="perfil-label">{{propiedad?.label}}</div>
      <div class="perfil-valor">
        <ng-container *ngIf="propiedad?.valor && propiedad?.valor != '--'; else sinValor">
            <!-- <ng-container *ngIf="!esString(propiedad?.valor)"> -->
                <ng-container [ngSwitch]="propiedad?.tipoDatePicker"> 
                    <ng-container  *ngSwitchCase="_TipoDatePickerEnum.Fecha">
                        {{propiedad?.valor | date: 'd/M/y'}}
                    </ng-container>
                    <ng-container  *ngSwitchCase="_TipoDatePickerEnum.FechaHora">
                        {{propiedad?.valor | date: 'd/M/y, h:mm'}}
                    </ng-container>
                    <ng-container  *ngSwitchCase="_TipoDatePickerEnum.Hora">
                        {{propiedad?.valor | date: 'HH:mm'}}
                    </ng-container>
                    <ng-container  *ngSwitchCase="_TipoDatePickerEnum.FechaRango">
                        {{propiedad?.valor?.start  | date: 'd/M/y'}} - {{propiedad?.valor?.end  | date: 'd/M/y'}}
                    </ng-container>
                    <ng-container  *ngSwitchDefault>
                        {{propiedad?.valor | date: 'd/M/y'}}
                    </ng-container>                    
                </ng-container>
              
            <!-- </ng-container> -->
            
            <!-- <ng-container *ngIf="esString(propiedad?.valor)">
                {{propiedad?.valor}}
            </ng-container> -->

        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Importe"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Cantidad"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Telefono"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Textarea"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <ng-container  *ngIf="propiedad?.valor; else sinValor">
                <neo-span-inner-html [contenido]="propiedad.valor"></neo-span-inner-html>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.Captcha"  >
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Fichero" class="subida-fichero campo" >
        <pre>{{propiedad | json}}</pre>
        <ng-template [ngTemplateOutlet]="valorBasico" *ngIf="propiedad?.valor; else ficheroObjeto"></ng-template>
        <ng-template #ficheroObjeto>
            <div class="perfil-label">{{propiedad?.label}}</div>
            <div class="perfil-valor">
                <ng-container  *ngIf="propiedad?.valor?.alt; else sinValor">
                    <ng-container *ngIf="propiedad.truncarTexto">{{propiedad?.valor?.alt | truncarTexto: propiedad.truncarTexto}}</ng-container>
                    <ng-container *ngIf="!propiedad.truncarTexto">{{propiedad?.valor?.alt}}</ng-container>
                </ng-container>
            </div>
        </ng-template>
        
    
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.FicheroZonaArrastre" class="campo subida-fichero" >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
          <ng-container  *ngIf="propiedad?.valor; else sinValor">
              <neo-perfil-valores-multiples [valores]="propiedad.valor"></neo-perfil-valores-multiples>
          </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="_TagCampoEnum.Editor"  >
        <!-- <ng-template [ngTemplateOutlet]="valorBasico"></ng-template> -->
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <ng-container  *ngIf="propiedad?.valor; else sinValor">
                <neo-span-inner-html [contenido]="propiedad.valor"></neo-span-inner-html>
            </ng-container>
        </div>
        
    </ng-container>

    <!-- otros -->
    <ng-container *ngSwitchCase="_TagCampoEnum.Enlace"  >
        
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <div *ngIf="propiedad?.valor; else sinValor">
                <a class="link linkMain" [class.disabled]="!propiedad?.datos?.pagina" (click)="$event.stopPropagation()"
                    (click)="clickLink(propiedad?.datos?.pagina, propiedad?.datos?.valor)">
                    <ng-container *ngIf="propiedad.truncarTexto">{{ propiedad?.valor | truncarTexto: propiedad.truncarTexto}}
                    </ng-container>
                    <ng-container *ngIf="!propiedad.truncarTexto">{{ propiedad?.valor }}</ng-container>
                </a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Imagen"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <div *ngIf="propiedad?.valor; else sinValor">
                <img [src]="propiedad?.valor"  alt="" />
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.Icono"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <div *ngIf="propiedad?.valor; else sinValor">
                <neo-icono [color]="propiedad?.valor?.color"  [iconoClase]="propiedad?.valor?.class"></neo-icono>
            </div>
        </div>
    </ng-container>

    
    <ng-container *ngSwitchCase="_TagCampoEnum.Objeto"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <div *ngIf="propiedad?.valor; else sinValor">
                <ng-container *ngIf="propiedad.truncarTexto">{{ propiedad?.datos?.valor  | truncarTexto: propiedad.truncarTexto}}</ng-container>
                <ng-container *ngIf="!propiedad.truncarTexto">{{ propiedad?.datos?.valor }}</ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="_TagCampoEnum.ArrayObjeto"  >
        <div class="perfil-label">{{propiedad?.label}}</div>
        <div class="perfil-valor">
            <div *ngIf="propiedad?.valor; else sinValor">
                <span *ngFor="let objeto of propiedad?.valor " class="pr-1">
                    <ng-container *ngIf="propiedad.truncarTexto">{{ objeto?.nombre  | truncarTexto: propiedad.truncarTexto}}</ng-container>
                    <ng-container *ngIf="!propiedad.truncarTexto">{{ objeto?.nombre }}</ng-container>
                </span>
            </div>
        </div>
    </ng-container>
    <!-- no enumerado -->
    <div  *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="valorBasico"></ng-template>
    </div>

  </ng-container>


  <ng-template #valorBasico>
    <div class="perfil-label {{propiedad.identificador}}" [ngClass]="{'destacado': propiedad?.destacado}">
        {{propiedad?.label}}</div>
    <div class="perfil-valor {{propiedad.identificador}}" [ngClass]="{'destacado': propiedad?.destacado}">
      <ng-container  *ngIf="propiedad?.valor; else sinValor">
          <ng-container *ngIf="propiedad.truncarTexto">{{propiedad?.valor | truncarTexto: propiedad.truncarTexto}} {{propiedad.unidad}}</ng-container>
          <ng-container *ngIf="!propiedad.truncarTexto">{{propiedad?.valor}} {{propiedad.unidad}}</ng-container>
      </ng-container>
    </div>
  </ng-template>
  
  <ng-template #sinValor>--</ng-template>




