import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {RouterModule} from '@angular/router';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import {HeadComponent} from '@app/shared/modules/layouts/components/head/head.component';
import {SharedModule} from '@app/shared/shared-module';


@NgModule({
  declarations: [LeftSidebarComponent, RightSidebarComponent, HeadComponent],
  exports: [
    HeadComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerfectScrollbarModule,
    RouterModule,
  ]
})
export class LayoutsModule { }
