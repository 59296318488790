<div *ngIf="item" class="item-card" [ngClass]="{'item-seleccionado': item?.seleccionado, 'item-seleccionable': itemSeleccionable}">
  <div class="cabecera-fichero-card">
    <neo-icono *ngIf="itemMovible" [iconoClase]="'icon-20 icon-ibm--draggable text-grey500 mr-10'"></neo-icono>
    <mat-checkbox color="primary" *ngIf="itemSeleccionable" (change)="seleccionarItem($event, item)"
      [(ngModel)]="item.seleccionado">
    </mat-checkbox>
    <neo-button class="cabecera-btn" *ngFor="let accion of item.acciones" [icono]="true" [iconClass]="accion.iconClass"
      [disabled]="accion.disabled" (click)="determinarAccionCard(accion)"></neo-button>
  </div>

  <ng-container *ngIf="auxiliarService.tipoArchivo(item.extension) | lowercase as tipoArchivo">
    <div class="card-img"
      *ngIf="item.imagen.src && tipoArchivo == (aliasFicherosEnum.imagen | lowercase) else imagenTipoArchivoDefecto">
      <img [src]="item.imagen.src" class="imagen">
      <!-- <ngx-doc-viewer ></ngx-doc-viewer> -->
    </div>
  </ng-container>


  <div class="pie-fichero-card">
    <div>
      <p class="card-nombre">{{item.tituloSeo}}</p>
      <p class="card-peso" *ngIf="item.peso">{{auxiliarService.formatBytes(item.peso)}}</p>
      </div>
      <div>
      <neo-button *ngIf="item.peso || item.textoAlternativoSeo || item.tituloSeo" [icono]="true"
        [iconClass]="'icon-20 icon-ibm--information'" (click)="emitirVerInformacion()"></neo-button>
      </div>
      </div>

</div>



<ng-template #imagenTipoArchivoDefecto>
  <ng-container *ngIf="auxiliarService.tipoArchivo(item.extension) | lowercase as tipoArchivo">
    <span class="card-icon">
      <neo-icono *ngIf="tipoArchivo == (aliasFicherosEnum.documento | lowercase)  "
        [iconoClase]="'icon-100 icon-ibm--document text-primary'"></neo-icono>
      <neo-icono *ngIf="tipoArchivo == (aliasFicherosEnum.imagen | lowercase)  "
        [iconoClase]="'icon-100 icon-ibm--image text-primary'"></neo-icono>
    </span>
  </ng-container>
</ng-template>


<!--Diálogo Editar archivos-->
<ng-template #dialogEditarArchivo>
  <neo-acciones-dialog #accionDialogEditarArchivo dialogTittle="Editar  {{ tipoFichero | lowercase}}" [botonera]="[
                                    {
                                      label: 'Cancelar',
                                      id: 'btn-cancelar',
                                      tipoAccion: 'cerrar',
                                      type: 'button',
                                      color: '',
                                      disabled: false,
                                      activo: true,
                                      flat: true
                                    },
                                    {
                                      label: 'Aceptar',
                                      id: 'btn-guardar',
                                      tipoAccion: 'accion',
                                      type: 'button',
                                      color: 'primary',
                                      disabled: false,
                                      activo: true,
                                      flat: true
                                    }

                                  ]"
                       [claseDialogActions]="''"
                       (accionBoton)="submitAccionDialogEditarArchivo()">
    <neo-fichero-subida #appFicheroSubidaEdicion
                        [idObjeto]="idObjeto"
                        [objetoRelacionado]="objetoRelacionado"
                        [camposExtra]="camposExtra"
                        [urlArchivos]="urlArchivos"
                        [formatosPermitidos]="_formatosPermitidos"
                        [subidaMultiple]="false"
                        [sobreescrituraFichero]=true
                        [maximoElementosASubir]="1"
                        [ficherosIniciales]="[item]"
                        [tamanoMaximoTotal]="calcularTamanoMaximoTotal()"
                        [tamanoMaximoFichero]="_tamanoMaximoFichero"
                        (preSubirArchivos)="cargarArchivo($event)"
                        (submitFicheroSubida)="guardarArchivo($event)"
                        (borrarFichero)="eventoBorrarFicheroEnEdicion($event)"
    ></neo-fichero-subida>
    <neo-formulario class="mt-30 display-block" #formularioEdicion
                    [valoresIniciales]="item"
                    [camposFormulario]="formInputEdicion"
                    (changeValueForm)="cambiosFormularioEdicion($event)"
    >
    </neo-formulario>
  </neo-acciones-dialog>
</ng-template>
<!--End Diálogo Editar archivos-->
<!--Diálogo Eliminar archivos-->
<ng-template #dialogConfirmarEliminarArchivos>
  <neo-acciones-dialog #accionDialogConfirmarEliminarArchivos
                       [dialogTittle]="'Eliminar'"
                       [botonera]="[
                                   
                                    {
                                      label: 'No, mantener',
                                      id: 'btn-cancelar',
                                      tipoAccion: 'cerrar',
                                      type: 'button',
                                      color: '',
                                      disabled: false,
                                      activo: true,
                                      flat: true
                                    },
                                    {
                                      label: 'Sí, eliminar',
                                      id: 'btn-confirmar',
                                      tipoAccion: 'accion',
                                      type: 'button',
                                      color: 'primary',
                                      disabled: false,
                                      activo: true,
                                      flat: true
                                    },
                                  ]"
                       (accionBoton)="submitAccionDialogConfirmarEliminarArchivos([item])">

    <p>¿Estás seguro de eliminar
      <span *ngIf="[item].length>1 else avisoFicheroIndividual">los siguientes {{tipoFicheroPlural | lowercase}} </span>?
    </p>
    <ng-template #avisoFicheroIndividual>el siguiente {{tipoFichero | lowercase}} </ng-template>

    <ng-container *ngFor="let file of [item]">
      <p>
        {{file.nombre}}{{file.extension}}
      </p>
    </ng-container>
    </neo-acciones-dialog>
    </ng-template>
    <!--Diálogo Eliminar archivos-->

<!--Diálogo Más info-->
<ng-template #dialogoMasInfo>
  <neo-acciones-dialog #accionDialogMasInfo [dialogTittle]="'Información'" [botonera]="[]">
    <neo-perfil #perfilMasInfoFichero [propiedades]="propiedadesMasInfoFichero" [aplicarFlex]=false>
    </neo-perfil>
  </neo-acciones-dialog>
</ng-template>
<!--End Diálogo Más info-->
