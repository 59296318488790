<div class="header-dialog flex" *ngIf="dialogTittle || botonCerrarDialogoCabecera">
  <div class="flex header-dialog-left" *ngIf="dialogTittle">
    <h1 mat-dialog-title>{{dialogTittle}}</h1>
  </div>
  <mat-dialog-actions align="end" *ngIf="botonCerrarDialogoCabecera">
    <neo-button class="btn-close" *ngIf="notificarCierreDialogo" [miniFab]="true"
      [iconClass]="'icon-ibm--close icon-16'" (click)="cerrar()"></neo-button>
    <neo-button mat-dialog-close class="btn-close" *ngIf="!notificarCierreDialogo" [miniFab]="true"
      [iconClass]="'icon-ibm--close icon-16'" (click)="cerrar()"></neo-button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
    <neo-notificacion  #notificacionDialogo data-testid="notificacionDialogo"></neo-notificacion>

    <div data-testid="proyeccion">
      <ng-content></ng-content>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions  [ngClass]="claseDialogActions" align="end" data-testid="botoneraPie"  *ngIf="botonera.length">
    <div [ngClass]="configuracionBotoneraIzquierda?.clase">
      <ng-container *ngTemplateOutlet="botoneraPie;context:{botonera:botoneraIzquierda}"></ng-container>
    </div>
    <div [ngClass]="configuracionBotonera?.clase">
      <ng-container *ngTemplateOutlet="botoneraPie;context:{botonera:botonera}"></ng-container>
    </div>
  </mat-dialog-actions>


<ng-template #botoneraPie let-botonera="botonera">
  <ng-container *ngFor="let boton of botonera">
    <ng-container *ngIf="boton.tipoAccion === 'accion'">
          <neo-button tabindex="7" *ngIf="boton.activo"
              [text]="boton.label"
              [raised]="boton?.raised"
              [stroked]="boton?.stroked"
              [fab]="boton?.fab"
              [miniFab]="boton?.miniFab"
              [basic]="boton?.basic"
              [flat]="boton?.flat"
              [iconClass]="boton?.iconoClase"
              [matprefix]="boton?.matprefix"
              [matsuffix]="boton?.matsuffix"
              [color]="boton.color"
              [type]="boton.type"
              [disabled]="boton?.disabled"
              [clase] = "(boton?.clase) ? boton?.clase: 'ml-10'"
              (click)="(!boton?.disabled) ? accion(boton) : null;"
          ></neo-button>
      </ng-container>

      <ng-container *ngIf="boton.tipoAccion === 'cerrar'">
          <ng-container *ngIf="notificarCierreDialogo">
            <!-- TODO intentar solventar mat-dialog-close sin tener que duplicar codigo -->
              <neo-button tabindex="7"  class="btn-cancel mr-2" *ngIf="boton.activo"
                [text]="boton.label"
                [raised]="boton?.raised"
                [stroked]="boton?.stroked"
                [fab]="boton?.fab"
                [miniFab]="boton?.miniFab"
                [basic]="boton?.basic"
                [flat]="boton?.flat"
                [iconClass]="boton?.iconoClase"
                [matprefix]="boton?.matprefix"
                [matsuffix]="boton?.matsuffix"
                [color]="boton.color"
                [type]="boton.type"
                [disabled]="boton?.disabled"
                [clase] = "(boton?.clase) ? boton?.clase: 'ml-10'"
                (click)="cerrar(boton)"
            ></neo-button>
          </ng-container>
          <ng-container *ngIf="!notificarCierreDialogo">
              <neo-button tabindex="7"  mat-dialog-close class="btn-cancel mr-2" *ngIf="boton.activo"
                [text]="boton.label"
                [raised]="boton?.raised"
                [stroked]="boton?.stroked"
                [fab]="boton?.fab"
                [miniFab]="boton?.miniFab"
                [basic]="boton?.basic"
                [flat]="boton?.flat"
                [iconClass]="boton?.iconoClase"
                [matprefix]="boton?.matprefix"
                [matsuffix]="boton?.matsuffix"
                [color]="boton.color"
                [type]="boton.type"
                [disabled]="boton?.disabled"
                [clase] = "(boton?.clase) ? boton?.clase: 'ml-10'"
                (click)="cerrar(boton)"
            ></neo-button>
          </ng-container>

      </ng-container>

  </ng-container>
</ng-template>

