<div class="item-kanban">
    <div class="item-kanban-cont">
        <div class="item-kanban-texto">
            <p class="cabera-item-kanban-texto">{{ item.nombre }}</p>

            <div class="cuerpo-item-kanban-texto" *ngIf="item?.texto">{{item.texto}}</div>      

            <ng-container *ngIf="item?.extra && item?.extra?.length">
                <div *ngFor="let infoExtra of item.extra" class="pie-item-kanban-infoextra">
                    <p class="pie-item-kanban-infoextra-label">{{infoExtra.label}}</p>
                    <div class="pie-item-kanban-infoextra-cont">
                        <neo-icono class="pie-item-kanban-infoextra-icono"
                        [iconoClase]="infoExtra.iconoClase"
                        ></neo-icono>
                        <div class="pie-item-kanan-infoextra-texto">
                            {{infoExtra.texto}}
                        </div>
                    </div>            
                </div>
            </ng-container>
        </div>

        <div class="item-kanban-iconos">           
            <ng-container *ngIf="item?.avatar">
                <ngx-avatar name="{{item.avatar?.nombre?.nombre}}" initialsSize="1" src="{{item.avatar?.src}}" size="32"
                    class="ngx-mi-avatar"></ngx-avatar>
            </ng-container>
            <!-- flag -->
            <neo-icono *ngIf="item?.flag"
            [iconoClase]="'mat-icon icon-20 icon-ibm--notification text-primary'"
            ></neo-icono>
        </div>
    </div>    
</div>
